import { NetworkEpakList } from './../model/network-epg-model/network-epak-list';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TapkitFile } from '../model/network-epg-model/tapkit-file';
import { NetworkGridsList } from '../model/network-epg-model/network-grids-list';
import { NetworksGridsData } from '../model/network-epg-model/grids-data';
import { BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NetworskDownloadData } from '../model/network-epg-model/networks-donwload-data';
import { GeneralService } from './general.service';
import { NetworkDownloadData } from '../model/network-epg-model/network-download-data';
import { LocalBreaksFilesByFeed } from '../model/network-epg-model/localbreaks-files-with-feed';
import { TapkitFileResponse } from '../response/tapkit-file.response';

@Injectable({
    providedIn: 'root'
})
export class NetworkGridsService {
    private gridsFilesData: { [networkId: number]: NetworkGridsList[] } = {};
    private epakFilesData: { [networkId: number]: NetworkEpakList[] } = {};
    private updateFilesData: { [networkId: number]: TapkitFileResponse } = {};
    private gridsFilesSubject = new BehaviorSubject<NetworkGridsList[]>([]);
    private epakFilesSubject = new BehaviorSubject<NetworkEpakList[]>([]);

    gridFiles$ = this.gridsFilesSubject.asObservable();
    epakFiles$ = this.epakFilesSubject.asObservable();

    networksData: NetworksGridsData = {};

    constructor(private http: HttpClient,
        private gralService: GeneralService) { }

    // fijarse si va un observable o no en la declaracion, Observable<NetworkGridsList[]>
    getFilesFilteredByNetworkIdAndMonth(networkId: number, file_type: string, desde: number, hasta: number) {
        //behaviourSubject<>
        var params = new HttpParams();

        if (networkId)
            params = params.set('networkId', networkId + "");
        if (file_type)
            params = params.set('file_type', file_type + "");
        if (desde)
            params = params.set('desde', desde + "");
        if (hasta)
            params = params.set('hasta', hasta + "");

        return this.http.get<NetworkGridsList[]>(environment.apiNetworEpgGetAllNetworkGrids, { params, }).pipe(tap(result => {
            this.gridsFilesData[networkId] = result;
            this.gridsFilesSubject.next(result);
        })

        )
    }

    getEpakFiles(networkId: number) {

        var params = new HttpParams();

        if (networkId)
            params = params.set('networkId', networkId + "");

        return this.http.get<NetworkEpakList[]>(environment.apiNetworEpgGetAllEpakFiles, { params, }).pipe(tap(result => {
            this.epakFilesData[networkId] = result;
            this.epakFilesSubject.next(result);
        })

        )
    }

    getUpdateFiles(networkId: number) {

        var params = new HttpParams();

        if (networkId)
            params = params.set('networkId', networkId + "");

        return this.http.get<TapkitFileResponse>(environment.apiNetworEpgGetUpdates, { params, }).pipe(tap(result => {
            this.updateFilesData[networkId] = result;
        })

        )
    }

    getFilesFilteredByNetworkIdMonthAndDate(networkId: number, file_type: string, selectedDate: string) {
        var params = new HttpParams();

        if (networkId)
            params = params.set('networkId', networkId + "");
        if (file_type)
            params = params.set('file_type', file_type + "");
        if (selectedDate)
            params = params.set('selectedDate', selectedDate + "");

        //return this.http.get<LocalBreaksFilesByFeed[]>(environment.apiNetworEpgGetAllNetworkLocalBreaksByDate, { params, });
        return this.http.get<LocalBreaksFilesByFeed[]>(environment.apiNetworEpgGetAllNetworkLocalBreaksByDate, { params, })
    }

    getDownloadedFiles(filesList: NetworkDownloadData[]) {
        const headers = { 'Content-Type': 'application/json' };
        const requestBody = JSON.stringify(filesList);
        console.log(requestBody);
        return this.http.post(environment.apiNetworksGridsFileGetDownloadedFiles, requestBody, { 'headers': headers, responseType: 'arraybuffer' });
    }

}
