import { HttpClient, HttpHeaders, HttpParams, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { async, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Monthly } from '../model/monthly';
import { FeedResponse } from '../response/feed.response';
import { MonthlyResponse } from '../response/monthly.response';
import { GeneralService } from './general.service';
import { MonthlyUploadError } from '../model/monthly-upload-model/MonthlyUploadError';
import { MonthlyUploadResponse } from '../model/monthly-upload-model/MonthlyUploadResponse';

@Injectable({
  providedIn: 'root'
})
export class MonthlyService {

  //private localURL = 'http://localhost:8085/';
  //private downloadMonthlyURL = 'monthlyshowsdownload.xls';
  //private publishMonthlyURL = 'monthlyshowsupload.xls';

  constructor(
    private httpClient: HttpClient,
    private gralService: GeneralService) {
  }


  public downloadMonthlyGrid(feedId: string, filename: string, from: string, to: string, fileLanguage: string)/*: Observable<any>*/ {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    let queryParams = new HttpParams();
    queryParams = queryParams.append('feedId', feedId);
    queryParams = queryParams.append('from', from);
    queryParams = queryParams.append('to', to);
    queryParams = queryParams.append('fileName', filename);
    queryParams = queryParams.append('fileLanguage', fileLanguage);

    console.log("URL monthly = ", environment.apiMonthlyUrlDownload)
    return this.httpClient.get(environment.apiMonthlyUrlDownload, { headers, params: queryParams, responseType: 'blob' as 'json'})
      //.pipe(catchError(this.handleError));

  }

  private /*async*/ handleError(error: any) { 
    if (!(error instanceof String) && error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        'Backend returned ', error);
        //`Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
/*
  parseErrorBlob(err): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(JSON.parse((e.target.result.toString())));
        observer.complete();
      }
    });
    reader.readAsText(err);
    return obs;
}
*/
  public publishMonthlyGrid(feedId: string, filename: string, from: string, to: string, fileLanguage: string) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    //const url = this.localURL + this.publishMonthlyURL

    let queryParams = new HttpParams();
    queryParams = queryParams.append('feedId', feedId);
    queryParams = queryParams.append('from', from);
    queryParams = queryParams.append('to', to);
    queryParams = queryParams.append('fileName', filename);
    queryParams = queryParams.append('fileLanguage', fileLanguage);

    console.log("URL monthly = ", environment.apiMonthlyUrlUpload)

    
    return this.httpClient.get(environment.apiMonthlyUrlUpload, { headers, params: queryParams, responseType: 'blob' as 'json' });
    
  }

  getMonthly$(): Observable<Monthly[]> {
    return this.httpClient
      .get<MonthlyResponse>(environment.apiDomainUrlGetMonthly)
      .pipe(
        map(response => response.monthly),
        catchError(this.gralService.handleError));
  }

  public uploadFilesToBucket(files: File[]): Observable<MonthlyUploadResponse>{
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('archivos', files[i]);
    }
    return this.httpClient.post<MonthlyUploadResponse>(environment.apiMonthlyUrlSendFiles,formData).pipe(
      catchError(this.gralService.handleError));
  }

}