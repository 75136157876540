import { ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, Injector, Input, OnInit, Renderer2, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { NetworkEpg } from 'src/app/shared/model/network-epg-model/network-epg';
import { NetworkTab } from 'src/app/shared/model/network-epg-model/network-tab';
import { NetworkEpgService } from 'src/app/shared/service/network-epg.service';
import { NetworkTabService } from 'src/app/shared/service/network-tab.service';
import { EpgComponent } from '../../epg.component';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject, Subscription, of } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NetworkGridsService } from 'src/app/shared/service/network-grids.service';
import { NetworksData } from 'src/app/shared/model/network-epg-model/network-data';
import { NetworkUtilsDialogComponent } from './utils-dialog/network-utils-dialog/network-utils-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TapkitFile } from 'src/app/shared/model/network-epg-model/tapkit-file';
import { NetworskDownloadData } from 'src/app/shared/model/network-epg-model/networks-donwload-data';
import { NetworkDataSharingService } from 'src/app/shared/service/network-data-sharing.service';
import { Network } from 'src/app/shared/model/network';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Params } from '@angular/router';
import { NetworkDownloadData } from 'src/app/shared/model/network-epg-model/network-download-data';
import { saveAs } from 'file-saver';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { MatDatepicker, MatDatepickerInput, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { LocalBreaksFilesByFeed } from 'src/app/shared/model/network-epg-model/localbreaks-files-with-feed';
import { MatTableDataSource } from '@angular/material/table';
import { NetworkLocalbreaksService } from 'src/app/shared/service/network-localbreaks.service';
import { NetworkDetails } from 'src/app/shared/model/network-details';
import { WebsitesUtilsDialogComponent } from './utils-dialog/websites-utils-dialog/websites-utils-dialog.component';
import { NetworkContactService } from 'src/app/shared/service/network-contact.service';
import { NetworkContact } from 'src/app/shared/model/network-contact';
import { FeedsUtilsDialogComponent } from './utils-dialog/feeds-utils-dialog/feeds-utils-dialog.component';
import { FeedService } from 'src/app/shared/service/feed.service';
import { Feed } from 'src/app/shared/model/feed';
import { DailyGridsFilesByFeed } from 'src/app/shared/model/network-epg-model/dailygrids';
import { NetworkDailygridsService } from 'src/app/shared/service/network-dailygrids.service';
import { MatPaginator } from '@angular/material/paginator';
import { NewsService } from 'src/app/shared/service/epg-news.service';
import { EpgNews } from 'src/app/shared/model/news-model/news';

@Component({
    selector: 'app-networks',
    templateUrl: './networks.component.html',
    styleUrls: ['./networks.component.scss']
})
export class NetworksComponent implements OnInit {

    networkUrl: string = "";
    logoUrl: string;
    logoUrlBig: string;
    tabIndex: number[] = [1, 2, 3, 4, 5, 6, 7, 8];//para poder filtrar los tabs
    networksData: NetworksData = {};
    networkTabs: NetworkTab[] = [];
    networkEpg: NetworkEpg[] = [];
    idSeleccionado: number;
    networkSelected: boolean = false;//habilitar componentes del html
    networkNotSelected: boolean = true;//habilitar imagen del html
    gridsFileBoolean: boolean = false;//habilitar el mat progress bar de grids
    highligthFileBoolean: boolean = false;//habilitar el mat progress bar de highligth
    imagesFileBoolean: boolean = false;//habilitar el mat progress bar de images
    videosFileBoolean: boolean = false;//habilitar el mat progress bar de videos
    localbreaksFileBoolean: boolean = false;//habilitar el mat progress bar de localbreaks
    dailyGridsFileBoolean: boolean = false;//habilitar el mat progress bar de localbreaks
    epakFileBoolean: boolean = false;//habilitar el mat progress bar de EPAK
    updateFileBoolean: boolean = false;//habilitar el mat progress bar de UPDATES
    networkEpgActual: NetworkEpg;
    networkDetails: NetworkDetails;
    networskDownloadData: NetworskDownloadData[] = [];
    currentNetwork: Network;
    currentNetworkContact: NetworkContact;
    currentTab: number;
    private unsubscription$: Subject<void>;
    private langChangeSubscription: Subscription;
    networkGrids: NetworkTab;
    tabNames: { [id_tab: number]: string } = {
        [1]: "gridsFiles",
        [2]: "dailyGrids",
        [3]: "destacados",
        [4]: "imagenes",
        [5]: "videos",
        [6]: "localBreakFiles",
        [7]: "epak",
        [8]: "updates"
    };

    //Carousel
    epgNewsCarousel: EpgNews[] = [];
    defaultLogoUrl: string = environment.bucketUrlImagesNewsLogo;
    numSlides: number = 0;
    isPaused: boolean = false;

    modifiedFileParams: string;
    createdFileParams: string;
    infoMessage: string;
    errorMessage: string;
    //Localbreaks
    @ViewChild(MatPaginator) localbreakstablePaginator: MatPaginator;
    localBreaksMessageOk: string
    localBreaksMessageError: string;
    date = new FormControl(moment());
    private readonly MIN_DATE_IN_DAYS = new Date().getDate() - 365;
    private readonly MAX_DATE_IN_DAYS = new Date().getDate() + 365;
    minDate = new Date();
    maxDate = new Date();
    datePickerStartDate: string = moment().format('YYYY-MM-DD');
    datePickerChanged: string = "";
    localBreaksFilesByFeed: LocalBreaksFilesByFeed[] = [];
    localBreaksDataSourceFile = new MatTableDataSource([]);
    localBreaksDisplayedColumns: String[] = ["feed", "file_name", "download"];
    //dailygrids
    dailyGridsFilesByFeed: DailyGridsFilesByFeed[] = [];
    dailyGridsDataSourceFile = new MatTableDataSource([]);
    dailyGridsDisplayedColumns: String[] = ["name", "download"];

    networkDownloadData: NetworkDownloadData = {
        name: '',
        path: ''
    };
    feeds: Feed[] = [];
    //datePickerStartDate = new Date().getDate();

    //actionLocalProgress: boolean = false;
    //selectedDate = new Date();
    //selectedDateBoolean: boolean = false;

    //variables para borrar
    tab2: string = "Prensa";
    tabsNames: string[] = ['GRIDS', 'DAILYGRIDS', 'HIGHLIGHTS', 'IMAGES', 'VIDEOS', 'LOCALBREAK', 'EPAK', 'UPDATES'];
    lang: string;


    constructor
        (
            private networkGridsService: NetworkGridsService,
            private networkTabService: NetworkTabService,
            private networkEpgService: NetworkEpgService,
            private epgComponent: EpgComponent,
            private dialog: MatDialog,
            private dataSharingServices: NetworkDataSharingService,
            private route: ActivatedRoute,
            private snackBar: MatSnackBar,
            private translateService: TranslateService,
            private networkLocalbreaksService: NetworkLocalbreaksService,
            private networkContactService: NetworkContactService,
            private feedService: FeedService,
            private networkDailyGridsService: NetworkDailygridsService,
            private newsService: NewsService,
            private renderer: Renderer2
        ) {
        this.unsubscription$ = new Subject<void>();
        this.logoUrl = environment.bucketUrlImagesNetworksLogo;
        this.logoUrlBig = environment.bucketUrlImages + "wbd_new_logo_big.png";
        //Guardo los parámetros que provienen del JOB de notificaciones
        this.modifiedFileParams = this.route.snapshot.queryParams["file"];
        this.createdFileParams = this.route.snapshot.queryParams["createdFile"];
        this.networkDetails = new NetworkDetails();
        this.lang = sessionStorage.getItem('locale');
        this.translations();
    }

    ngOnInit(): void {

        this.minDate.setDate(this.MIN_DATE_IN_DAYS);
        this.maxDate.setDate(this.MAX_DATE_IN_DAYS);
        // traer los tabs de cada network
        this.networkTabService.getAllNetworkTab().subscribe(result => {
            this.networkTabs = result;
        });
        // traer la info de botones y tabs de arriba
        this.networkEpgService.getAllNetwork().subscribe(result => {
            this.networkEpg = result;
        });
        //falta llamado para traer contacts.

        //traer el network seleccionado
        this.epgComponent.selectedNetwork$.pipe(takeUntil(this.unsubscription$)).subscribe((codeNetwork) => {
            this.changeNetwork(codeNetwork);
            this.changeContacts(codeNetwork.id);
            this.changeNetworkDetails(this.networkEpgActual, this.lang);
        })

        // Suscripción independiente a onLangChange
        this.langChangeSubscription = this.translateService.onLangChange
            .pipe(takeUntil(this.unsubscription$))
            .subscribe((langChangeEvent: LangChangeEvent) => {
                this.lang = langChangeEvent.lang;
                if (this.networkEpgActual)
                    this.changeNetworkDetails(this.networkEpgActual, this.lang)
            });



        //saco los files desseleccionados desde el dialog
        this.dataSharingServices.getMyObjectSubject().subscribe((data) => {
            this.networksData[data.id_network][this.tabNames[data.tab_id]].find(gf => {
                let gfl = gf.lista.find(gfl => gfl.id === data.file_id);
                if (!gfl)
                    return false;
                gfl.selected = false;
                return true;
            });//change detection reference
        });

        //Si la URL contiene parámetros de tipo "file", se inicia automáticamente la descarga de archivos modificados del JOB de notificaciones
        if (this.modifiedFileParams) {
            this.donwloadExternalFiles(this.modifiedFileParams, "Updated_Tapkit_Files.zip");
        } else if (this.createdFileParams)
            this.donwloadExternalFiles(this.createdFileParams, "Created_Tapkit_Files.zip");

        //Traemos las imagenes para el carrusel.
        this.newsService.findByNetworkDateAndImage().subscribe((data) => {
            this.epgNewsCarousel = data;
            this.numSlides = this.epgNewsCarousel.length;
            document.documentElement.style.setProperty('--contentvalue', this.numSlides.toString());
        });
    }

    getFactor() {
        return this.numSlides;
    }

    pauseAnimation() {
        if (this.epgNewsCarousel.length >= 6) {
            this.isPaused = true;
            document.querySelector('.slide-track').classList.add('paused');
        }
    }

    resumeAnimation() {
        if (this.epgNewsCarousel.length >= 6) {
            this.isPaused = false;
            document.querySelector('.slide-track').classList.remove('paused');
        }
    }

    changeContacts(networkId: number) {
        this.networkContactService.getAllContacts(networkId).subscribe((result: NetworkContact) => {
            this.currentNetworkContact = result;
        });
    }

    getFactshet() {
        this.networkEpgService.downloadFile(environment.bucketUrlFeeds + this.networkEpgActual.factSheet)
    }

    getLogo() {
        this.networkEpgService.downloadFile(environment.bucketUrlFeeds + this.networkEpgActual.pressKit)
    }

    // Funcion para actualizar datos cada vez que cambia el network
    changeNetwork(currentNetwork: Network) {
        this.networkNotSelected = false;
        this.currentNetwork = currentNetwork;
        //this.currentTab = 1;
        this.idSeleccionado = currentNetwork.id;
        this.networkUrl = this.logoUrl + currentNetwork.logo;
        this.networkEpgActual = this.networkEpg.find(network => network.id === this.idSeleccionado);// puede tirar error porque networkEpg puede tardar mas en traer los datos

        this.networkSelected = true;
        //this.selectedDateBoolean = false;
        if (!this.currentTab) {

            this.currentTab = this.getMinTab(this.idSeleccionado);
        }

        this.tabsGetData(this.idSeleccionado, this.currentTab);

    }


    changeNetworkDetails(networkEpgActual: NetworkEpg, lang: string) {
        if (lang == 'en') {
            this.networkDetails.about = networkEpgActual.informationEn;
            this.networkDetails.urls = networkEpgActual.urlsEn;
            this.networkDetails.urlFacebook = networkEpgActual.urlFacebookEn;
            this.networkDetails.urlTwitter = networkEpgActual.urlTwitterEn;
        }
        if (lang == 'es') {
            this.networkDetails.about = networkEpgActual.informationEs;
            this.networkDetails.urls = networkEpgActual.urlsEs;
            this.networkDetails.urlFacebook = networkEpgActual.urlFacebookEs;
            this.networkDetails.urlTwitter = networkEpgActual.urlTwitterEs;
        }
        if (lang == 'pt') {
            this.networkDetails.about = this.networkEpgActual.informationPt;
            this.networkDetails.urls = this.networkEpgActual.urlsPt;
            this.networkDetails.urlFacebook = this.networkEpgActual.urlFacebookPt;
            this.networkDetails.urlTwitter = this.networkEpgActual.urlTwitterPt;
        }

    }

    // Funcion para traer el tab minimo
    getMinTab(idSel: number): number {
        const selectedNetworkTabs = this.networkTabs.filter(tab => tab.id_network == idSel);
        if (selectedNetworkTabs.length === 0) {
            return -1;
        }
        const minTabId = selectedNetworkTabs.reduce((min, tab) => {
            return tab.tab_id < min ? tab.tab_id : min;
        }, selectedNetworkTabs[0].tab_id);
        return minTabId;
    }

    // Funcion para mostrar los tabs en el html si es que tiene tab_id el network.
    hasIdTab(tabId: number): boolean {
        const selectedNetworkTabs = this.networkTabs.filter(tab => tab.id_network === this.idSeleccionado);
        return selectedNetworkTabs.some(tab => tab.tab_id === tabId);
    }

    //busco la informacion dependiendo del tag y network seleccionado.
    tabsGetData(idSeleccionado: number, tabId: number): void {
        if (!this.networksData[idSeleccionado]) {
            //inicializo las listas de datos de cada network.
            this.networksData[idSeleccionado] = {
                gridsFiles: null,
                daily: null,
                destacados: null,
                imagenes: null,
                videos: null,
                localBreakFiles: null,
                epak: null,
                updates: null
            };
        }

        if (!this.networksData[idSeleccionado][tabId]) {
            this.getNetworkGrids(this.idSeleccionado, this.tabsNames[tabId - 1], tabId, this.datePickerStartDate);
            this.networksData[idSeleccionado][tabId] = true;
        }
        else if (tabId == 6) {
            this.getNetworkGrids(this.idSeleccionado, this.tabsNames[5], 6, this.datePickerStartDate);
        }
    }

    //cuando cambia el tab, busco el id correspondiente y traigo la info de ese tab.
    onTabChange(event: MatTabChangeEvent): void {
        const selectedNetworkTabs = this.networkTabs
            .filter(tab => tab.id_network === this.idSeleccionado)
            .sort((a, b) => a.tab_id - b.tab_id);

        const selectedTabIndex = event.index;
        if (selectedTabIndex < selectedNetworkTabs.length) {
            this.currentTab = selectedNetworkTabs[selectedTabIndex].tab_id;
        }

        if (!this.networksData[this.idSeleccionado] || !this.networksData[this.idSeleccionado][this.currentTab]) {
            this.tabsGetData(this.idSeleccionado, this.currentTab);
        }
    }

    // Funcion para cargar los botones, creo
    getRows(array: any[], columns: number): any[] {
        const result = [];
        for (let i = 0; i < array.length; i += columns) {
            result.push(array.slice(i, i + columns));
        }
        return result;
    }


    // FuncioshouldShowButtonn para verificar que boton mostrar
    shouldShowButton(buttonId: number): boolean {
        const selectedNetwork = this.networkEpg.find(network => network.id === this.idSeleccionado)
        if (!selectedNetwork) {
            return false;
        }
        switch (buttonId) {
            case 1:
                return (this.networkDetails.urlFacebook == null || this.networkDetails.urlFacebook == "");
            case 2:
                return (this.networkDetails.urlTwitter == null || this.networkDetails.urlTwitter == "");
            case 3:
                return (this.networkEpgActual.feedImage == null || this.networkEpgActual.feedImage == "");
            case 4:
                return (this.networkDetails.urls == null || this.networkDetails.urls == "");
            case 5:
                return (this.networkEpgActual.factSheet == null || this.networkEpgActual.factSheet == "");
            case 6:
                return (this.networkEpgActual.pressKit == null || this.networkEpgActual.pressKit == "");
            default:
                return true;
        }
    }

    //Funcion para abrir el link de facebook o twitter
    openLink(link: string) {
        if (link) {
            window.open(link, '_blank');
        }
    }

    openWebsite() {
        this.dialog.open(WebsitesUtilsDialogComponent, {
            data: this.networkDetails.urls,
            minWidth: '350px',
        });
    }

    openFeeds() {
        this.dialog.open(FeedsUtilsDialogComponent, {
            data: {
                feedImage: this.networkEpgActual.feedImage,
                feedText: this.networkEpgActual.feedText,
                feedHorarios: this.networkEpgActual.feedHorarios
            },
            minWidth: '450px',
        })
    }

    // Funcion para deseleccionar todos los files
    deselectFiles() {
        this.networskDownloadData.forEach(netData => {
            const networkData = this.networksData[netData.id_network];
            if (networkData && networkData[this.tabNames[netData.tab_id]]) {
                networkData[this.tabNames[netData.tab_id]].forEach(gridsFile => {
                    const gfl = gridsFile.lista.find(gfl => gfl.id === netData.file_id);
                    if (gfl) {
                        gfl.selected = false;
                    }
                });
            }
        });

        this.networskDownloadData = [];
    }

    // Funcion para pasar los nombres de los archivos al dialog y mostrarlos.
    descargarfilesPublicSeleccionados() {
        this.dialog.open(NetworkUtilsDialogComponent, {
            data: this.networskDownloadData
        });
    }

    //Funcion para actualziar el valor de los checkbox.
    updateSelectedCount(gridsFile: TapkitFile) {
        if (gridsFile.selected) {
            const netData = new NetworskDownloadData;
            netData.id_network = this.idSeleccionado;
            netData.network_code = this.currentNetwork.code;
            netData.tab_id = this.currentTab;
            netData.name = gridsFile.name
            netData.selected = true;
            netData.file_id = gridsFile.id;
            netData.path = gridsFile.path;
            this.networskDownloadData.push(netData);
        } else {
            const index = this.networskDownloadData.findIndex(file => file.name === gridsFile.name);
            if (index !== -1) {
                this.networskDownloadData.splice(index, 1);
            }
        }
    }

    //traigo la info de las grillas
    getNetworkGrids(idSeleccionado: number, file_type: string, tab_id: number, date: string) {
        this.networkGrids = this.networkTabs.find(tab => tab.id_network === idSeleccionado);
        switch (tab_id) {
            case 1:
                this.gridsFileBoolean = true;
                this.networkGridsService.getFilesFilteredByNetworkIdAndMonth(idSeleccionado, file_type, this.networkGrids.meses_desde, this.networkGrids.meses_hasta).subscribe(result => {
                    this.networksData[idSeleccionado].gridsFiles = result;
                    this.gridsFileBoolean = false;
                });;
                break;
            case 2:
                //this.dailyGridsFileBoolean = true;
                this.networksData[idSeleccionado].daily = this.currentNetwork.feeds;
                break;
            case 3:
                this.highligthFileBoolean = true;
                this.networkGridsService.getFilesFilteredByNetworkIdAndMonth(idSeleccionado, file_type, this.networkGrids.meses_desde, this.networkGrids.meses_hasta).subscribe(result => {
                    this.networksData[idSeleccionado].destacados = result;
                    this.highligthFileBoolean = false;
                });;
                break;
            case 4:
                this.imagesFileBoolean = true;
                this.networkGridsService.getFilesFilteredByNetworkIdAndMonth(idSeleccionado, file_type, this.networkGrids.meses_desde, this.networkGrids.meses_hasta).subscribe(result => {
                    this.networksData[idSeleccionado].imagenes = result;
                    this.imagesFileBoolean = false;
                });;
                break;
            case 5:
                this.videosFileBoolean = true;
                this.networkGridsService.getFilesFilteredByNetworkIdAndMonth(idSeleccionado, file_type, this.networkGrids.meses_desde, this.networkGrids.meses_hasta).subscribe(result => {
                    this.networksData[idSeleccionado].videos = result;
                    this.videosFileBoolean = false;
                });;
                break;
            case 6:
                this.localbreaksFileBoolean = true;
                this.localBreaksDataSourceFile = null;
                this.networkGridsService.getFilesFilteredByNetworkIdMonthAndDate(idSeleccionado, file_type, date).subscribe(result => {
                    //console.log("result", result);
                    this.localBreaksFilesByFeed = result;
                    this.localBreaksDataSourceFile = new MatTableDataSource(result);
                    this.localBreaksDataSourceFile.paginator = this.localbreakstablePaginator;
                    this.localbreaksFileBoolean = false;
                });
                break;
            case 7:
                this.epakFileBoolean = true;
                this.networkGridsService.getEpakFiles(idSeleccionado).subscribe(result => {
                    this.networksData[idSeleccionado].epak = result;
                    this.epakFileBoolean = false;
                });;
                break;
            case 8:
                this.updateFileBoolean = true;
                this.networkGridsService.getUpdateFiles(idSeleccionado).subscribe(result => {
                    this.networksData[idSeleccionado].updates = result;
                    this.updateFileBoolean = false;
                });;
                break;
            case 9:
                console.log("estoy en Cambios de Programacion");
                break;
        }
    }

    onDateChange(event: MatDatepickerInputEvent<Date>): void {
        if (event.value) {
            this.datePickerChanged = moment(event.value).format('YYYY-MM-DD');
            this.getNetworkGrids(this.idSeleccionado, this.tabsNames[5], 6, this.datePickerChanged);
        } else {

        }
    }

    downloadLocalBreak(element): void {
        //console.log("element:", element);
        this.networkDownloadData.name = element.name;
        this.networkDownloadData.path = element.path;
        this.networkLocalbreaksService.downloadLocalBreaksFile(this.networkDownloadData).subscribe({
            next: (res) => {
                this.manageCsvFile(res, element.name);
                this.showSnackbar(this.localBreaksMessageOk, "Ok");
            },
            error: (e) => {
                this.showSnackbar(this.localBreaksMessageError, "Ok");
            }
        });
    }

    downloadDailyGrids(element): void {
        this.dailyGridsFileBoolean = true;
        this.networkDailyGridsService.downloadDailyGridsFile(element.id).subscribe({
            next: (res) => {
                this.dailyGridsFileBoolean = false;
                this.manageCsvFile(res, element.id + "-schedule");
                this.showSnackbar(this.localBreaksMessageOk, "Ok");
            },
            error: (e) => {
                this.showSnackbar(this.localBreaksMessageError, "Ok");
            }
        });

    }

    manageCsvFile(response: any, filename: string): void {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const filePath = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }))
        const downloadLink = document.createElement('a')
        downloadLink.href = filePath
        downloadLink.setAttribute('download', filename)
        document.body.appendChild(downloadLink)
        downloadLink.click()
    }

    //Método para descargar archivos que fueron modificados: "Job Notificaciones"
    donwloadExternalFiles(fileParam: string, zipName: string) {
        const body = fileParam;
        let networkDownloadDataArray: NetworkDownloadData[];
        if (Array.isArray(body)) {
            networkDownloadDataArray = body.map(this.createNetworkData);
        } else {
            networkDownloadDataArray = [this.createNetworkData(body)]
        }
        //Servicio para descargar archivos
        this.networkGridsService.getDownloadedFiles(networkDownloadDataArray).pipe(
            catchError(e => {
                console.error('Error al descargar los archivos:', e);
                this.showErrorSnackBar(this.errorMessage, "Ok")
                return of(null);
            })
        ).subscribe((arrayBuffer) => {
            if (arrayBuffer) {
                //Mostrar antes del subscribe
                this.showSnackbar(this.infoMessage, "Ok");
                this.arrayBufferToFile(arrayBuffer, zipName);
            }
        });
    }

    //Guardar archivo de tipo Blob
    arrayBufferToFile(arrayBuffer: ArrayBuffer, zipName: string) {
        const blob = new Blob([arrayBuffer]);
        saveAs(blob, zipName);
    }

    //Creo arreglos de tipo NetworkData
    createNetworkData(file: string): NetworkDownloadData {
        const path = file;
        const lastSlashIndex = file.lastIndexOf("/") + 1;
        const name = file.substring(lastSlashIndex, file.length);
        return { name, path };
    }

    //Traigo el network code
    getNetwork(networkId: number): string {
        const network = this.networkEpg.find(net => net.id == networkId);
        return network?.code;
    }

    //Snackbars
    showSnackbar(message: string, action: string) {
        let config = new MatSnackBarConfig();
        config.duration = 3000;
        config.verticalPosition = "top";
        config.panelClass = ['snackbar-info'];
        this.snackBar.open(message, action, config);
    }

    showErrorSnackBar(message: string, action: string) {
        let config = new MatSnackBarConfig();
        config.verticalPosition = "top";
        config.panelClass = ['error-snackbar'];
        this.snackBar.open(message, action, config);
    }


    //Traducciones
    private translations(): void {
        const keys = [
            'epg.networks.mesages.error',
            'epg.networks.mesages.download',
            'epg.networks.mesages.localbreaks.downloadok',
            'epg.networks.mesages.localbreaks.downloaderror'
        ];
        this.translateService
            .stream(keys)
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(translations => this.mapperToMessages(translations));
    }

    private mapperToMessages(translations: any): void {
        this.errorMessage = translations['epg.networks.mesages.error'];
        this.infoMessage = translations['epg.networks.mesages.download'];
        this.localBreaksMessageOk = translations['epg.networks.mesages.localbreaks.downloadok'];
        this.localBreaksMessageError = translations['epg.networks.mesages.localbreaks.downloaderror'];
    }

    ngOnDestroy(): void {
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }
}
