import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NetworkTab } from '../model/network-epg-model/network-tab';


@Injectable({
  providedIn: 'root'
})
export class NetworkTabService {

  constructor(private http: HttpClient) { }

  getAllNetworkTab(){
    return this.http.get<NetworkTab[]>(environment.apiNetworEpgGetAllNetworkTab);
  }
  
}
