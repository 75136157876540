import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NetworkDownloadData } from '../model/network-epg-model/network-download-data';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NetworkLocalbreaksService {

  constructor(private http: HttpClient) { }

  downloadLocalBreaksFile(filesList: NetworkDownloadData) {
    const headers = { 'Content-Type': 'application/json' };
    const requestBody = JSON.stringify(filesList);

    return this.http.post(environment.apiNetworksLocalBreaksDownloadLocalBreaksFile, requestBody, {
      headers: headers,
      responseType: 'blob'
    });
  }
}
