import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NetworkResponse } from '../response/network.response';
import { GeneralService } from './general.service';
import { Network } from '../model/network';
import { NetworksUploadFilesUser } from '../model/network-epg-model/network-upload-files-user';
import { MonthlyUploadResponse } from '../model/monthly-upload-model/MonthlyUploadResponse';
import { BehaviorSubject } from 'rxjs';
import { NetworkSubscriptionResponse } from '../response/network-subscription-response';

@Injectable({
    providedIn: 'root'
})
export class NetworkService {
    private networksSource = new BehaviorSubject<Network[]>([]);
    networks$ = this.networksSource.asObservable();

    constructor(
        private http: HttpClient,
        private gralService: GeneralService) {
    }

    //Send network data from this components to another
    updateNetworks(networks: Network[]) {
        this.networksSource.next(networks);
    }

    getNetworks$(): Observable<Network[]> {
        return this.http
            .get<NetworkResponse>(environment.apiDomainUrlGetNetwork)
            .pipe(
                map(response => response.networks),
                catchError(this.gralService.handleError));
    }


    getNetworksAncine$(): Observable<Network[]> {
        return this.http
            .get<NetworkResponse>(environment.apiDomainUrlGetNetworkAncine)
            .pipe(
                map(response => response.networks),
                catchError(this.gralService.handleError));
    }

    uploadFile(formData: FormData): Observable<any> {
        return this.http.post(environment.apiDomainPostNetworkFile, formData);
    }

    // uploadFile(networksUploadFiles: NetworksUploadFiles): Observable<any> {
    //     return this.http.post(environment.apiDomainPostNetworkFile, networksUploadFiles);
    // }

    // registerDownloadedFile(formData: FormData): Observable<any> {
    //     return this.http.post(environment.apiDomainPostNetworkFile, formData);
    // }

    getFilesByUser(username: String): Observable<NetworksUploadFilesUser[]> {
        let params = new HttpParams();
        params = params.set('username', username + "");
        return this.http.get<NetworksUploadFilesUser[]>(environment.apiDomainGetNetworkFilesByUser, { params, });
    }

    deleteFile(fileId: Number): Observable<MonthlyUploadResponse> {
        let params = new HttpParams();
        params = params.set('fileId', fileId + "");
        return this.http.delete<MonthlyUploadResponse>(environment.apiDomainDeleteFile, { params, });
    }

    deleteFileFromS3(filePath: String): Observable<MonthlyUploadResponse> {
        let params = new HttpParams();
        params = params.set('filePath', filePath + "");
        return this.http.delete<MonthlyUploadResponse>(environment.apiEpgDeleteFileFromS3, { params, });
    }

    subscribeNetworks(requestBody: any): Observable<NetworkSubscriptionResponse> {

        return this.http.post<NetworkSubscriptionResponse>(environment.apiDomainUrlPostNetworkSubscription, requestBody);
    }

}
