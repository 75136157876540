import { Component } from '@angular/core';

@Component({
  selector: 'app-configuration-register',
  templateUrl: './configuration-register.component.html',
  styleUrls: ['./configuration-register.component.scss']
})
export class ConfigurationRegisterComponent {
  activeSection: number = 1; // Por defecto, activamos la primera sección

  // Método para cambiar la sección activa
  changeSection(section: number): void {
    this.activeSection = section;
  }

  // Método para verificar si una sección está activa
  isActiveSection(section: number): boolean {
    return this.activeSection === section;
  }
}

