import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from './general.service';
import { Module } from '../model/module';
import { Observable } from 'rxjs';
import { ModuleResponse } from '../response/module.response';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ModuleService {

    constructor(
        private http: HttpClient,
        private gralService: GeneralService) {
    }

    getModules$(): Observable<Module[]> {
        return this.http
            .get<ModuleResponse>(environment.apiDomainUrlGetModules)
            .pipe(
                map(response => response.modules),
                catchError(this.gralService.handleError));
    }

}
