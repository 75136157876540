import { Component, OnInit, ViewChild } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { Language } from 'src/app/shared/model/language';
import { Network } from 'src/app/shared/model/network';
import { NetworksUploadFilesUser } from 'src/app/shared/model/network-epg-model/network-upload-files-user';
import { User } from 'src/app/shared/model/user';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { FeedService } from 'src/app/shared/service/feed.service';
import { FileConfigurationsService } from 'src/app/shared/service/file-configurations.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { NetworkService } from 'src/app/shared/service/network.service';
import { NetworksUploadUtilsDialogComponent } from './utils-dialog/networks-upload-utils-dialog/networks-upload-utils-dialog.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-networks-upload',
    templateUrl: './networks-upload.component.html',
    styleUrls: ['./networks-upload.component.scss'],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]

})

export class NetworksUploadComponent implements OnInit {
    @ViewChild(MatPaginator) tablePaginator: MatPaginator;

    displayedColumns: String[] = ["fileName", "fileType", "network","language", "date","delete"]
    dataSourceFile = new MatTableDataSource([]);
    showProgressBar: boolean = false;

    // Declaracion de variables.
    callReady: boolean = true;
    languages: Language[] = [];
    fileTypes: string[] = null;
    feeds: string[] = null;
    networkList: Network[] = null;
    net:Network = null;
    //Variables para snackBar
    private unsubscription$: Subject<void>;
    fileDeletitionInProgress: string;
    fileDeletitionSuccess: string;
    fileDeletitionError: string;
    
    currentUser: User;
    networks$: Observable<Network[]>; //new
    // Para las flechitas que ordenan la tabla.

    constructor(
        private dialog: MatDialog,
        private languageService: LanguageService,
        private fileConfigurationsService: FileConfigurationsService,
        private feedService: FeedService,
        private networkService: NetworkService,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private snackBar: MatSnackBar,
    ) {
        this.unsubscription$ = new Subject<void>();
        this.translations();
    }

    ngOnInit(): void {
        //Get network data from footer.
        this.networks$ = this.networkService.networks$;
        this.networks$.subscribe(result=>{
            this.networkList = result;
        });
        // Traemos los lenguajes.
        this.languageService.getLanguages$().subscribe(
            data => {
                this.languages = data;
            }
        )
        // Traemos los tipos de archivos.
        this.fileConfigurationsService.getAllFilesConfByFileTypeEnabled().subscribe(
            data => {
                this.fileTypes = data.map(fileType => fileType.fileType);
            }
        )
        // Traemos los feeds.
        this.showProgressBar = true;
        this.feedService.getFeeds$().subscribe(
            data => {
                this.feeds = data.map(feed => feed.id);
                this.callReady = false;
                this.getAllFiles(); 
            }        
        )
    }

    // Llamado para traer y mostrar todos los registros de los archivos en la tabla.
    getAllFiles() {
        this.networkService.getFilesByUser(this.authenticationService.currentUserValue.username).subscribe(result =>{
            this.dataSourceFile = new MatTableDataSource(result);
            this.dataSourceFile.paginator = this.tablePaginator;
            this.showProgressBar = false;
        },() =>{
            this.showProgressBar = false;
        })
    }

    newRegister() {
        this.dialog.open(NetworksUploadUtilsDialogComponent, {
            data: {
                languages: this.languages,
                fileTypes: this.fileTypes,
                feeds: this.feeds,
                username:this.authenticationService.currentUserValue.username
            },
        }).afterClosed().subscribe(()=>this.refreshTable());
    }

    getNetworkFromId(networkId:number){  
        return this.networkList.find(n=>n.id == networkId).code;
    }

    openConfirmDialog(elementSelected:NetworksUploadFilesUser): void {
        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '300px',
        });
        dialogRef.afterClosed().subscribe(result =>{
            if(result){
                this.deleteFile(elementSelected);
            }
        })
    }

    
    //Elimina los archivos de la base de datos
    deleteFile(selectedFile:any): void{
        this.showSnackbar(this.fileDeletitionInProgress,null);
        //Eliminar el archivo del bucket.
        this.networkService.deleteFileFromS3(selectedFile.tapkitFileId.path).subscribe();
        //Eliminar el registro de la tabla tapkit_file y de la tabla network_upload_file_users
        this.networkService.deleteFile(selectedFile.tapkitFileId.id).subscribe(()=>{
            this.showSnackbar(this.fileDeletitionSuccess, "Ok");
            this.refreshTable();
        },()=>this.showErrorSnackbar(this.fileDeletitionError, "Ok"))
     
    }

    refreshTable(){
        this.getAllFiles();
    }

    // Snackbars
    showSnackbar(message: string, action: string) {
        let config = new MatSnackBarConfig();
        config.duration = 3000;
        config.verticalPosition = "top";
        config.panelClass = ['SuccessfulSnackbar'];
        this.snackBar.open(message, action, config);
    }

    showErrorSnackbar(message: string, action: string) {
        let config = new MatSnackBarConfig();
        config.verticalPosition = "top";
        config.panelClass = ['error-snackbar'];
        this.snackBar.open(message, action, config);
    }

    //Translate Snackbar Functions
    private translations(): void {
        const keys = [
        'epg.networks_upload.messages.error',
        'epg.networks_upload.messages.inProgress',
        'epg.networks_upload.messages.success',
        
        ];
        
        this.translateService
        .stream(keys)
        .pipe(takeUntil(this.unsubscription$))
        .subscribe(translations => this.mapperToMessages(translations));
    }

    private mapperToMessages(translations: any): void {
    
        this.fileDeletitionInProgress = translations['epg.networks_upload.messages.inProgress'];
        this.fileDeletitionError =  translations['epg.networks_upload.messages.error'];
        this.fileDeletitionSuccess = translations['epg.networks_upload.messages.success'];

    }

    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }

}
