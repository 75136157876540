import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from '../service/alert.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {

    private unsubscription$: Subject<void>;
    message: any;

    constructor(private alertService: AlertService) {
        this.unsubscription$ = new Subject<void>();
    }

    ngOnInit() {
        this.alertService
            .getAlert$()
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(message => this.alertCase(message));
    }

    ngOnDestroy() {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }

    removeAlert() {
        this.alertService.clear();
    }

    private alertCase(message: any): void {
        switch (message && message.type) {
            case 'info':
                message.cssClass = 'alert alert-info';
                break;
            case 'success':
                message.cssClass = 'alert alert-success';
                break;
            case 'warning':
                message.cssClass = 'alert alert-warning';
                break;
            case 'error':
                message.cssClass = 'alert alert-danger';
                break;
        }
        this.message = message;
    }

}
