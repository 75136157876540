import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { LanguageSelect } from 'src/app/shared/model/languageSelect';
import { LanguageService } from 'src/app/shared/service/language.service';
import { MatDialog } from '@angular/material/dialog';
import { LanguageDialogComponent } from './language-dialog/language-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/shared/service/util.service';


@Component({
  selector: 'app-language-options',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {


  languages: LanguageSelect[] = [];
  displayedColumns: string[] = ['code', 'name', 'actions'];
  dataSource = new MatTableDataSource<LanguageSelect>();
  languageForm: FormGroup;
  isEditing: boolean = false;
  selectedLanguageId?: number;

  constructor(private languageService: LanguageService, 
    private dialog: MatDialog,
    private translateService: TranslateService,
    private utilService: UtilService
) {
   
  }

  ngOnInit(): void {
    this.loadLanguages();
  }


  loadLanguages() {
    this.languageService.getLanguagesSelect().subscribe(
      (data) => {
        this.languages = data;
        this.dataSource.data = this.languages;  // Asignar datos a la tabla
      },
      (error) => {
        console.error('Error al cargar los lenguajes:', error);
      }
    );
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(LanguageDialogComponent, {
      width: '400px',
      data: { language: null, isEditing: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.languageService.addLanguage(result).subscribe(() => this.loadLanguages());
      }
    });
  }

  openEditDialog(language: LanguageSelect) {
    const dialogRef = this.dialog.open(LanguageDialogComponent, {
      width: '400px',
      data: { language, isEditing: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.languageService.updateLanguage(language.id, result).subscribe(() => this.loadLanguages());
      }
    });
  }

  async deleteLanguage(id: number) {
    const textConfirm = await this.translateService.get('epg.users.dialog.messageDeleteConfirm').toPromise();
    const buttonConfirm = await this.translateService.get('epg.users.dialog.buttons.delete').toPromise();

    // Abrir el diálogo de confirmación
    const dialogRef = this.utilService.openConfirmDialog({
      title: buttonConfirm,
      text: textConfirm,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Si el usuario confirma, proceder a eliminar el idioma
        this.languageService.deleteLanguage(id).subscribe(() => {
          this.loadLanguages();
        });
      }
    });
  }
  resetForm() {
    this.languageForm.reset();
    this.isEditing = false;
    this.selectedLanguageId = undefined;
  }

}
