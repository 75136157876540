import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GeneralService {

    constructor() { }

    public handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('Ha ocurrido un error:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend retorno codigo ${error.status}, ` +
                `el cuerpo fue: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError('Ha ocurrido un error, por favor intente mas tarde.');
    }

}
