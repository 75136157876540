import { Component, Inject, OnInit } from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialogActions,
    MatDialogClose,
} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-terms",
    templateUrl: "./terms.component.html",
    styleUrls: ["./terms.component.css"],
})
export class TermsComponent {

    constructor(
        public dialog: MatDialogRef<TermsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translateService: TranslateService
    ) {
        //this.translateService.use(this.data.language);
    }

    confirm() {
        this.dialog.close(true);
    }

    closeDialog() {
        this.dialog.close(false);
    }
}
