import { Address } from "./address";
import { Company } from "./company";
import { RoleType } from "./role-type";

export class UserProfile {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    language: string;
    lastLogin: Date;
    dateCreated: Date;
    role: RoleType[];
    company: Company;
    address: Address;
    state: number;
    password?: string;
}
