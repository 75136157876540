import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Feed } from 'src/app/shared/model/feed';
import { AlertService } from 'src/app/shared/service/alert.service';
import { DialogConfigComponent } from './dialog-config/dialog-config.component';
import { ConfigGridAlias } from 'src/app/shared/model/config-grid-alias';
import { ConfigurationsRequest } from 'src/app/shared/request/configurations.request';
import { ConfigService } from 'src/app/shared/service/config.service';
import { TranslateService } from '@ngx-translate/core';
import { Module } from 'src/app/shared/model/module';

@Component({
    selector: 'app-config-confirm',
    templateUrl: './config-confirm.component.html',
    styleUrls: ['./config-confirm.component.css']
})
export class ConfigConfirmComponent implements OnDestroy {

    @Input()
    module: Module;

    @Input()
    selection: SelectionModel<Feed>;

    @Input()
    configSelected$: Observable<ConfigGridAlias[]>;

    loadingConfirm = false;

    private unsubscription$: Subject<void>;
    private request: ConfigurationsRequest;
    private message: string;

    constructor(
        public dialog: MatDialog,
        private alertService: AlertService,
        private configService: ConfigService,
        private translateService: TranslateService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer) {

        iconRegistry.addSvgIcon('download',
            sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/cloud-download-24px.svg')
        );
        iconRegistry.addSvgIcon('confirm',
            sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/done-24px.svg')
        );
        this.unsubscription$ = new Subject<void>();
        this.request = new ConfigurationsRequest();

        this.translateService
            .stream(['epg.config.setting-response'])
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(translations => this.message = translations['epg.config.setting-response']);
    }

    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }

    mapperRequest(): void {
        this.loadingConfirm = true;
        this.request.module = this.module;
        this.request.feeds = this.selection.selected;
        if (this.request.feeds.length > 0) {
            this.configSelected$
                .pipe(takeUntil(this.unsubscription$))
                .subscribe(fields => this.request.fields = fields)
                .add(() => this.openDialog());
        } else {
            this.alertService.warning(this.message);
            this.loadingConfirm = false;
        }
    }

    private openDialog(): void {
        const settings = {
            height: '190px',
            width: '356px',
            disableClose: true,
            autoFocus: true,
            data: this.request
        };

        this.dialog
            .open(DialogConfigComponent, settings)
            .afterClosed()
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(result => this.acceptDialog(result))
            .add(() => this.loadingConfirm = false);
    }

    private acceptDialog(result: ConfigurationsRequest): void {
        this.alertService.clear();
        if (result) {
            this.configService
                .postConfigurations$(result)
                .pipe(takeUntil(this.unsubscription$))
                .subscribe(
                    data => this.alertService.success(data.message),
                    error => this.alertService.error(error));
        }
    }

}
