import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CategorySelect } from '../model/categorySelect';
import { catchError } from 'rxjs/operators';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {


  private apiUrl = environment.apiSecurityUrlCategory;


  constructor(
    private http: HttpClient,
    private gralService: GeneralService) { 
    }

  getCategories(): Observable<CategorySelect[]> {
    return this.http.get<CategorySelect[]>(this.apiUrl);
  }

  addCategory(category: CategorySelect): Observable<CategorySelect> {
    return this.http.post<CategorySelect>(`${this.apiUrl}/add`, category).pipe(
      catchError(this.gralService.handleError)
    );
  }

  updateCategory(id: number, type: CategorySelect): Observable<CategorySelect> {
    return this.http.put<CategorySelect>(`${this.apiUrl}/update/${id}`, type).pipe(
      catchError(this.gralService.handleError)
    );
  }

  deleteCategory(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/delete/${id}`).pipe(
      catchError(this.gralService.handleError)
    );
  }
}
