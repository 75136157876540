import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileExtensionsConfigurations } from 'src/app/shared/model/file-extensions-configurations';
import { Network } from 'src/app/shared/model/network';
import { NetworkEpg } from 'src/app/shared/model/network-epg-model/network-epg';
import { EpgNews } from 'src/app/shared/model/news-model/news';
import { FileExtensionsConfigurationsService } from 'src/app/shared/service/file-extensions-configurations.service';
import { LanguageService } from 'src/app/shared/service/language.service';
import { NetworkEpgService } from 'src/app/shared/service/network-epg.service';
import { NewsService } from 'src/app/shared/service/epg-news.service';
import { EpgComponent } from '../../epg.component';
import { NewsDialogsComponent } from './utils-dialog/news-dialogs/news-dialogs.component';
import { Language } from 'src/app/shared/model/language';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  @ViewChild(MatPaginator) tablePaginator: MatPaginator;

  //displayedColumns: String[] = ["date", "expiration_date", "title", "subtitle", "network", "language"]
  displayedColumns: String[] = ["date", "expiration_date", "title", "subtitle", "language"]
  dataSourceFile = new MatTableDataSource([]);
  mostrarBarra: boolean = false;
  currentNetwork: Network;
  private unsubscription$: Subject<void>;
  networkNews: EpgNews[] = [];
  languages: Language[] = null;
  listNetworks: NetworkEpg[] = [];
  nets: Observable<Network[]>;
  newsFileExtensionsConfigurations: FileExtensionsConfigurations[];
  videoExtensions: string[] = null;
  imageExtensions: string[] = null;
  loadingNetworks: boolean = true;
  filterValue: string = '';

  // Para las flechitas que ordenan la tabla.
  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  constructor(
    private networkNewsService: NewsService,
    private epgComponent: EpgComponent,
    private dialog: MatDialog,
    private languageService: LanguageService,
    private networkEpgService: NetworkEpgService,
    private fileExtensionsConfigurationsService: FileExtensionsConfigurationsService,
  ) {
    this.unsubscription$ = new Subject<void>();
  }

  ngOnInit(): void {

    //Me suscribo a la seleccion del network para poder traer sus datos.
    this.epgComponent.selectedNetwork$.pipe(takeUntil(this.unsubscription$)).subscribe((codeNetwork) => {
      this.currentNetwork = codeNetwork;
      this.getAllNews();
    })

    //traigo los networks
    this.networkEpgService.getAllNetwork().subscribe(result => {
      this.listNetworks = result;
      this.loadingNetworks = false;
    });

    //Traigo los lenguajes y saco el que es ALL.
    this.languageService.getLanguages$().subscribe(
      data => {
        this.languages = data
          .filter(language => language.code !== 'ALL')
          .map(language => language);
      }
    )

    //Traigo las extensiones
    this.getExtensions();
  }

  // Llamado para traer y mostrar todos los registros de la db.
  getAllNews() {
    this.mostrarBarra = true;
    this.networkNewsService.getAllNetworkNewsByNetworkId(this.currentNetwork.id).subscribe(result => {
      this.networkNews = result;
      this.dataSourceFile = new MatTableDataSource(result);
      this.dataSourceFile.paginator = this.tablePaginator;
      this.dataSourceFile.sort = this.sort;
      this.mostrarBarra = false;
    });
  }

  openDialog(element) {
    const dialogRef = this.dialog.open(NewsDialogsComponent, {
      data: {
        listNetworks: this.listNetworks,
        currentNetwork: this.currentNetwork,
        news: element,
        languages: this.languages,
        videoExtensions: this.videoExtensions,
        imageExtensions: this.imageExtensions,
        isEdit: true,
        editarBoolean: true
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refresh();
      }
    });
  }

  
  newRegister() {
    this.dialog.open(NewsDialogsComponent, {
      data: {
        listNetworks: this.listNetworks,
        network: this.currentNetwork,
        news: null,
        languages: this.languages,
        videoExtensions: this.videoExtensions,
        imageExtensions: this.imageExtensions,
        isEdit: false,
        editarBoolean: false,
      },
    });
  }

  getExtensions() {
    //traigo las extensiones para validar
    this.fileExtensionsConfigurationsService.getAllFilesExtConfByFileType(["IMAGES", "VIDEOS"]).subscribe(result => {
      this.newsFileExtensionsConfigurations = result;
      this.videoExtensions = result.filter(data => data.fileConfigurationId.fileType == 'VIDEOS').map(data => data.fileExtensionId.fileExtension);
      this.imageExtensions = result.filter(data => data.fileConfigurationId.fileType == 'IMAGES').map(data => data.fileExtensionId.fileExtension);
    })
  }

  refresh() {
    this.getAllNews();
  }

  // Método para aplicar el filtro
  applyFilter(value: string) {
    this.filterValue = value.trim().toLowerCase();
    this.dataSourceFile.filter = this.filterValue;
  }
}
