import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NetworkDailygridsService {

  constructor(private http: HttpClient) { }

  downloadDailyGridsFile(feedId: string) {
    let headers : HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/vnd.ms-excel');
    let queryParams = new HttpParams();
    queryParams = queryParams.append('feedId', feedId);

    return this.http.get(environment.apiDailyGridsUrlDownload, { params: queryParams, headers: headers, responseType: 'blob'})
  }
}
