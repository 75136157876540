import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-monthly-utils-dialogs-component-component',
  templateUrl: './monthly-utils-dialogs-component-component.component.html',
  styleUrls: ['./monthly-utils-dialogs-component-component.component.scss']
})
export class MonthlyUtilsDialogsComponentComponentComponent implements OnInit {

  filesErrors: string[] = [];

  constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<MonthlyUtilsDialogsComponentComponentComponent>) {
    this.filesErrors = data;
   }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }
  
}
