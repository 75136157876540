import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Feeds } from '../model/visual-logs-model/feeds';
import { FileProcess } from '../model/visual-logs-model/files';
import { Process } from '../model/visual-logs-model/process';

@Injectable({
  providedIn: 'root'
})
export class VisualLogsService {

  constructor(private http: HttpClient) { 
    //httpClient
    //activar o manejar el tema de CORS en spring 
  }

  traerProcesos(){

    return this.http.get<Process[]>(environment.apiDatacityUrlGetProcess);
    //return this.http.get<Process[]>('http://10.161.103.21:8080/getprocess');

  }

  traerArchivosId(id:number){

    return this.http.get<FileProcess[]>(environment.apiDatacityUrlGetFiles + id);
    //return this.http.get<FileProcess[]>('http://10.161.103.21:8080/getfiles/byid_process/'+ id);

  }

  traerArchivosStatus(){

    return this.http.get<String[]>(environment.apiDatacityUrlGetStatus);
    //return this.http.get<FileProcess[]>('http://10.161.103.21:8080/getfiles');

  }

  traerArchivosFiltered(feed: string, status: string, startDate: string, endDate: string, id_process: number): Observable<any> {
    var params = new HttpParams();

    if (id_process)
        params = params.append('id_process', id_process+"");
    if (feed)
        params = params.append('feed', feed);
    if (status){
        //console.log(status)
        params = params.append('status', status);}
    if (startDate && endDate){
      console.log(startDate);
      console.log(endDate);
      params = params.append('startDate', startDate);
      params = params.append('endDate', endDate);
    }
     // console.log(params)

    //return this.http.get<String[]>('http://localhost:8080/getfiltered');
    //return this.http.get<FileProcess[]>('http://10.161.103.21:8080/getfiles');
    return this.http.get<FileProcess[]>(environment.apiDatacityUrlGetFiltered , {params,});
  }
   
  traerLogs(id:number){

    return this.http.get<FileProcess[]>(environment.apiDatacityUrlGetLogs + id);
    //return this.http.get<FileProcess[]>('http://10.161.103.21:8080/getlogs/byid_file/'+ id);

  }

  traerFeeds(){

    return this.http.get<Feeds[]>(environment.apiDatacityUrlGetFeeds);
    //return this.http.get<Feeds[]>('http://10.161.103.21:8080/getfeeds');

  }


}
