import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../service/authentication.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { switchMap, map, filter, take } from 'rxjs/operators';
import { User } from '../model/user';


/**
 * Intercepta las solicitudes http, para agregar al header:
 *          > Accept-Language : locale
 *          > Content-Type    : application/json (solo si el usuario esta logueado)
 *          > Authorization   : access_token JWT (solo si el usuario esta logueado)
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private contentType: string;
    private contentTypeSplitted: string;

    constructor(
        private authenticationService: AuthenticationService,
        private jwtHelperService: JwtHelperService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authenticationService.currentUserValue;
        request = request.clone({
            setHeaders: {
                'Accept-Language': sessionStorage.getItem('locale')
            }
        });

        if (request.url === environment.apiSecurityUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Basic bXljbGllbnQ6c2VjcmV0',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
        } else if (currentUser && currentUser.accessToken) {
            // Si existe el token lo agregamos al encabezado de la peticion
            const isTokenExpired = this.jwtHelperService.isTokenExpired(currentUser.accessToken);
            if (isTokenExpired) {
                // Si el token expiro obtenemos un nuevo token
                return this.refreshToken(request, next, currentUser);
            } else {
                request = this.addAccessToken(request, currentUser.accessToken);
            }
        }
        return next.handle(request);
    }

    /**
     * Pide un nuevo access_token al servicio de Seguridad
     *
     * @param request     request con token vencido
     * @param next        puntero a la siguiente funcion que maneje la peticion
     * @param currentUser usuario de la sesion actual
     */
    private refreshToken(
            request: HttpRequest<any>,
            next: HttpHandler,
            currentUser: User) {

        if (this.isRefreshing) {
            return this.refreshTokenSubject$
                       .pipe(filter(token => token != null),
                             take(1),
                             switchMap(newAccessToken => next.handle(this.addAccessToken(request, newAccessToken))));
        } else {
            this.isRefreshing = true;
            this.refreshTokenSubject$.next(null);
            return this.authenticationService
                       .loginRefreshToken$(currentUser.refreshToken)
                       .pipe(switchMap(() => {
                            this.isRefreshing = false;
                            this.refreshTokenSubject$.next(currentUser.accessToken);
                            return next.handle(this.addAccessToken(request, currentUser.accessToken));
                       }));
        }
    }

    /**
     * Agrega el access_token al Header
     *
     * @param request solicitud http
     * @param accessToken token JWT
     */
    // private addAccessToken(request: HttpRequest<any>, accessToken: string) {
    //     return request.clone({
    //         setHeaders: {
    //             Authorization: `Bearer ${accessToken}`,
    //             'Content-Type': 'application/json'
    //         }
    //     });
    // }

    private addAccessToken(request: HttpRequest<any>, accessToken: string) {
        if (request.body instanceof FormData) {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
        }
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
    }
}
