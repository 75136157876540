import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Network } from 'src/app/shared/model/network';
import { NetworkEpgFilesUpload } from 'src/app/shared/model/network-epg-model/network-epg-files-upload';
import { NewNetworkEpg } from 'src/app/shared/model/new-network-epg';
import { EpgDataService } from 'src/app/shared/service/epg-data.service';
import { NetworkEpgService } from 'src/app/shared/service/network-epg.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-network',
  templateUrl: './edit-network.component.html',
  styleUrls: ['./edit-network.component.scss']
})
export class EditNetworkComponent implements OnInit {

  @Input() selectedNetworkCode: string | null = null;

  pressKitMessage: string;
  factSheetMessage: string;

  defaultLogoUrl: string;
  feedImageMessage: string;

  imageMessage: string;
  updateInProgress: string;
  networkReflectedImage: string;
  imageReflectedMessage: string;


  private unsubscription$: Subject<void> = new Subject<void>();
  networks: NewNetworkEpg[] = [];
  selectedNetwork: NewNetworkEpg | null = null;
  loadingSelectedNetwork = false;

  networkUrl: string;
  logoUrl: string;
  reflectedLogoUrl: string;
  networkForm: FormGroup;
  isEditing: boolean;

  networkImage: string = "";

  networkEpgLogoUpload: NetworkEpgFilesUpload;
  networkEpgReflectedLogoUpload: NetworkEpgFilesUpload;

  imageExtensions: string[] = [];
  maxWidthLogo: number = 240;
  maxHeightLogo: number = 120;
  maxWidthLogoRef: number = 97;
  maxHeightLogoRef: number = 96;
  networkUpdated: {
    id: number; active: boolean; code: string; factSheet: string; informationEn: string; informationEs: string; informationPt: string; logo: string; nameEn: string; nameEs: string; namePt: string; urlFacebookEn: string; urlFacebookEs: string; urlFacebookPt: string; urlTwitterEn: string; urlTwitterEs: string; urlTwitterPt: string; urlsEn: string; urlsEs: string; urlsPt: string; feedImage: string; feedText: string; feedHorarios: string; pressKit: string; //son varias fotos,
    visible: boolean; activepcm: boolean;
  };
  publicationFilesInProcessMessage: string;
  savingInProgress: boolean;
  allowedExtension: string = ".png";;
  errorMessage: string;

  listNetworks: NewNetworkEpg[] = [];
  successfulFilesPublicationMessage: string;
  errorDBMessage: string;



  constructor(
    private networkEpgService: NetworkEpgService,
    private epgDataService: EpgDataService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {

    this.defaultLogoUrl = environment.bucketUrlImages + "WBD_logo_news.png";
    this.logoUrl = environment.bucketUrlImagesNetworksLogo;
    this.reflectedLogoUrl = environment.bucketUrlImagesNetworks;


    this.unsubscription$ = new Subject<void>();
    this.networkEpgLogoUpload = new NetworkEpgFilesUpload();
    this.networkEpgReflectedLogoUpload = new NetworkEpgFilesUpload();

  }

  ngOnInit(): void {

    this.translateService.stream([
        'epg.networks_upload.messages.errorUpload',
        'epg.networks_upload.messages.errorUpload',
        'epg.networks_upload.messages.successUpload'
    ])
    .pipe(takeUntil(this.unsubscription$))
    .subscribe(translations => this.mapperToMessages(translations));

    this.loadNetworks();


    // Escuchar cambios de selección desde el componente principal
    this.epgDataService.codeNetworkSubject$.pipe(
      takeUntil(this.unsubscription$)
    ).subscribe((result) => {

      let networkCode = result.codeNetwork
      this.updateSelectedNetwork(networkCode);
    });

    this.networkForm = this.fb.group({
      name_en: ['', Validators.required],
      name_es: ['', Validators.required],
      name_pt: ['', Validators.required],
      code: ['', Validators.required],
      information_en: ['', Validators.required],
      information_es: ['', Validators.required],
      information_pt: ['', Validators.required],
      url_en: ['', [Validators.required, Validators.pattern('https?://.+')]],
      url_es: ['', [Validators.required, Validators.pattern('https?://.+')]],
      url_pt: ['', [Validators.required, Validators.pattern('https?://.+')]],
      url_twitter_en: [''],
      url_twitter_es: [''],
      url_twitter_pt: [''],
      url_facebook_en: [''],
      url_facebook_es: [''],
      url_facebook_pt: [''],
      feeds: [''],
      horarios: [''],
      visible: [false],
      active: [false]
    });



  }

  loadNetworks(): void {
    this.isEditing = false;
    this.loadingSelectedNetwork = true;


    this.networkEpgService.getAllNetwork().pipe(
      takeUntil(this.unsubscription$)
    ).subscribe({
      next: (networks: NewNetworkEpg[]) => {
        this.networks = networks;
        this.checkAndLoadSelectedNetwork();
      },
      error: (err) => {
        console.error('Error al cargar las networks', err);
      },
      complete: () => {
        this.loadingSelectedNetwork = false;
      }
    });
  }

   checkAndLoadSelectedNetwork(): void {
    if (this.selectedNetworkCode) {  // Verificamos si hay una network seleccionada
      this.updateSelectedNetwork(this.selectedNetworkCode);  // Cargamos automáticamente los datos de la network
      this.selectedNetworkCode = null;  // Limpiamos el código una vez cargados los datos
    }
  }


  loadSelectedNetworkData(): void {
    if (this.selectedNetwork) {
      this.networkForm.patchValue({
        name_en: this.selectedNetwork.nameEn || '',
        name_es: this.selectedNetwork.nameEs || '',
        name_pt: this.selectedNetwork.namePt || '',
        code: this.selectedNetwork.code || '',
        information_en: this.selectedNetwork.informationEn || '',
        information_es: this.selectedNetwork.informationEs || '',
        information_pt: this.selectedNetwork.informationPt || '',
        url_en: this.selectedNetwork.urlsEn || '',
        url_es: this.selectedNetwork.urlsEs || '',
        url_pt: this.selectedNetwork.urlsPt || '',
        url_twitter_en: this.selectedNetwork.urlTwitterEn || '',
        url_twitter_es: this.selectedNetwork.urlTwitterEs || '',
        url_twitter_pt: this.selectedNetwork.urlTwitterPt || '',
        url_facebook_en: this.selectedNetwork.urlFacebookEn || '',
        url_facebook_es: this.selectedNetwork.urlFacebookEs || '',
        url_facebook_pt: this.selectedNetwork.urlFacebookPt || '',
        feeds: this.selectedNetwork.feedText || '',
        horarios: this.selectedNetwork.feedHorarios || '',
        visible: this.selectedNetwork.visible || false,
        active: this.selectedNetwork.active || false
      });

      this.isEditing = false;

      this.networkImage = this.logoUrl + this.selectedNetwork.logo
      this.networkReflectedImage = this.reflectedLogoUrl + this.selectedNetwork.logo

      this.networkForm.disable();


    }
  }

  // Método para actualizar la network seleccionada
  updateSelectedNetwork(networkCode: string): void {
    this.selectedNetwork = this.networks.find(network => network.code === networkCode) || null;
    this.loadSelectedNetworkData();
  }

  enableEditing(): void {
      this.networkForm.enable();
      this.isEditing = true;

  }

  save(): void {
    if (this.networkForm && this.networkForm.valid) {
      this.savingInProgress = true;

      this.uploadToS3(this.selectedNetwork.id);
    }


      this.isEditing = false;
      this.networkForm.disable();
    }


    uploadToS3(id) {


      this.networkEpgLogoUpload.fileName = (this.networkForm.value.code + this.allowedExtension).toLowerCase();
      this.networkEpgReflectedLogoUpload.fileName = (this.networkForm.value.code + this.allowedExtension).toLowerCase();

      let epgUpload = {
        logo: this.networkEpgLogoUpload,
        reflectedLogo:  this.networkEpgReflectedLogoUpload
      }

      this.networkEpgService.uploadFilesToS3(epgUpload).subscribe({
        next: (res) => {
          this.registerInDB(id);
        },
        error: (e) => {
          this.showSnackbar(this.errorMessage, "Ok");
          this.savingInProgress = false;
        }
      });
    }

    // Para registrar los datos en la base de datos. DOMAIN
    registerInDB(id) {
      const formValues = this.networkForm.value;
      const selectedNetwork = this.listNetworks.find(network => network.code === formValues.network.code);
      this.networkUpdated = {
        id: id,
        active: formValues.active,
        code: formValues.code,
        factSheet: null,
        informationEn: formValues.information_en,
        informationEs: formValues.information_es,
        informationPt: formValues.information_pt,
        logo: this.networkEpgLogoUpload.fileName,
        nameEn: formValues.name_en,
        nameEs: formValues.name_es,
        namePt: formValues.name_pt,
        urlFacebookEn: formValues.url_facebook_en,
        urlFacebookEs: formValues.url_facebook_es,
        urlFacebookPt: formValues.urlFacebookEs,
        urlTwitterEn: formValues.url_twitter_en,
        urlTwitterEs: formValues.url_twitter_es,
        urlTwitterPt: formValues.url_twitter_pt,
        urlsEn: formValues.url_en,
        urlsEs: formValues.url_es,
        urlsPt: formValues.url_pt,
        feedImage: null,
        feedText: formValues.feeds,
        feedHorarios: formValues.feedHorarios,
        pressKit: null, //son varias fotos,
        visible: formValues.visible,
        activepcm: false
      };

      // Realiza la subida de datos a la base de datos
      this.networkEpgService.saveNetworkEpg(this.networkUpdated).subscribe({ // IMPORTANTE rxjs switch map o algo similar.
        next: (res) => {
          this.showSnackbar(this.successfulFilesPublicationMessage, "Ok");
          this.savingInProgress = false;

          this.isEditing = false;
          this.networkForm.disable();
          this.networkForm.patchValue(res);

        },
        error: (e) => {
          this.showErrorSnackbar(this.errorDBMessage, "Ok");
          this.savingInProgress = false;
        }
      });
    }



  // Para capturar la imagen y validar la extension.
  onImageSelected(event: any) {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return;
    const fileName = selectedFile.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    const reader = new FileReader();

    this.networkEpgLogoUpload.fileName = selectedFile;

    this.networkEpgLogoUpload.networkFile = selectedFile;

    if (!this.imageExtensions.includes(fileExtension)) {
      this.imageMessage = `El archivo ${fileName} no tiene una extensión válida.`;
    }
    else if (selectedFile.size === 0) {
      this.imageMessage = `El archivo ${fileName} se encuentra vacío.`;
    }


    reader.readAsDataURL(selectedFile);
    reader.onload = (e: any) => {

      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        if (height != 120 && width != 240) {
          this.imageMessage = `El archivo ${fileName} no tiene el tamaño adecuado \ (${this.maxWidthLogo}px x ${this.maxHeightLogo}px).`;
        } else {
          this.imageMessage = fileName;
          this.networkImage = e.target.result;
        }
      };
    };
  }

  onImageReflectedSelected(event: any) {

    const selectedFile = event.target.files[0];

    if (!selectedFile) return;
    const fileName = selectedFile.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    const reader = new FileReader();


    this.networkEpgReflectedLogoUpload.fileName = selectedFile;

    const networkCode = this.networkForm.value.code

    //le da al logo el nombre del codigo de la network
    this.networkEpgReflectedLogoUpload.fileName = (networkCode + "." + fileExtension).toLowerCase();
    this.networkEpgReflectedLogoUpload.networkFile = selectedFile;

    if (!this.imageExtensions.includes(fileExtension)) {
      this.imageReflectedMessage = `El archivo ${fileName} no tiene una extensión válida.`;
    }
    else if (selectedFile.size === 0) {
      this.imageReflectedMessage = `El archivo ${fileName} se encuentra vacío.`;
    }

    reader.readAsDataURL(selectedFile);
    reader.onload = (e: any) => {

      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        if (height != 96 && width != 97) {
          this.imageReflectedMessage = `El archivo ${fileName} no tiene el tamaño adecuado \ (${this.maxWidthLogoRef}px x ${this.maxHeightLogoRef}px).`;
        } else {
          this.imageReflectedMessage = fileName;
          this.networkReflectedImage = e.target.result;
        }
      };
    };
  }




  toggleEdit(): void {
    this.isEditing = !this.isEditing; // Alternar entre habilitar/deshabilitar la edición
    if (this.isEditing) {
      this.networkForm.enable();
    } else {
      this.networkForm.disable(); // Deshabilitar el formulario si se cancela la edición
    }
  }

  useDefaultImage(reflected: number): void {
    if (reflected) {
      this.networkReflectedImage = this.defaultLogoUrl;
    }
    this.networkImage = this.defaultLogoUrl;

  }


  // Snackbars
  showSnackbar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.duration = 3000;
    config.verticalPosition = "top";
    config.panelClass = ['SuccessfulSnackbar'];
    this.snackBar.open(message, action, config);
  }

  showErrorSnackbar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.panelClass = ['error-snackbar'];
    this.snackBar.open(message, action, config);
  }

  ngOnDestroy(): void {
    this.unsubscription$.next();
    this.unsubscription$.complete();
  }

  getButtonLabel(): string {
    return this.isEditing ? 'epg.configurationNetworks.cancel' : 'epg.configurationNetworks.edit';
}

private mapperToMessages(translations: any): void {
    this.errorMessage = translations['epg.networks_upload.messages.errorUpload'];
    this.errorDBMessage = translations['epg.networks_upload.messages.errorUpload'];
    this.successfulFilesPublicationMessage = translations['epg.networks_upload.messages.successUpload'];
  }
}
