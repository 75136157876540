import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Network } from 'src/app/shared/model/network';
import { NetworkService } from 'src/app/shared/service/network.service';
import { EpgDataService } from 'src/app/shared/service/epg-data.service';
import { DataNetwork } from 'src/app/shared/model/data-network';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-configuration-networks',
  templateUrl: './configuration-networks.component.html',
  styleUrls: ['./configuration-networks.component.css']
})
export class ConfigurationNetworksComponent implements OnInit {

  private unsubscription$: Subject<void> = new Subject<void>();
  private dataNetwork: DataNetwork;

  networks: Network[];
  loadingNetworks = false;
  networks$: Observable<Network[]> = of([]);
  style: any = {};

  activateStyle: any = {
    opacity: '1',
    filter: 'grayscale(0%)',
    clicked: false
  };
  deactivateStyle: any = {
    opacity: '.5',
    filter: 'grayscale(100%)',
    clicked: false
  };

  // Para pasar el network code a ancine
  selectedNetwork$ = new Subject<Network>();
  selectedNetwork: Network | null = null;
  selectedNetworkCode$ = this.selectedNetwork$.pipe(map(n => n.code));
  showingAncine: boolean = false;
  showCreateNetwork: boolean = false;
  selectedNetworkCode: string;


  constructor(
    private networkService: NetworkService,
    private epgDataService: EpgDataService,
    //   private route: ActivatedRoute
  ) {

    this.dataNetwork = new DataNetwork();
    this.unsubscription$ = new Subject<void>();
  }

  ngOnInit(): void {

    this.loadNetworks();
    this.selectedNetwork = null;

  }

  loadNetworks(): void {
    let nets: Observable<Network[]>;

    this.loadingNetworks = true;


    nets = this.networkService.getNetworks$();

    nets.pipe(takeUntil(this.unsubscription$))
      .subscribe(networks => this.mapperNetworks(networks))
      .add(() => this.loadingNetworks = false);
  }


  private mapperNetworks(networks: Network[]): void {
    this.networks = networks;
    this.networks.forEach(network => this.style[network.code] = { ...this.deactivateStyle });
    this.networks$ = of(this.networks);
  }

  clickNetwork(network: Network): void {
    this.showCreateNetwork = false;
    const codeNetwork = network.code;
    this.dataNetwork.codeNetwork = codeNetwork;
    this.dataNetwork.isClicked = this.style[codeNetwork].clicked;
    this.epgDataService.codeNetworkSubject$.next(this.dataNetwork);
    this.selectedNetworkCode = codeNetwork;

    this.selectedNetwork$.next(network);
    // this.selectedNetwork = network;

    if (this.style[codeNetwork].clicked) {
      this.style[codeNetwork] = { ...this.deactivateStyle };
    } else {
      this.networks.forEach(n => this.style[n.code] = { ...this.deactivateStyle });
      this.style[codeNetwork] = { ...this.activateStyle, clicked: true };
    }
  }


  toggleCreateNetwork() {
    this.showCreateNetwork = !this.showCreateNetwork; // Cambia la visibilidad


    if (this.showCreateNetwork) {
      // Limpiar la network seleccionada al crear una nueva
      this.selectedNetwork = null;
    } else {
      // Resetear el estilo de las redes cuando se cancela la creación
      this.resetNetworkStyles();
    }
  }

  private resetNetworkStyles(): void {
    this.networks.forEach(n => this.style[n.code] = { ...this.deactivateStyle, clicked: false });
  }

  ngOnDestroy(): void {
    this.unsubscription$.next();
    this.unsubscription$.complete();
  }

  getButtonLabel(): string {
    return this.showCreateNetwork ? 'epg.configurationNetworks.cancel' : 'epg.configurationNetworks.createNew';
  }
}

