/**
 * Helper con funcionalidades comunes
 * para el manejo de Arrays
 *
 * author gamartinez
 *
 */
export class ArrayHelper {

    /**
     * Ordena el array pasado por parametro,
     * por el campo del mismo pasado por parametro
     *
     * param list
     * param nameField
     */
    public static orderBy(list: Array<any>, nameField: string): void {
        if (this.validateParams(list, nameField)) {
            return;
        }
        list.sort((a, b) => {
            if (a[nameField] > b[nameField]) {
                return 1;
            }
            if (a[nameField] < b[nameField]) {
                return -1;
            }
            return 0;
        });
    }

    /**
     * Verifica si el elemento pasado por parametro,
     * esta dentro del array pasado por parametro
     * (similar a array.includes() pero con objetos)
     *
     * param list
     * param element
     */
    public static contains(list: Array<any>, element: any): boolean {
        if (this.validateParams(list, element)) {
            return;
        }
        let i = list.length;
        while (i--) {
            if (list[i].id === element.id) {
                return true;
            }
        }
        return false;
    }

    /**
     * Valida que los parametros recibidos no sean nulos o indefinidos
     *
     * param list
     * param field
     */
    private static validateParams(list: Array<any>, field: any): boolean {
        if (!list || !field) {
            console.error('No puede recibir un parametro undefined');
            return true;
        }
        return false;
    }

}
