import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NetworkEpg } from '../model/network-epg-model/network-epg';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { NewNetworkEpg } from '../model/new-network-epg';
import { MonthlyUploadResponse } from '../model/monthly-upload-model/MonthlyUploadResponse';
import { catchError } from 'rxjs/operators';
import { GeneralService } from './general.service';
import { NetworkEpgFilesUpload } from '../model/network-epg-model/network-epg-files-upload';



@Injectable({
  providedIn: 'root'
})
export class NetworkEpgService {

  constructor( 
    private http: HttpClient,
    private gralService: GeneralService
  ) { }

  getAllNetwork(){
    return this.http.get<NetworkEpg[]>(environment.apiNetworEpgGetAllNetwork);
  }

  getActiveNetworks(){
    return this.http.get<NetworkEpg[]>(environment.apiNetworkEpgGetActiveNetworks);
  }

  saveNetworkEpg(networkEpg: NewNetworkEpg): Observable<NewNetworkEpg> {
    return this.http.post<any>(environment.apiDomainNetworkEpgSave, networkEpg).pipe(
      catchError(this.gralService.handleError)
    );
  }

  
  uploadFilesToS3(epgUpload: any) {
    let formData = new FormData();


    formData.append('logoImageName', epgUpload.logo.fileName);
    formData.append('logoImageFile', epgUpload.logo.networkFile);

    formData.append('reflectedLogoImageName', epgUpload.reflectedLogo.fileName);
    formData.append('reflectedLogoImageFile', epgUpload.reflectedLogo.networkFile);

    
    //cambiar el nombre de la response 
    return this.http.post<MonthlyUploadResponse>(environment.apiEpgSaveNetworkFileToS3, formData).pipe(
      catchError(this.gralService.handleError));
  }


  downloadFile(url: string): void {
    let filename = url.split('/').pop();
    const blob = new Blob([url]);
    saveAs(blob,filename);
  }

}
