import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CategorySelect } from 'src/app/shared/model/categorySelect';
import { UtilService } from 'src/app/shared/service/util.service';

@Component({
  selector: 'app-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.css']
})
export class CategoryDialogComponent implements OnInit {
  categoryForm: FormGroup;
  isEditing: boolean;

  constructor(
    public dialogRef: MatDialogRef<CategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { category: CategorySelect, isEditing: boolean },
    private fb: FormBuilder,
    private translateService: TranslateService,
    private utilService: UtilService
  ) {
    this.categoryForm = this.fb.group({
      name: ['', Validators.required],
    });
    this.isEditing = data.isEditing;
  }

  ngOnInit(): void {
    if (this.isEditing && this.data.category) {
      this.categoryForm.setValue({
        name: this.data.category.type,
      });
    }
  }

  async onSave() {
    if (this.categoryForm.valid) {
      let textConfirm = this.isEditing
        ? await this.translateService.get('epg.users.dialog.messageEditConfirm').toPromise()
        : await this.translateService.get('epg.users.dialog.messageAddConfirm').toPromise();

      let buttonConfirm = this.isEditing
        ? await this.translateService.get('epg.users.dialog.buttons.edit').toPromise()
        : await this.translateService.get('epg.users.dialog.buttons.add').toPromise();

      const dialogRef = this.utilService.openConfirmDialog({
        title: buttonConfirm,
        text: textConfirm,
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          var newCategory: CategorySelect = {
            type: this.categoryForm.value.name
          }
          this.dialogRef.close(newCategory);
        }
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
