import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { CategorySelect } from 'src/app/shared/model/categorySelect';
import { CategoryService } from 'src/app/shared/service/category.service';
import { UtilService } from 'src/app/shared/service/util.service';
import { CategoryDialogComponent } from './category-dialog/category-dialog.component';

@Component({
  selector: 'app-category-options',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {


  categories: CategorySelect[] = [];
  displayedColumns: string[] = ['code', 'name', 'actions'];
  dataSource = new MatTableDataSource<CategorySelect>();
  categoryForm: FormGroup;
  isEditing: boolean = false;
  selectedCategoryId?: number;

  constructor(private categoryService: CategoryService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private utilService: UtilService
) {

  }

  ngOnInit(): void {
    this.loadCategories();
  }


  loadCategories() {
    this.categoryService.getCategories().subscribe(
      (data) => {
        this.categories = data;
        this.dataSource.data = this.categories;  // Asignar datos a la tabla
      },
      (error) => {
        console.error('Error al cargar los paises:', error);
      }
    );
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(CategoryDialogComponent, {
      width: '400px',
      data: { category: null, isEditing: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.categoryService.addCategory(result).subscribe(() => this.loadCategories());
      }
    });
  }

  openEditDialog(category: CategorySelect) {
    const dialogRef = this.dialog.open(CategoryDialogComponent, {
      width: '400px',
      data: { category, isEditing: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.categoryService.updateCategory(category.id, result).subscribe(() => this.loadCategories());
      }
    });
  }

  async deleteCategory(id: number) {
    const textConfirm = await this.translateService.get('epg.users.dialog.messageDeleteConfirm').toPromise();
    const buttonConfirm = await this.translateService.get('epg.users.dialog.buttons.delete').toPromise();

    // Abrir el diálogo de confirmación
    const dialogRef = this.utilService.openConfirmDialog({
      title: buttonConfirm,
      text: textConfirm,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Si el usuario confirma, proceder a eliminar el idioma
        this.categoryService.deleteCategory(id).subscribe(() => {
          this.loadCategories();
        });
      }
    });
  }
  resetForm() {
    this.categoryForm.reset();
    this.isEditing = false;
    this.selectedCategoryId = undefined;
  }
}
