import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigGridAlias } from 'src/app/shared/model/config-grid-alias';
import { Feed } from 'src/app/shared/model/feed';
import { ArrayHelper } from 'src/app/shared/utils/array-helper';

@Component({
    selector: 'app-field-selector',
    templateUrl: './field-selector.component.html',
    styleUrls: ['./field-selector.component.css']
})
export class FieldSelectorComponent {

    @Input()
    selection: SelectionModel<Feed>;

    @Input()
    loadingAlias: boolean;

    @Input()
    loadingSelected: boolean;

    @Input()
    configAlias$: Observable<ConfigGridAlias[]>;

    @Input()
    configSelected$: Observable<ConfigGridAlias[]>;

    drop(event: CdkDragDrop<ConfigGridAlias[]>) {
        if (this.selection.selected.length === 0) {
            return;
        }
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
            if (event.container.id === 'containerToSelect') {
                ArrayHelper.orderBy(event.container.data, 'field');
            }
        }
    }

}
