import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Network } from 'src/app/shared/model/network';
import { NetworkService } from 'src/app/shared/service/network.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {

    loadingNetworks = false;
    logoUrl: string;
    networks$: Observable<Network[]>;

    private unsubscription$: Subject<void>;

    constructor(private networkService: NetworkService) {
        this.unsubscription$ = new Subject<void>();
        //this.logoUrl = environment.bucketUrlImages + 'turner_logo.jpg';
        this.logoUrl = environment.bucketUrlImages + 'wbd_new_logo.png';
    }

    ngOnInit() {
        this.loadingNetworks = true;
        this.networkService
            .getNetworks$()
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(data => {
                this.networks$ = of(data)
                this.networkService.updateNetworks(data); //use this method to send network data to other components
            }).add(() => this.loadingNetworks = false);       
    }

    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }

}
