import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileConfigurations } from '../model/file-configurations';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileConfigurationsService {

  constructor(private http: HttpClient) { }

  getAllFilesConf(){
    return this.http.get<FileConfigurations[]>(environment.apiDomainFileConfigurationAll);
  }

  getAllFilesConfByFileType(fileType: string){
    var params = new HttpParams();
    if (fileType)
      params = params.set('fileType', fileType + "");

    return this.http.get<FileConfigurations>(environment.apiDomainFileConfigurationByFileType, { params, });
  }

  getAllFilesConfByFileTypeEnabled() {
    return this.http.get<FileConfigurations[]>(environment.apiDomainFileConfigurationByFileTypeEnabled);
  }

}
