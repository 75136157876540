import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { Injectable } from '@angular/core';
import { AuthorizationService } from '../service/authorization.service';
import { UrlService } from '../service/url.service';

/**
 * Evita que los usuarios no autenticados, accedan a rutas restringidas
 */
@Injectable({ providedIn: 'root' })
export class EpgGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private urlService: UrlService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            const isAuthorized = this.authorizationService.isAuthorized(route.data.allowedRoles);
            if (!isAuthorized) {
                this.router.navigate(['/access_denied']);
            }
            return true;
        }
        // no ha iniciado session, guarda URL, redirije al login
        let url:string;
        url = state.url;
        if(url.includes("?")){
            url = url.slice(0,url.indexOf("?"));
        }
        
      this.urlService.setRedirectUrl(url, route.queryParams);
        this.router.navigate(['/login']);
        return false;
    }

}
