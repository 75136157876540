import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-websites-utils-dialog',
  templateUrl: './websites-utils-dialog.component.html',
  styleUrls: ['./websites-utils-dialog.component.scss']
})
export class WebsitesUtilsDialogComponent implements OnInit {

  websitesData: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WebsitesUtilsDialogComponent>,
  ) {
        this.websitesData = data.split(",").filter(url => url !== '');
   }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  openLink(link:string){
    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      link = 'https://' + link;
    }
    window.open(link, '_blank');

  }
}
