import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FeedResponse } from '../response/feed.response';
import { GeneralService } from './general.service';
import { Feed } from '../model/feed';

@Injectable({
    providedIn: 'root'
})
export class FeedService {
    [x: string]: any;

    constructor(
        private http: HttpClient,
        private gralService: GeneralService) {
    }

    getFeeds$(): Observable<Feed[]> {
        this.http.get<FeedResponse>(environment.apiDomainUrlGetFeed).pipe(map(response => response.feeds),catchError(this.gralService.handleError))
        //console.log("****"+ environment.apiDomainUrlGetFeed);
        return this.http.get<FeedResponse>(environment.apiDomainUrlGetFeed).pipe(map(response => response.feeds),catchError(this.gralService.handleError));
    }

}
