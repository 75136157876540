import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogVersion } from 'src/app/shared/model/log-version';
import { GeneralService } from 'src/app/shared/service/general.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ShowService {

    constructor(
        private http: HttpClient,
        private gralService: GeneralService
    ) { }

    download$(feed: string): Observable<HttpResponse<Blob>> {
        return this.http
                   .get<Blob>(environment.apiEpgUrlDownload + feed, {
                       observe: 'response',
                       responseType: 'blob' as 'json'
                   })
                   .pipe(catchError(this.gralService.handleError));
    }

    getLogVersion$(feed: string): Observable<LogVersion> {
        return this.http
                   .get<LogVersion>(environment.apiEpgUrlLogVersion + feed)
                   .pipe(catchError(this.gralService.handleError));
    }

    legalDownload$(
            reportType: string,
            feed: string,
            dateFrom: Date,
            dateTo: Date): Observable<HttpResponse<Blob>> {

        const requestURI = environment.apiEpgUrlLegalDownload
            + reportType + '/'
            + feed + '/'
            + this.dateFromISO(dateFrom) + '/'
            + this.dateToISO(dateTo);

        return this.http
            .get<Blob>(requestURI, {
                observe: 'response',
                responseType: 'blob' as 'json'
            })
            .pipe(catchError(this.gralService.handleError));
    }

    private dateFromISO(date: Date): string {
        let dateFromISO = new Date(date)
        dateFromISO.setUTCHours(6, 0, 0, 0);
        const dateISO = dateFromISO.toISOString();
        //date.setUTCHours(6, 0, 0, 0);
        //const dateISO = date.toISOString();
        return dateISO;
    }

    private dateToISO(date: Date): string {
        let dateToISO = new Date(date)
        dateToISO.setUTCHours(5, 59, 59, 999);
        const dateISO = dateToISO.toISOString();
        //date.setUTCHours(5, 59, 59, 999);
        //const dateISO = date.toISOString();
        return dateISO;
    }

}
