import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Feed } from 'src/app/shared/model/feed';
import { Module } from 'src/app/shared/model/module';

@Component({
    selector: 'app-config-feed-grid',
    templateUrl: './config-feed-grid.component.html',
    styleUrls: ['./config-feed-grid.component.css']
})
export class ConfigFeedGridComponent implements OnInit {

    @Input()
    module: Module;

    @Input()
    dataSource = new MatTableDataSource<Feed>();

    displayedColumns: string[];

    @Input()
    selection: SelectionModel<Feed>;

    @Input()
    loadingDatasource: boolean;

    @Output()
    public loadFieldToSelect = new EventEmitter();

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    ngOnInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.displayedColumns = [
            'code',
            'name',
            'selected'
        ];
    }

    change(event: MatCheckboxChange, row: Feed) {
        this.loadFieldToSelect.emit();
        if (event) {
            return this.selection.toggle(row);
        } else {
            return null;
        }
    }

    /** Selecciona/Deselecciona un Feed */
    checkboxLabel(row?: Feed): string {
        const isSelected: boolean = this.selection.isSelected(row);
        this.dataSource
            .data
            .find(feed => feed.id === row.id)
            .selected = isSelected;

        if (isSelected) {
            return `deselect row ${row.id}`;
        } else {
            return `select row ${row.id}`;
        }
    }

}
