import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ModuleService } from 'src/app/shared/service/module.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Module } from 'src/app/shared/model/module';
import { MatSelectChange } from '@angular/material/select';


@Component({
    selector: 'app-filter-report',
    templateUrl: './filter-report.component.html',
    styleUrls: ['./filter-report.component.css']
})
export class FilterReportComponent implements OnInit, OnDestroy {

    @Output()
    public reportTypeEmit = new EventEmitter<Module>();

    @Output()
    public dateFromEmit = new EventEmitter<Date>();

    @Output()
    public dateToEmit = new EventEmitter<Date>();

    private readonly MIN_DATE_IN_DAYS = new Date().getDate() - 30;
    private readonly MAX_DATE_IN_DAYS = new Date().getDate() + 60;
    private readonly START_LEGAL_ID = 'LEG';

    reportTypes: Module[];

    minDate = new Date();
    maxDate = new Date();
    reportTypeCtrl = new FormControl('', Validators.required);
    range = new FormGroup({
        start: new FormControl('', Validators.required),
        end: new FormControl('', Validators.required)
    });
    private unsubscription$ = new Subject<void>();

    constructor(private moduleService: ModuleService) {}

    ngOnInit(): void {
        this.minDate.setDate(this.MIN_DATE_IN_DAYS);
        this.maxDate.setDate(this.MAX_DATE_IN_DAYS);
        this.loadModules();
    }

    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }

    private loadModules(): void {
        this.moduleService
            .getModules$()
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(modules => this.reportTypes = modules.filter(module => module.id.startsWith(this.START_LEGAL_ID)));
    }

    onReportTypeChange(event: MatSelectChange) {
        this.reportTypeEmit.emit(event.value);
    }

    addEventFrom(event: MatDatepickerInputEvent<Date>) {
        this.dateFromEmit.emit(event.value);
    }

    addEventTo(event: MatDatepickerInputEvent<Date>) {
        this.dateToEmit.emit(event.value);
    }

}
