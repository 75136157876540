import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomPaginator extends MatPaginatorIntl implements OnDestroy {

    private translateSubscription: Subscription;

    constructor(private translateService: TranslateService) {
        super();
        this.getTranslations();
    }

    ngOnDestroy(): void {
        this.translateSubscription.unsubscribe();
    }

    getTranslations() {
        this.translateSubscription = this.translateService.stream([
            'paginator.itemsPerPage',
            'paginator.firstPage',
            'paginator.previousPage',
            'paginator.nextPage',
            'paginator.lastPage'
        ]).subscribe(translation => {
            this.itemsPerPageLabel = translation['paginator.itemsPerPage'];
            this.firstPageLabel = translation['paginator.firstPage'];
            this.previousPageLabel = translation['paginator.previousPage'];
            this.nextPageLabel = translation['paginator.nextPage'];
            this.lastPageLabel = translation['paginator.lastPage'];
            this.changes.next();
        });
    }

    getRangeLabel = function(page: number, pageSize: number, length: number) {
        if (length === 0 || pageSize === 0) {
            return '0 de ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;

        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;

        return this.translateService.instant(
            'paginator.range', {
                startIndex,
                endIndex,
                length
            }
        );
    };

}
