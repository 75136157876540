import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Newsletter } from 'src/app/shared/model/newsletters-model/newsletter';
import { NewsletterResponse } from 'src/app/shared/response/newsletter.response';
import { NewsletterService } from 'src/app/shared/service/newsletter.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-newsletter-draft-table',
  templateUrl: './newsletter-draft-table.component.html',
  styleUrls: ['./newsletter-draft-table.component.scss']
})
export class NewsletterDraftTableComponent implements OnInit {

    newsletters: Newsletter[];

    displayedColumns: string[] = ['dateCreated', 'from', 'subject', 'destinatarios', 'network', 'language', 'country'];
    dataSource: MatTableDataSource<Newsletter>;

    currentPage: number = 0;
    pageSize: number = 10;
    totalSize: number = 0;

    checked: boolean = false;

    filterValue: string = '';
    private filterSubject = new BehaviorSubject<string>('');

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort = new MatSort();

    constructor(
        private newsletterService: NewsletterService,
        private translateService: TranslateService
    ) {
        this.dataSource = new MatTableDataSource([]);
    }

    ngOnInit(): void {
        this.filterSubject.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            filter(value => value.length === 0 || value.length >= 3),
        ).subscribe(filterValue => {
            this.currentPage = 0;
            this.loadNewsletters( this.checked ? JSON.parse(localStorage.getItem('user')).id : null, filterValue);
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loadNewsletters();
    }

    loadNewsletters(userId: number = null, keyword?: string) {
        let state: string = 'DRAFT';
        const sortField = this.sort.active;
        const sortDirection = this.sort.direction;

        const key = keyword ? keyword : null;

        this.newsletterService.getNewsletters$(this.currentPage, this.pageSize, state, userId, keyword).subscribe((resp: NewsletterResponse) => {
            this.dataSource = new MatTableDataSource(resp.newsletters);
            this.newsletters = resp.newsletters;
            this.totalSize = resp.totalElements;
        });
    }

    getOnlyMe() {
        this.checked = !this.checked;
        let userId = this.checked ? JSON.parse(localStorage.getItem('user')).id : null;
        this.loadNewsletters(userId);
    }

    onChangePage(event) {
        this.currentPage = event.pageIndex;
        this.pageSize = event.pageSize;
        let userId = this.checked ? JSON.parse(localStorage.getItem('user')).id : null;
        this.loadNewsletters(userId);
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.filterSubject.next(filterValue);
      }

      clearFilter(){
        this.filterValue = '';
        this.filterSubject.next('');
      }

}
