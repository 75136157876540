export enum Role {
    SuperAdmin = 'ROLE_SUPER_ADMIN',
    AdminContent = 'ROLE_ADMIN_CONTENT',
    AdminUsers = 'ROLE_ADMIN_USERS',
    AdminPress = 'ROLE_ADMIN_PRESS',
    Ancine = 'ROLE_ANCINE',
    Localbreaks = 'ROLE_LOCALBREAKS',
    User = 'ROLE_USER',
    Legal = 'ROLE_LEGAL',
    Logs = 'ROLE_LOGS',
    Microservices = 'ROLE_MICROSERVICES'
}
