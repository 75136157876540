import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Feed } from 'src/app/shared/model/feed';
import { AlertService } from 'src/app/shared/service/alert.service';
import { ShowService } from 'src/app/shared/service/show.service';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';
import { Module } from 'src/app/shared/model/module';

@Component({
    selector: 'app-feed-download',
    templateUrl: './feed-download.component.html',
    styleUrls: ['./feed-download.component.css']
})
export class FeedDownloadComponent implements OnDestroy {

    @Input()
    reportType: Module;

    @Input()
    selection: SelectionModel<Feed>;

    @Input()
    dateFrom: Date;

    @Input()
    dateTo: Date;

    validateReportMessage: string;
    validateRangeMessage: string;
    alertMessageDownload: string;

    loadingDownload = false;

    private unsubscription$: Subject<void>;

    constructor(
            private showService: ShowService,
            private alertService: AlertService,
            private translateService: TranslateService,
            iconRegistry: MatIconRegistry,
            sanitizer: DomSanitizer) {

        iconRegistry.addSvgIcon('download',
            sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/cloud-download-24px.svg')
        );
        this.unsubscription$ = new Subject<void>();
        this.translations();
    }

    private translations(): void {
        const keys = [
            'epg.legal.validateReportMessage',
            'epg.legal.validateRangeMessage',
            'epg.legal.alertMessageDownload'
        ];
        this.translateService
            .stream(keys)
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(translations => this.mapperToMessages(translations));
    }

    private mapperToMessages(translations: any): void {
        this.validateReportMessage = translations['epg.legal.validateReportMessage'];
        this.validateRangeMessage = translations['epg.legal.validateRangeMessage'];
        this.alertMessageDownload = translations['epg.legal.alertMessageDownload'];
    }

    ngOnDestroy() {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }

    download(): void {
        this.alertService.clear();
        if (!this.validateParams()) {
            return;
        }

        this.loadingDownload = true;
        const selecteds = this.selection.selected;
        const feedsTotal = selecteds.length;
        let feedsCounter = 0;

        selecteds.forEach(feed => {
            this.showService
                .legalDownload$(
                    this.reportType.id,
                    feed.alias,
                    this.dateFrom,
                    this.dateTo)
                .pipe(takeUntil(this.unsubscription$))
                .subscribe((response: HttpResponse<Blob>) => this.downloadFile(response))
                .add(() => this.loadingDownload = (++feedsCounter !== feedsTotal));
        });
        if (feedsTotal === 0) {
            this.alertService.error(this.alertMessageDownload);
            this.loadingDownload = false;
        }
    }

    private validateParams(): boolean {
        if (!this.reportType) {
            this.alertService.error(this.validateReportMessage);
            return false;
        }
        if (!this.dateFrom || !this.dateTo) {
            this.alertService.error(this.validateRangeMessage);
            return false;
        }
        return true;
    }

    private downloadFile(response: HttpResponse<Blob>): void {
        if (response.body === null) {
            this.alertService.error(
                this.getContentDispositionValue(response.headers)
            );
        } else {
            const url = window.URL.createObjectURL(response.body);
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.href = url;
            // Nombre del archivo
            a.download = this.getContentDispositionValue(response.headers);
            a.click();
        }
    }

    /**
     * Devuelve el valor de la cabecera Content-Disposition
     *
     * @param headers cabeceras http
     */
    private getContentDispositionValue(headers: HttpHeaders): string {
        return headers.get('Content-Disposition')
            .split('=')[1]
            .replace('"', '')
            .replace('"', '');
    }

}
