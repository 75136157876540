import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent {

    imageUrl: string;

    constructor() {
        this.imageUrl = environment.bucketUrlImages + '404.gif';
    }

}
