import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConfigGridAlias } from '../model/config-grid-alias';
import { ConfigGridMaster } from '../model/config-grid-master';
import { ConfigAliasResponse } from '../response/config-alias-response';
import { ConfigResponse } from '../response/config.response';
import { GeneralService } from './general.service';
import { ConfigurationsRequest } from '../request/configurations.request';
import { ConfigurationsResponse } from '../response/configurations.response';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    private readonly LOCALE_ENGLISH = 'en';

    constructor(
        private http: HttpClient,
        private gralService: GeneralService) {
    }

    getConfigAlias$(): Observable<ConfigGridAlias[]> {
        return this.http
            .get<ConfigAliasResponse>(environment.apiDomainUrlGetConfigAlias)
            .pipe(
                map(response => response
                    .configGridAliasList
                    .filter(field => field.locale.id === this.LOCALE_ENGLISH)),
                catchError(this.gralService.handleError)
            );
    }

    getConfigurations$(moduleId: string): Observable<ConfigGridMaster[]> {
        return this.http
            .get<ConfigResponse>(environment.apiDomainUrlGetConfigurations + moduleId)
            .pipe(
                map(response => response.configurations),
                catchError(this.gralService.handleError)
            );
    }

    postConfigurations$(request: ConfigurationsRequest): Observable<ConfigurationsResponse> {
        return this.http
            .post<ConfigurationsResponse>(environment.apiDomainUrlPostConfigurations, request)
            .pipe(catchError(this.gralService.handleError));
    }

}
