import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { FormControl, Validators } from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { NetworkEpg } from 'src/app/shared/model/network-epg-model/network-epg';
import { NetworkEpgService } from 'src/app/shared/service/network-epg.service';
import { NewsletterService } from 'src/app/shared/service/newsletter.service';
import { MatTabChangeEvent } from '@angular/material/tabs/tab-group';
import { Newsletter } from 'src/app/shared/model/newsletters-model/newsletter';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UtilService } from 'src/app/shared/service/util.service';
import { UserProfileService } from 'src/app/shared/service/user-profile.service';
import { CountryEnum } from 'src/app/shared/constants/country.enum';
import { LanguageEnum } from 'src/app/shared/constants/language.enum';
import { NewsletterRequest } from 'src/app/shared/request/newsletter.request';
import { NewsletterResponse } from 'src/app/shared/response/newsletter.response';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-newsletters',
  templateUrl: './newsletters.component.html',
  styleUrls: ['./newsletters.component.scss']
})
export class NewslettersComponent implements OnInit {

    disableCountrySelect: boolean = true;
    disableLangualgeSelect: boolean = true;
    disableUserSelect: boolean = true;

    networkCrtl = new FormControl('', Validators.required);
    countryCrtl = new FormControl('',Validators.required);
    langCrtl = new FormControl('', Validators.required);
    usersCrtl = new FormControl();
    templateCtrl = new FormControl('', Validators.required);
    subjectCtrl = new FormControl('');


    networkList: NetworkEpg[] = [];

    newsletterList: NewsletterResponse;
    onlyMe: boolean;
    username: string = '';
    indexTab: number;

    loadingSend: boolean = false;
    loadingDraft: boolean = false;

    allNetworksSelected = false;
    allCountriesSelected = false;
    AllUsersSelected = false;

    AllTurnerChecked = false;

    countryList = Object.keys(CountryEnum).map(key => ({ value: key, name: CountryEnum[key] }));

    langList = Object.keys(LanguageEnum).map(key => ({ value: key, name: LanguageEnum[key] }));

    usersList = []

    filteredNetworkList = this.networkList;
    filteredUserList = this.usersList;

    templateList = [
        { value: '1', name: 'Default' },
    ]


    private unsubscription$ = new Subject<void>();

    constructor(
        private authenticationService: AuthenticationService,
        private networkEpgService: NetworkEpgService,
        private newsletterService: NewsletterService,
        private userProfileService: UserProfileService,
        private translateService: TranslateService,
        private matSnackBar: MatSnackBar
    ) {

        this.networkEpgService.getActiveNetworks().pipe(
            map(result => result.sort((a, b) => a.nameEn.toLowerCase().localeCompare(b.nameEn.toLowerCase())))
          ).subscribe(sortedResult => {
            this.networkList = sortedResult;
            this.filteredNetworkList = this.networkList
          });

        this.username = this.authenticationService.currentUserValue.username;

        this.newsletterService.getIndexTab$().subscribe((data: any) => {
            this.indexTab = data;
        });

        this.newsletterService.getOnlyMe$().subscribe((data: any) => {
            this.onlyMe = data;
            this.getNewsletters(this.indexTab);
        });

     }

    ngOnInit(): void {
        if(localStorage.getItem('user') === null) {
            this.userProfileService.getUserProfileByUsername$(this.username).subscribe((data:any) => {
                localStorage.setItem('user', JSON.stringify(data.userProfiles[0]));
            });
        }

        this.networkCrtl.setValue(this.networkList.map(network => network.id));


        combineLatest([
            this.networkCrtl.valueChanges.pipe(startWith(this.networkCrtl.value)),
            this.countryCrtl.valueChanges.pipe(startWith(this.countryCrtl.value)),
            this.langCrtl.valueChanges.pipe(startWith(this.langCrtl.value))
          ])
            .pipe(
              takeUntil(this.unsubscription$),
              filter(([network, country, language]) => !!network && !!country && !!language)
            )
            .subscribe(([network, country, language]) => {
                this.userProfileService.getUserProfilesToNewsletter(network, country, language).pipe(
                    map(result => result.userProfiles.sort((a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase())))
                    ).subscribe(sortedResult => {
                    this.disableUserSelect = false;
                    this.usersList = sortedResult;
                    this.filteredUserList = this.usersList;
                },
                error => {
                    this.openErrorSnackBar("USERS NOT FOUND", 'Close');
                });
            });
    }

    filterNetworks(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

        if(!filterValue ) {
            this.filteredNetworkList = this.networkList;
            return;
        }

        this.filteredNetworkList = this.networkList.filter(network => network.nameEn.toLowerCase().includes(filterValue));
    }

    filterUsers(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

        if(!filterValue) {
            this.filteredUserList = this.usersList;
            return;
        }

        this.filteredUserList = this.filteredUserList.filter(user => user.username.toLowerCase().includes(filterValue));
    }

    getAllTurnerUsers() {
        this.AllTurnerChecked = !this.AllTurnerChecked;
        if(!this.AllTurnerChecked) {
            this.userProfileService.getUserProfilesToNewsletter(this.networkCrtl.value , this.countryCrtl.value, this.langCrtl.value).subscribe((data:any) => {
                this.usersList = data.userProfiles;
            });
        }
        else{
            this.userProfileService.getUserProfilesToNewsletter(this.networkCrtl.value , this.countryCrtl.value, this.langCrtl.value, true).subscribe((data:any) => {
                this.usersList = data.userProfiles;
            });
        }
        this.usersCrtl.setValue([]);
    }

    toggleSelectAllNetworks() {
        this.allNetworksSelected = !this.allNetworksSelected;

        if (!this.allNetworksSelected) {
            this.networkCrtl.setValue([]);
        } else {
            let netwoksIds= this.networkList.map(network => network.id);
            netwoksIds.push(0);
            this.networkCrtl.setValue(netwoksIds);
        }

      }

      toggleSelectAllCountries() {
        this.allCountriesSelected = !this.allCountriesSelected;

        if (!this.allCountriesSelected) {
            this.countryCrtl.setValue([]);
        } else {
            let countriesIds = this.countryList.map(country => country.value);
            countriesIds.push("-1");
            this.countryCrtl.setValue(countriesIds);
        }
      }

    toggleSelectAllUsers() {
        this.AllUsersSelected = !this.AllUsersSelected;

        if (!this.AllUsersSelected) {
            this.usersCrtl.setValue([]);
        } else {
            let usersIds = this.usersList.map(user => user.id);
            usersIds.push(0);
            this.usersCrtl.setValue(usersIds);
        }
    }



      changeNetworks() {
        this.disableCountrySelect = false;
      }

      changeCountries() {
        this.disableCountrySelect = false;
      }




      isAllSelectedNetworks() {
        this.allNetworksSelected = !this.allNetworksSelected;
      }

      isAllSelectedCountries() {
        this.allCountriesSelected = !this.allCountriesSelected;
      }


    onTabChange(event: MatTabChangeEvent): void {
        const selectedTabIndex = event.index;
        this.getNewsletters(selectedTabIndex);
    }

    resetForm(): void {
        this.networkCrtl.reset() ;
        this.countryCrtl.reset();
        this.langCrtl.reset();
        this.usersCrtl.reset();
        this.templateCtrl.reset();
        this.subjectCtrl.reset();
        this.newsletterService.setNewsletterMessage('');
        this.AllTurnerChecked = false;
        this.disableCountrySelect = true;
        this.disableLangualgeSelect = true;
        this.disableUserSelect = true;
    }

    getNewsletters(index: number): void {

        this.indexTab = index;
        let userId = null;
        if(this.onlyMe) {
            userId = JSON.parse(localStorage.getItem('user')).id;
        }
        this.newsletterService.setIndexTab(index);

    }

    getNetworkById(id: number): string {
        let network = this.networkList.find(network => network.id === id);
        if(network)
            return network.nameEn
        else
            return '';
    }

    getCountryById(id: string): string {
        let country = this.countryList.find(country => country.value === id);
        if(country)
            return country.name
        else
            return '';
    }

    getUserById(id: number): string {
        let user = this.usersList.find(user => user.id === id);
        if(user)
            return user.username
        else
            return '';
    }


    sendNewsletter(): void {
        let body;
        this.loadingSend = true;
        this.newsletterService.getNewsletterMessage$().subscribe((data:any) => {
            body = data;
        });

        let request = new NewsletterRequest(
            JSON.parse(localStorage.getItem('user')).id,
            this.subjectCtrl.value,
            body,
            this.templateCtrl.value,
            this.langCrtl.value,
            this.networkCrtl.value,
            this.countryCrtl.value,
            this.usersCrtl.value
        );

        this.newsletterService.sendNewsletter$(request).subscribe((data:any) => {
            this.loadingSend = false;
            this.openSuccessSnackBar(data.message, 'Close');
            this.resetForm();
        },
        error => {
            this.openErrorSnackBar(error.error.message, 'Close');
        });
    }

    saveDraft(): void {
        let body;
        this.loadingDraft = true;
        this.newsletterService.getNewsletterMessage$().subscribe((data:any) => {
            body = data;
        });

        let request = new NewsletterRequest(
            JSON.parse(localStorage.getItem('user')).id,
            this.subjectCtrl.value,
            body,
            this.templateCtrl.value,
            this.langCrtl.value,
            this.networkCrtl.value,
            this.countryCrtl.value,
            this.usersCrtl.value
        );


        this.newsletterService.draftNewsletter$(request).subscribe((data: NewsletterResponse) => {
            this.loadingDraft = false;
            this.openSuccessSnackBar(data.message, 'Close');
            this.resetForm();
            },
            error => {
                this.openErrorSnackBar(error.error.message, 'Close');
            }
        );
    }

    openErrorSnackBar(message: string, action: string) {
        let config = new MatSnackBarConfig();
        config.verticalPosition = "top";
        config.panelClass = ['error-snackbar'];
        config.duration = 8000;
        this.matSnackBar.open(message, action, config);
      }

      openSuccessSnackBar(message: string, action: string) {
        let config = new MatSnackBarConfig();
        config.verticalPosition = "top";
        config.panelClass = ['success-snackbar'];
        config.duration = 8000;
        this.matSnackBar.open(message, action, config);
      }


    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }



}
