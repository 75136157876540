import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { RoleType } from '../model/role-type';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private http: HttpClient,
    private gralService: GeneralService
  ) { }


  getRoles$(): Observable<RoleType[]> {
    return this.http
        .get<RoleType[]>(environment.apiDomainUrlGetRoles + '/list')
        .pipe(
            catchError(this.gralService.handleError));
  }


}
