import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AncineGridsFile } from 'src/app/shared/model/ancine-grids-model/ancine-grids-file';
import { AncineGirdsService } from 'src/app/shared/service/ancine-grids.service';
import { saveAs } from 'file-saver';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
@Component({
  selector: 'app-ancine-utils-dialogs-component',
  templateUrl: './ancine-utils-dialogs-component.component.html',
  styleUrls: ['./ancine-utils-dialogs-component.component.scss']
})
export class AncineUtilsDialogsComponentComponent implements OnInit {

  filesPublic: AncineGridsFile[] = [];
  // filesList: string[] = ["PUB_31433592000169_WARNERCHANNELHD_4040330030_202301_20230207.csv",
  //   "PUB_31433592000169_TRUTVSD_4040330009_202001_20200214.csv",
  //   "33015827000128_WARNERCHANNELHD_2239630002_202001_20200215.csv"];

  fileName: string = "TurnerTapkit-EPG-Ancine.zip";
  filesLists: string[] = [];
  descargando: boolean = false;
  deshabilitar: boolean = false;
  showErrorMessage: boolean = false;
  constructor(private _service: AncineGirdsService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AncineUtilsDialogsComponentComponent>) {
    this.filesPublic = data;

  }

  ngOnInit(): void {
  }

  download(): void {
    this.filesLists = this.filesPublic.map(file => file.name);
    this.descargando = true;
    this._service.getDownloadedFiles(this.filesLists)
      .pipe(
        catchError(error => {
          console.error('Error al descargar los archivos:', error);
          this.descargando = false;
          this.showErrorMessage = true;
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 3000);
          return of(null);
        })
      ).subscribe((arrayBuffer) => {
        if (arrayBuffer) {
          this.arrayBufferToFile(arrayBuffer);
        }
        this.descargando = false;
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  arrayBufferToFile(arrayBuffer: ArrayBuffer) {
    const blob = new Blob([arrayBuffer]);
    saveAs(blob, this.fileName);
  }

  removeFileFromList(file: any) {
    console.log("item", file);
    const index = this.filesPublic.findIndex(item => item === file);
    console.log("index", index);
    if (index !== -1) {
      this.filesPublic.splice(index, 1);
      console.log("filesPublic", this.filesPublic);
    }
    if (this.filesPublic.length == 0) {
      this.deshabilitar = true;
    }
  }

  deselectFiles() {
    for (const file of this.filesPublic) {
      file.selected = false;
    }
    this.filesPublic.splice(0, this.filesPublic.length);
    this.filesLists = [];
    this.deshabilitar = true;
  }
}
