import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigurationsRequest } from 'src/app/shared/request/configurations.request';

@Component({
    selector: 'app-dialog-config',
    templateUrl: './dialog-config.component.html',
    styleUrls: ['./dialog-config.component.css']
})
export class DialogConfigComponent {

    constructor(
            public dialogRef: MatDialogRef<DialogConfigComponent>,
            @Inject(MAT_DIALOG_DATA) public data: ConfigurationsRequest,
            iconRegistry: MatIconRegistry,
            sanitizer: DomSanitizer) {

        iconRegistry.addSvgIcon(
            'close',
            sanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/icons/twotone-close-24px.svg')
        );
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onOkClick(): void {
        this.dialogRef.close(this.data);
    }

}
