import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { CountrySelect } from 'src/app/shared/model/countrySelect';
import { CountryService } from 'src/app/shared/service/country.service';
import { UtilService } from 'src/app/shared/service/util.service';
import { CountryDialogComponent } from './country-dialog/country-dialog.component';

@Component({
  selector: 'app-country-options',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit {


  countries: CountrySelect[] = [];
  displayedColumns: string[] = ['code', 'name', 'actions'];
  dataSource = new MatTableDataSource<CountrySelect>();
  countryForm: FormGroup;
  isEditing: boolean = false;
  selectedCountryId?: number;

  constructor(private countryService: CountryService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private utilService: UtilService
) {

  }

  ngOnInit(): void {
    this.loadCountries();
  }


  loadCountries() {
    this.countryService.getCountries().subscribe(
      (data) => {
        this.countries = data;
        this.dataSource.data = this.countries;  // Asignar datos a la tabla
      },
      (error) => {
        console.error('Error al cargar los paises:', error);
      }
    );
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(CountryDialogComponent, {
      width: '400px',
      data: { country: null, isEditing: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.countryService.addCountry(result).subscribe(() => this.loadCountries());
      }
    });
  }

  openEditDialog(country: CountrySelect) {
    const dialogRef = this.dialog.open(CountryDialogComponent, {
      width: '400px',
      data: { country, isEditing: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.countryService.updateCountry(country.id, result).subscribe(() => this.loadCountries());
      }
    });
  }

  async deleteCountry(id: number) {
    const textConfirm = await this.translateService.get('epg.users.dialog.messageDeleteConfirm').toPromise();
    const buttonConfirm = await this.translateService.get('epg.users.dialog.buttons.delete').toPromise();

    // Abrir el diálogo de confirmación
    const dialogRef = this.utilService.openConfirmDialog({
      title: buttonConfirm,
      text: textConfirm,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Si el usuario confirma, proceder a eliminar el idioma
        this.countryService.deleteCountry(id).subscribe(() => {
          this.loadCountries();
        });
      }
    });
  }
  resetForm() {
    this.countryForm.reset();
    this.isEditing = false;
    this.selectedCountryId = undefined;
  }
}
