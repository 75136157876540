import { Component, Output, EventEmitter, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-feed-search',
    templateUrl: './feed-search.component.html',
    styleUrls: ['./feed-search.component.css']
})
export class FeedSearchComponent {

    @Output()
    public keyupSearch = new EventEmitter<string>();

    @Output()
    public selectOrDeselectAll = new EventEmitter();

    @Input()
    labelButton: string;

    constructor(
            iconRegistry: MatIconRegistry,
            sanitizer: DomSanitizer) {

        iconRegistry.addSvgIcon(
            'search',
            sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/twotone-search-24px.svg')
        );
    }

}
