import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FileProcess } from '../../../shared/model/visual-logs-model/files';
import { VisualLogsService } from '../../../shared/service/visual-logs.service';

@Component({
  selector: 'app-utils-dialogs',
  templateUrl: './visual-logs-dialogs.component.html',
  styleUrls: ['./visual-logs-dialogs.component.scss']
})
export class UtilsDialogsComponent implements OnInit {
  @ViewChild(MatPaginator) tablePaginator: MatPaginator;

  itemLogs: FileProcess[] = [];
  dataSourceLogs = new MatTableDataSource([]);
  displayedColumns: String[]=["step","detail","start_time"]
  idFile: number;
  file_name: String;
  mostrarBarra: boolean = false;
  mostrarMensaje: boolean = false;

  constructor(private _service: VisualLogsService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UtilsDialogsComponent>) {

    this.idFile = data.id;
    this.file_name=data.file_name;
    console.log(this.idFile)

  }

  ngOnInit(): void {
    
    this.mostrarBarra = true;
    this.mostrarMensaje = false;

    this._service.traerLogs(this.idFile).subscribe(result => {
      console.log(result);
      this.itemLogs = result;
      this.dataSourceLogs = new MatTableDataSource(result);
      this.dataSourceLogs.paginator = this.tablePaginator;
      this.mostrarBarra = false;
      this.mostrarMensaje = true;
    }
    );

  }

  close(): void {
    this.dialogRef.close();
}

}
