import { SelectionModel } from '@angular/cdk/collections';
import { Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataNetwork } from 'src/app/shared/model/data-network';
import { Feed } from 'src/app/shared/model/feed';
import { EpgDataService } from 'src/app/shared/service/epg-data.service';
import { FeedService } from 'src/app/shared/service/feed.service';
import { Module } from 'src/app/shared/model/module';

@Component({
    selector: 'app-legal',
    templateUrl: './legal.component.html',
    styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit, DoCheck, OnDestroy {

    feeds: Feed[];
    feedsFiltered: Feed[];
    dataSource = new MatTableDataSource<Feed>();
    selection: SelectionModel<Feed>;
    filterAllField: any;
    loadingDatasource = false;
    labelButton: string;
    selectAll: string;
    deselectAll: string;
    reportType: Module;
    dateFrom: Date;
    dateTo: Date;

    private unsubscription$: Subject<void>;

    constructor(
            private epgDataService: EpgDataService,
            private translateService: TranslateService,
            private feedService: FeedService) {

        this.unsubscription$ = new Subject<void>();
        this.translateButtton();
    }

    ngOnInit() {
        this.loadingDatasource = true;
        this.feedService
            .getFeeds$()
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(feeds => this.mapperFeeds(feeds))
            .add(() => this.loadingDatasource = false);
    }

    private mapperFeeds(feeds: Feed[]): void {
        this.feeds = feeds;
        this.feedsFiltered = this.feeds;
        this.dataSource.data = this.feeds;
        this.filterAllField = this.dataSource.filterPredicate;
        this.selection = new SelectionModel<Feed>(true, this.dataSource.data.filter(row => row.selected));
        this.epgDataService
            .codeNetwork$
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(dataNetwork => this.filterNetwork(dataNetwork));
    }

    private filterNetwork(dataNetwork: DataNetwork): void {
        this.dataSource.data = this.feeds;

        if (dataNetwork.isClicked) {
            this.feedsFiltered = this.feeds;
            this.dataSource.filter = null;
        } else {
            this.filterNetworkSetup();
            this.dataSource.filter = dataNetwork.codeNetwork.trim().toLowerCase();
            this.feedsFiltered = this.dataSource.filteredData;
            this.dataSource.data = this.feedsFiltered;
        }
        this.selection = new SelectionModel<Feed>(true, this.dataSource.data.filter(row => row.selected));
        this.labelButton = this.isAllSelected() ? this.deselectAll : this.selectAll;
    }

    ngDoCheck() {
        this.labelButton = this.isAllSelected() ? this.deselectAll : this.selectAll;
    }

    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }

    private translateButtton(): void {
        this.translateService
            .stream(['epg.feed.selectAll', 'epg.feed.deselectAll'])
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(translations => {
                this.selectAll = translations['epg.feed.selectAll'];
                this.deselectAll = translations['epg.feed.deselectAll'];
            });
    }

    keyupSearch(charSearch: string) {
        this.dataSource.data = this.feedsFiltered;
        this.dataSource.filterPredicate = this.filterAllField;
        this.dataSource.filter = charSearch.trim().toLowerCase();
    }

    /**
     * Setea el filtro por defecto, para que solo filtre por el campo 'feed.network'
     */
    private filterNetworkSetup() {
        this.dataSource.filterPredicate = (feed: Feed, networkCode: string) => {
            return feed.network.code === networkCode.trim().toUpperCase();
        };
    }

    /** Si el numero de elementos seleccionados coincide con el numero total de filas. */
    private isAllSelected() {
        let allSelected = false;
        if (this.selection && this.dataSource) {
            allSelected = this.selection.selected.length === this.dataSource.data.length;
        }
        return allSelected;
    }

    /** Selecciona todas las filas si no estan todas seleccionadas, de lo contrario, limpia la seleccion */
    selectOrDeselectAll() {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.labelButton = this.selectAll;
            this.dataSource
                .data
                .forEach(row => row.selected = false);
        } else {
            this.labelButton = this.deselectAll;
            this.dataSource
                .data
                .forEach(row => {
                    this.selection.select(row);
                    row.selected = true;
                });
        }
    }

    reportTypeEmit(reportType: Module): void {
        this.reportType = reportType;
    }

    dateFromEmit(dateFrom: Date): void {
        this.dateFrom = dateFrom;
    }

    dateToEmit(dateTo: Date): void {
        this.dateTo = dateTo;
    }

}
