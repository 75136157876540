import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilsDialogsComponent } from 'src/app/epg/visual-logs/utils-dialogs/visual-logs-dialogs.component';
import { ConfirmDialogComponent } from 'src/app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { Language } from 'src/app/shared/model/language';
import { Network } from 'src/app/shared/model/network';
import { NetworkEpg } from 'src/app/shared/model/network-epg-model/network-epg';
import { EpgNews } from 'src/app/shared/model/news-model/news';
import { EpgNewsUpload } from 'src/app/shared/model/news-model/news-upload';
import { NewsService } from 'src/app/shared/service/epg-news.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news-dialogs',
  templateUrl: './news-dialogs.component.html',
  styleUrls: ['./news-dialogs.component.scss']
})
export class NewsDialogsComponent implements OnInit {

  // variables.
  private unsubscription$: Subject<void>;
  epgNewsUpload: EpgNewsUpload;
  logoUrl: string;
  defaultLogoUrl: string = environment.bucketUrlImages + "WBD_logo_news.png";
  maxWidth:number = 180;
  maxHeight:number = 240;

  // variables del html.
  selectorForm: FormGroup;
  imageMessage: string = "";
  networkImage: string = "";
  datePickerStartDate: string = moment().format('YYYY-MM-DD');
  datePickerChanged: string = "";
  savingInProgress: boolean = false;
  updateButtonInProgress: boolean = true;
  updateInProgress: boolean = false;

  // variables para mensajes del snackbar.
  errorMessage: string;
  errorDBMessage: string;
  publicationFilesInProcessMessage: string;
  successfulFilesPublicationMessage: string;
  deletingRegister: string;
  registerDelete: string;
  failedToDeleteRegister: string;

  // variables que traigo del news.component
  languages: Language[] = [];
  listNetworks: NetworkEpg[] = [];
  currentNetwork: Network;
  networkNews: EpgNews;
  videoExtensions: string[] = [];
  imageExtensions: string[] = [];
  editarBoolean: boolean = false;
  isEdit: boolean;

  // datos del formGroup.
  title = new FormControl('', Validators.required);
  subtitle = new FormControl('', Validators.required);
  description = new FormControl('', Validators.required);
  network = new FormControl('', Validators.required);
  language = new FormControl('', Validators.required);
  date = new FormControl(moment(), Validators.required);
  expdate = new FormControl('', Validators.required);
  video = new FormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private networkNewsService: NewsService,
    public dialogRef: MatDialogRef<UtilsDialogsComponent>,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    public dialog: MatDialog
  ) {
    this.unsubscription$ = new Subject<void>();
    this.epgNewsUpload = new EpgNewsUpload();

    this.logoUrl = environment.bucketUrlImagesNewsLogo;
    this.translations();

    //cargo los datos de la pantalla de news.
    this.languages = data.languages;
    this.listNetworks = data.listNetworks;
    this.currentNetwork = data.currentNetwork;
    this.networkNews = data.news;
    this.imageExtensions = data.imageExtensions;
    this.isEdit = data.isEdit;
    this.editarBoolean = data.editarBoolean;

    //cargo los datos en el formGroup
    this.selectorForm = new FormGroup({
      title: this.title,
      subtitle: this.subtitle,
      description: this.description,
      network: this.network,
      language: this.language,
      date: this.date,
      expdate: this.expdate,
      video: this.video,
    }, { validators: this.dateRangeValidator() });

  }

  ngOnInit(): void {
    if (this.editarBoolean) {
      this.updateInProgress = true;
    }
    // En caso de que sea Nuveo registro.
    this.networkImage = this.defaultLogoUrl;

    // En caso de que sea Edit, cargo el formulario.
    if (this.networkNews) {
      this.networkImage = this.logoUrl + this.networkNews.image
      this.epgNewsUpload.imageName = this.networkNews.image
      this.selectorForm.setValue({
        title: this.networkNews.title,
        subtitle: this.networkNews.subtitle,
        description: this.networkNews.text,
        network: this.listNetworks.find(network => network.id == this.networkNews.networkId),
        language: this.languages.find(lang => lang.code == this.networkNews.language),
        date: this.networkNews.date,
        expdate: this.networkNews.expirationDate,
        video: this.networkNews.video,
      });
      
    }
    this.imageMessage = `Tamaño:(${this.maxWidth}px x ${this.maxHeight}px)`;
  }

   // Validar el rango de fechas
   dateRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate = control.get('date')?.value;
      const endDate = control.get('expdate')?.value;
      
      if (startDate && endDate) {
        const start = moment(startDate);
        const end = moment(endDate);

        if (end.isBefore(start)) {
          return { dateRange: 'Expiration date cannot be earlier than start date' };
        }
      }
      return null;
    };
  }

  // Para editar el formulario.
  edit() {
    this.updateButtonInProgress = false;
    this.updateInProgress = false;
  }

  // Para guardar el rgistro Nuevo.
  save() {
    if (this.selectorForm.valid) {
      this.showSnackbar(this.publicationFilesInProcessMessage, "Ok");
      this.savingInProgress = true;

      // Realiza la subida de archivos al bucket S3
      this.uploadToS3(null,0);
    }
  }

  // Para editar el registro Existente.
  update(): void {
    this.updateButtonInProgress = true;
    this.updateInProgress = true;

    // subo archivos
    if (this.selectorForm.valid) {
      this.showSnackbar(this.publicationFilesInProcessMessage, "Ok");

      if (this.epgNewsUpload) {
        // Realiza la subida de archivos al bucket S3
        this.uploadToS3(this.networkNews.id,this.networkNews.version + 1);
      } else {
        this.registerInDB(this.networkNews.id,this.networkNews.version + 1);
      }
    }
  }

  openConfirmDialog(eventType:string): void {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(eventType === "delete"){
          this.deleteNews();
        } else if(eventType === "update"){
          this.update();
        }
      }
    });
  }

  // Para borrar un registro.
  deleteNews(){
    this.showSnackbar(this.deletingRegister, "Ok");
    this.networkNewsService.deleteNews(this.networkNews.id).subscribe({
      next: (res) => {
        this.showSnackbar(this.registerDelete, "Ok");
        this.dialogRef.close(true);
      },
      error: (e) => {
        this.showErrorSnackbar(this.failedToDeleteRegister, "Ok");
        this.dialogRef.close();
      }
    })
    // Revisar lo que dijo Ari sobre Rxjs Switch map
    if(this.networkNews.image){
      this.networkNewsService.deleteImageFromS3(this.networkNews.image).subscribe()
    }
  }


  // Para subir los archivos al bucket S3. EPG
  uploadToS3(id, version){
    this.networkNewsService.uploadNewsFilesToS3(this.epgNewsUpload).subscribe({
      next: (res) => {
        this.registerInDB(id, version);
      },
      error: (e) => {
        this.showErrorSnackbar(this.errorMessage, "Ok");
        this.savingInProgress = false;
      }
    });
  }

  // Para registrar los datos en la base de datos. DOMAIN
  registerInDB(id,version) {
    const formValues = this.selectorForm.value;
    const selectedNetwork = this.listNetworks.find(network => network.code === formValues.network.code);
    const uploadData: EpgNews = {
      id: id,
      version: version,
      date: formValues.date,
      expirationDate: formValues.expdate,
      image: this.epgNewsUpload.imageName,
      language: formValues.language.code,
      networkId: selectedNetwork.id,
      subtitle: formValues.subtitle,
      text: formValues.description,
      title: formValues.title,
      video: formValues.video,
    };

    // Realiza la subida de datos a la base de datos
    this.networkNewsService.uploadNewsDB(uploadData).subscribe({ // IMPORTANTE rxjs switch map o algo similar.
      next: (res) => {
        this.showSnackbar(this.successfulFilesPublicationMessage, "Ok");
        this.savingInProgress = false;

        if(this.isEdit){
          this.dialogRef.close(true);
        }else{
          this.imageMessage = "";
          this.networkImage = "";
          this.selectorForm.reset();
          this.selectorForm.get('date').setValue(moment());
        }
      },
      error: (e) => {
        this.showErrorSnackbar(this.errorDBMessage, "Ok");
        this.savingInProgress = false;
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  // Para actualizar el datepicker.
  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    if (event.value) {
      this.datePickerChanged = moment(event.value).format('YYYY-MM-DD');
    } else {
    }
  }

  // Para capturar la imagen y validar la extension.
  onImageSelected(event: any) {
    const selectedFile = event.target.files[0];
    const fileName = selectedFile.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    const reader = new FileReader();

    this.epgNewsUpload.imageFile = selectedFile;

    const selectedDate = this.selectorForm.get('date').value;
    const formattedDate = moment(selectedDate).format('ddd MMM DD HH:mm:ss YYYY');
    const newFileName = `${formattedDate}-${fileName}`;

    this.epgNewsUpload.imageName = newFileName;

    if (!this.imageExtensions.includes(fileExtension)) {
      this.imageMessage = `El archivo ${fileName} no tiene una extensión válida.`;
    }
    else if (selectedFile.size === 0) {
      this.imageMessage = `El archivo ${fileName} se encuentra vacío.`;
    }

    reader.readAsDataURL(selectedFile);
    reader.onload = (e:any) => {
      
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        if(height != 240 && width != 180) {
          this.imageMessage = `El archivo ${fileName} no tiene el tamaño adecuado \ (${this.maxWidth}px x ${this.maxHeight}px).`;
        }else{
          this.imageMessage = fileName;
          this.networkImage = e.target.result;
        }
      };
    };
  }

  // Snackbars
  showSnackbar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.duration = 3000;
    config.verticalPosition = "top";
    config.panelClass = ['SuccessfulSnackbar'];
    this.snackBar.open(message, action, config);
  }

  showErrorSnackbar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.panelClass = ['error-snackbar'];
    this.snackBar.open(message, action, config);
  }

  private translations(): void {
    const keys = [
      'epg.news.messages.error',
      'epg.news.messages.dberror',
      'epg.news.messages.publication',
      'epg.news.messages.success',
      'epg.news.messages.deletingRegister',
      'epg.news.messages.registerDelete',
      'epg.news.messages.failedToDeleteRegister',
    ];
    this.translateService
      .stream(keys)
      .pipe(takeUntil(this.unsubscription$))
      .subscribe(translations => this.mapperToMessages(translations));
  }

  private mapperToMessages(translations: any): void {
    this.errorMessage = translations['epg.news.messages.error'];
    this.errorDBMessage = translations['epg.news.messages.dberror'];
    this.publicationFilesInProcessMessage = translations['epg.news.messages.publication'];
    this.successfulFilesPublicationMessage = translations['epg.news.messages.success'];
    this.deletingRegister = translations['epg.news.messages.deletingRegister'];
    this.registerDelete = translations['epg.news.messages.registerDelete'];
    this.failedToDeleteRegister = translations['epg.news.messages.failedToDeleteRegister'];
  }

}
