import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from './login/login.component';
import { DialogTimeoutComponent } from './dialog-timeout/dialog-timeout.component';
import { RedirectAuthenticationComponent } from './redirect-authentication/redirect-authentication.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { LoginRoutingModule } from './login-routing.module';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';

@NgModule({
    declarations: [
        LoginComponent,
        DialogTimeoutComponent,
        RedirectAuthenticationComponent,
        AccessDeniedComponent,
        PasswordRecoveryComponent
    ],
    entryComponents: [
        DialogTimeoutComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        TranslateModule,
        ReactiveFormsModule,
        RouterModule,
        LoginRoutingModule
    ]
})
export class LoginModule { }
