import { SelectionModel } from '@angular/cdk/collections';
import { Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataNetwork } from 'src/app/shared/model/data-network';
import { Feed } from 'src/app/shared/model/feed';
import { EpgDataService } from 'src/app/shared/service/epg-data.service';
import { FeedService } from 'src/app/shared/service/feed.service';
import { UserService } from 'src/app/shared/service/user.service';
import { ShowService } from '../../shared/service/show.service';

@Component({
    selector: 'app-epg-subscription',
    templateUrl: './epg-subscription.component.html',
    styleUrls: ['./epg-subscription.component.css']
})
export class EpgSubscriptionComponent implements OnInit, DoCheck, OnDestroy {

    feeds: Feed[];
    feedsFiltered: Feed[];
    dataSource = new MatTableDataSource<Feed>();
    selection: SelectionModel<Feed>;
    filterAllField: any;
    loadingDatasource = false;
    labelButton: string;
    selectAll: string;
    deselectAll: string;

    private unsubscription$: Subject<void>;

    constructor(
            private epgDataService: EpgDataService,
            private translateService: TranslateService,
            private userService: UserService,
            private showService: ShowService,
            private feedService: FeedService) {

        this.unsubscription$ = new Subject<void>();
        this.translateButtton();
    }

    ngOnInit() {
        this.loadingDatasource = true;
        this.feedService
            .getFeeds$()
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(feeds => this.mapperFeeds(feeds))
            .add(() => this.loadingDatasource = false);
    }

    private mapperFeeds(feeds: Feed[]): void {
        this.feeds = feeds;
        this.feedsFiltered = this.feeds;
        this.dataSource.data = this.feeds;
        this.filterAllField = this.dataSource.filterPredicate;
        this.selection = new SelectionModel<Feed>(true, this.dataSource.data.filter(row => row.selected));
        this.updateTableVersion();
        this.userService
            .getUser$()
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(user => this.updateTableSelected(user.feeds));
    }

    private updateTableSelected(feeds: Feed[]): void {
        this.dataSource.data.forEach(row => {
            feeds.forEach(feed => {
                if (feed.alias === row.alias) {
                    this.selection.select(row);
                    row.selected = true;
                }
            });
        });

        this.epgDataService
            .codeNetwork$
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(dataNetwork => this.filterNetwork(dataNetwork));
    }

    private filterNetwork(dataNetwork: DataNetwork): void {
        this.dataSource.data = this.feeds;

        if (dataNetwork.isClicked) {          // Si la NETWORK esta seleccionada
            this.feedsFiltered = this.feeds;
            this.dataSource.filter = null;
        } else {
            this.filterNetworkSetup();
            this.dataSource.filter = dataNetwork.codeNetwork.trim().toLowerCase();
            this.feedsFiltered = this.dataSource.filteredData;
            this.dataSource.data = this.feedsFiltered;
        }
        this.selection = new SelectionModel<Feed>(true, this.dataSource.data.filter(row => row.selected));
        this.labelButton = this.isAllSelected() ? this.deselectAll : this.selectAll;
    }

    ngDoCheck() {
        this.labelButton = this.isAllSelected() ? this.deselectAll : this.selectAll;
    }

    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }

    private updateTableVersion(): void {
        this.dataSource.data.forEach(row => {
            this.showService
                .getLogVersion$(row.code)
                .pipe(takeUntil(this.unsubscription$))
                .subscribe(logVersion => row.version = (logVersion != null) ? logVersion.version : 0);
        });
    }

    private translateButtton(): void {
        this.translateService
            .stream(['epg.feed.selectAll', 'epg.feed.deselectAll'])
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(translations => {
                this.selectAll = translations['epg.feed.selectAll'];
                this.deselectAll = translations['epg.feed.deselectAll'];
            });
    }

    keyupSearch(charSearch: string) {
        this.dataSource.data = this.feedsFiltered;
        this.dataSource.filterPredicate = this.filterAllField;
        this.dataSource.filter = charSearch.trim().toLowerCase();
    }

    /**
     * Setea el filtro por defecto, para que solo filtre por el campo 'feed.network'
     */
    private filterNetworkSetup() {
        this.dataSource.filterPredicate = (feed: Feed, networkCode: string) => {
            return feed.network.code === networkCode.trim().toUpperCase();
        };
    }

    /** Si el numero de elementos seleccionados coincide con el numero total de filas. */
    private isAllSelected() {
        let allSelected = false;
        if (this.selection && this.dataSource) {
            allSelected = this.selection.selected.length === this.dataSource.data.length;
        }
        return allSelected;
    }

    /** Selecciona todas las filas si no estan todas seleccionadas, de lo contrario, limpia la seleccion */
    selectOrDeselectAll() {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.labelButton = this.selectAll;
            this.dataSource
                .data
                .forEach(row => row.selected = false);
        } else {
            this.labelButton = this.deselectAll;
            this.dataSource
                .data
                .forEach(row => {
                    this.selection.select(row);
                    row.selected = true;
                });
        }
    }

}
