import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AncineGridsFile } from "../model/ancine-grids-model/ancine-grids-file";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { AncineGridsList } from "../model/ancine-grids-model/ancine-grids-list";
import { Url } from "url";


@Injectable({
    providedIn: 'root'
})
export class AncineGirdsService {

    constructor(private http: HttpClient) {
        //httpClient
        //activar o manejar el tema de CORS en spring 
    }

    getFileByNetwork(_code: string, _type: string) {
        var params = new HttpParams();

        if (_code)
            params = params.set('code', _code);
        if (_type)
            params = params.set('type', _type);

        return this.http.get<AncineGridsList[]>(environment.apiAncineGridsFileGetFileDate, { params, });
        //return this.http.get<AncineGridsFile[]>('http://localhost:8091/ancine/getfile/date');
    }

    getDownloadedFiles(filesList: string[]) {
        const params = new HttpParams().set('archivos', filesList.join(','));
        const responseType: 'arraybuffer' = 'arraybuffer';
        return this.http.get(environment.apiAncineGridsFileGetDownloadedFiles, { params, responseType });
    }

    getDownloadedFile(_archivo: string) {
        var params = new HttpParams();
        params = params.set('archivo', _archivo);
        //const responseType: 'arraybuffer' = 'arraybuffer';

        console.log("URL ancine = ", params)
        return this.http.get(environment.apiAncineGridsFileGetDownloadedFile, { params, responseType: 'arraybuffer', observe: 'response' });
    }

}

