import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-feeds-utils-dialog',
  templateUrl: './feeds-utils-dialog.component.html',
  styleUrls: ['./feeds-utils-dialog.component.scss']
})
export class FeedsUtilsDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FeedsUtilsDialogComponent>
    ) { }

  ngOnInit(): void {
    this.data.feedImage = environment.bucketUrlFeeds + this.data.feedImage;
  }

  close(): void {
    this.dialogRef.close();
  }
}
