import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralService } from './general.service';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Newsletter } from '../model/newsletters-model/newsletter';
import { NewsletterRequest } from '../request/newsletter.request';
import { NewsletterResponse } from '../response/newsletter.response';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(
    private http: HttpClient,
    private gralService: GeneralService
  ) { }

    private newslettersSubject = new BehaviorSubject<NewsletterResponse>(new NewsletterResponse());
    private onlyMeSubject = new BehaviorSubject<boolean>(false);
    private newsletterMessage = new BehaviorSubject<string>('<p>hola</p>');
    private indexTabSubject = new BehaviorSubject<number>(1);

    getNewsletterList$(): Observable<NewsletterResponse> {
        return this.newslettersSubject.asObservable();
    }

    setNewsletterList(newsletters: NewsletterResponse): void {
        this.newslettersSubject.next(newsletters);
    }
    getOnlyMe$(): Observable<boolean> {
        return this.onlyMeSubject.asObservable();
    }

    setOnlyMe(onlyMe: boolean): void {
        this.onlyMeSubject.next(onlyMe);
    }

    getIndexTab$(): Observable<number> {
        return this.indexTabSubject.asObservable();
    }

    setIndexTab(index: number): void {
        this.indexTabSubject.next(index);
    }

    getNewsletterMessage$(): Observable<string> {
        return this.newsletterMessage.asObservable();
    }

    setNewsletterMessage(message: string): void {
        this.newsletterMessage.next(message);
    }


    getNewsletters$(page?:number,size?:number,state?:string,userId?:number, keyword?:string):Observable<NewsletterResponse> {
        // let url = environment.apiDomainUrlNewsletters + '/list';
        // if(state){
        //     url = environment.apiDomainUrlNewsletters + '/list?state=' + state;
        // }
        // if(state && userId){
        //     url = environment.apiDomainUrlNewsletters + '/list?state=' + state + '&userId=' + userId;
        // }
        // return this.http.get(url)
        let url = environment.apiDomainUrlNewsletters + '/list';
        const queryParams = [];

        if(page){
            queryParams.push('page=' + page);
        }
        if(size){
            queryParams.push('size=' + size);
        }
        if(state){
            queryParams.push('state=' + state);
        }
        if(userId){
            queryParams.push('userId=' + userId);
        }
        if(keyword){
            queryParams.push('keyword=' + keyword);
        }
        if(queryParams.length > 0){
            url += '?' + queryParams.join('&');
        }

        return this.http.get<NewsletterResponse>(url)
            .pipe(
                catchError(this.gralService.handleError)
            );
    }

    draftNewsletter$(newsletter: NewsletterRequest): Observable<any> {
        return this.http.post(environment.apiDomainUrlNewsletters + '/draftNewsletter', newsletter)
        .pipe(
            catchError(this.gralService.handleError)
        );
    }

    sendNewsletter$(newsletter: NewsletterRequest): Observable<any> {
        return this.http.post(environment.apiDomainUrlNewsletters + '/sendNewsletter', newsletter)
        .pipe(
            catchError(this.gralService.handleError)
        );
    }



}
