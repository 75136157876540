import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserProfile } from 'src/app/shared/model/user-profile';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserProfileService } from 'src/app/shared/service/user-profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    username = '';
    userId = 0;
    user: UserProfile;
    activedSection = 1;


    constructor(
        private authenticationService: AuthenticationService,
        private userProfileService: UserProfileService
    ) {
        this.username = this.authenticationService.currentUserValue.username;
        this.userId = this.authenticationService.currentUserValue.id;

    }

    ngOnInit(): void {
        this.userProfileService.getUserProfileById$(this.userId).pipe(
            map(data => data.
                userProfiles[0])
        ).subscribe(user => {
            this.user = user;
        });
    }

    changeSection(section: number): void {
        this.activedSection = section;
    }

    isActiveSection(id:number): boolean{
        if(id == this.activedSection)
            return true
        else
            return false;
    }

}
