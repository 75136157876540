
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-ancine',
  templateUrl: './ancine.component.html',
  styleUrls: ['./ancine.component.scss']
})

export class AncineComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
  
    }
  
  }