import { Component, NgModule, OnInit } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfigurationRegisterComponent } from './configuration-register/configuration-register.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-configurations',
  templateUrl: './admin-configurations.component.html',
  styleUrls: ['./admin-configurations.component.css']
})
export class AdminConfigurationsComponent implements OnInit {

    constructor(
        private translateService: TranslateService,
    ) {
    }

    ngOnInit(): void {
    }

}




