import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../shared/service/authentication.service';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-redirect-authentication',
    template: ``
})
export class RedirectAuthenticationComponent implements OnInit, OnDestroy {

    private path: string;
    private locale: string;
    private refreshToken: string;
    private authSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this.setFields();
        this.loginWithToken();
    }

    ngOnDestroy(): void {
        this.authSubscription.unsubscribe();
    }

    private loginWithToken(): void {
        this.authSubscription = this.authenticationService
            .loginRefreshToken$(this.refreshToken)
            .pipe(first())
            .subscribe(
                () => {
                    sessionStorage.setItem('locale', this.locale);
                    this.translate.use(this.locale);
                    this.router.navigate([this.path]);
                },
                error => {
                    console.error(error);
                    this.router.navigate(['']);
                });
    }

    /** Inicializa los campos 'path' 'locale' y 'refreshToken' */
    private setFields(): void {
        const pathString = this.route
            .snapshot
            .url
            .join(',');
        const arrayPath = pathString.split(',');
        arrayPath.splice(arrayPath.length - 2, 2);

        this.path = arrayPath.join('/');
        this.locale = this.route
            .snapshot
            .paramMap
            .get('locale');
        this.refreshToken = this.route
            .snapshot
            .paramMap
            .get('refresh_token');
    }

}
