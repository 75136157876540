import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MonthlyService } from 'src/app/shared/service/monthly.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import * as moment from 'moment/moment';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Moment } from 'moment/moment';
import { Feed } from 'src/app/shared/model/feed';
import { FeedService } from 'src/app/shared/service/feed.service';
import { Monthly } from 'src/app/shared/model/monthly';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MonthlyUploadError } from 'src/app/shared/model/monthly-upload-model/MonthlyUploadError';
import { MonthlyUploadResponse } from 'src/app/shared/model/monthly-upload-model/MonthlyUploadResponse';
import { MatDialog } from '@angular/material/dialog';
import { MonthlyUtilsDialogsComponentComponentComponent } from './utils-dialog/monthly-utils-dialogs-component-component/monthly-utils-dialogs-component-component.component';
import { FileExtensionsConfigurationsService } from 'src/app/shared/service/file-extensions-configurations.service';
import { FileConfigurationsService } from 'src/app/shared/service/file-configurations.service';
import { FileConfigurations } from 'src/app/shared/model/file-configurations';
import { FileExtensionsConfigurations } from 'src/app/shared/model/file-extensions-configurations';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-monthly',
  templateUrl: './monthly.component.html',
  styleUrls: ['./monthly.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class MonthlyComponent implements OnInit {

  itemLista: Monthly[] = [];
  private feedId: string;
  private networkCode: string;
  feedSelected: boolean = false;
  filesSelected: boolean = false;
  monthly: Monthly;
  loadingConfirm: boolean = false;
  date = new FormControl(moment());
  dateFrom: string;
  dateTo: string;
  month: string;
  year: string;
  private readonly MIN_DATE_IN_DAYS = new Date().getDate() - 365;
  private readonly MAX_DATE_IN_DAYS = new Date().getDate() + 365;
  minDate = new Date();
  maxDate = new Date();
  processingDownloadMessage: string;
  processingPublishMessage: string;
  successfulDownloadMessage: string;
  successfulPublishMessage: string;
  noDataFeedErrorMessage: string;
  monthlyErrorMessage: string;
  monthlyErrorUploadMessage: string;
  monthlySuccessUploadMessage: string;
  monthlyFileConfiguration: FileConfigurations;
  monthlyFileExtensionsConfigurations: FileExtensionsConfigurations[];
  extensions: string[] = [];
  searchTerm: string = '';
  filteredItems: any[];

  actionGridProgress: number = 0;

  private unsubscription$: Subject<void>;

  //para subir archivos al bucket
  files: File[] = [];
  newfiles: File[] = [];
  archivosSeleccionados: String[] = [];
  errorMessage: string = '';
  subiendoArchivos: boolean = false;
  errorResponse: MonthlyUploadResponse[] = [];

  @Output()
  public dateFromEmit = new EventEmitter<Date>();

  @Output()
  public dateToEmit = new EventEmitter<Date>();

  constructor(private monthlyService: MonthlyService,
    private snackBar: MatSnackBar,
    private fileExtensionsConfigurationsService: FileExtensionsConfigurationsService,
    private fileConfigurationsService: FileConfigurationsService,
    private feedService: FeedService,
    private translateService: TranslateService,
    private dialog: MatDialog) {
    this.unsubscription$ = new Subject<void>();
    this.translations();
  }

  ngOnInit(): void {
    this.minDate.setDate(this.MIN_DATE_IN_DAYS);
    this.maxDate.setDate(this.MAX_DATE_IN_DAYS);

    this.monthlyService.getMonthly$().subscribe(result => {
      this.itemLista = result.sort((a, b) => a.feedtimezoneId.name.localeCompare(b.feedtimezoneId.name));
      this.filteredItems = result; // Inicialmente muestra todos los ítems
    })


    

    let inputDate: Moment = moment();
    inputDate.month(moment().month());
    inputDate.year(moment().year());

    inputDate.date(moment().date() - (moment().date() - 1));

    this.year = inputDate.year() + '';
    let monthReal = inputDate.month() + 1;
    this.month = ((monthReal < 10) ? '0' + monthReal.toString() : monthReal.toString()); //this.month = inputDate.month()+1+'';

    this.dateFrom = inputDate.clone().date(1).format('DD-MM-YYYY');
    this.dateTo = inputDate.add(1, 'M').format('DD-MM-YYYY');

    this.fileConfigurationsService.getAllFilesConfByFileType("GRIDS").subscribe(result => {
      this.monthlyFileConfiguration = result;
      this.getExtensions();
    });


  }

  private translations(): void {
    const keys = [
      'epg.monthly.processingDownloadMessage',
      'epg.monthly.processingPublishMessage',
      'epg.monthly.successfulDownload',
      'epg.monthly.successfulPublish',
      'epg.monthly.noDataFeedError',
      'epg.monthly.monthlyError',
      'epg.monthly.monthlyErrorUploadMessage',
      'epg.monthly.monthlySuccessUploadMessage'
    ];
    this.translateService
      .stream(keys)
      .pipe(takeUntil(this.unsubscription$))
      .subscribe(translations => this.mapperToMessages(translations));
  }

  private mapperToMessages(translations: any): void {
    this.processingDownloadMessage = translations['epg.monthly.processingDownloadMessage'];
    this.processingPublishMessage = translations['epg.monthly.processingPublishMessage'];
    this.successfulDownloadMessage = translations['epg.monthly.successfulDownload'];
    this.successfulPublishMessage = translations['epg.monthly.successfulPublish'];
    this.noDataFeedErrorMessage = translations['epg.monthly.noDataFeedError'];
    this.monthlyErrorMessage = translations['epg.monthly.monthlyError'];
    this.monthlyErrorUploadMessage = translations['epg.monthly.monthlyErrorUploadMessage'];
    this.monthlySuccessUploadMessage = translations['epg.monthly.monthlySuccessUploadMessage'];
  }

  // llamado desde el html
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    this.actionGridProgress = 0;

    let inputDate: Moment = moment();
    inputDate.month(normalizedMonthAndYear.month());
    inputDate.year(normalizedMonthAndYear.year());
    inputDate.date(normalizedMonthAndYear.date());
    //update ui date
    this.date.setValue(inputDate);
    datepicker.close();


    this.year = inputDate.year() + '';
    let monthReal = inputDate.month() + 1;
    this.month = ((monthReal < 10) ? '0' + monthReal.toString() : monthReal.toString()); //this.month = inputDate.month()+1+'';

    this.dateFrom = inputDate.clone().date(1).format('DD-MM-YYYY');

    this.dateTo = inputDate.add(1, 'M').format('DD-MM-YYYY');

  }

  onselectfeed(monthly: Monthly) {
    this.actionGridProgress = 0;
    this.monthly = monthly;
    this.feedSelected = true;
  }

  openSucessSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.duration = 3000;
    config.verticalPosition = "top";
    config.panelClass = ['SuccessfulSnackbar'];
    this.snackBar.open(message, action, config);
  }

  openErrorSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.panelClass = ['error-snackbar'];
    this.snackBar.open(message, action, config);
  }

  descargarGrillaMensual() {
    this.actionGridProgress = 0;
    this.feedId = this.monthly.feedtimezoneId.id;
    this.networkCode = this.monthly.feedtimezoneId.network.code;
    //let filename = this.feedId.split('_')[0] + "_" + this.year + "_" + this.month + "_grids_SPA_ARG.xls";
    let filename = this.networkCode + "_" + this.year + "_" + this.month + "_grids_" + this.monthly.language_id.code + "_" + this.monthly.country_id.code;
    console.log(this.monthly.feedtimezoneId.network.code);
    const date = new Date('01-' + this.month + "-" + this.year)
    let fileLanguage = this.monthly.language_id.code;

    //this.openSnackBar(this.processingDownloadMessage, "Ok"); //translations['epg.monthly.processingDownload'] //"Procesando descarga"
    this.actionGridProgress = 50;

    this.monthlyService.downloadMonthlyGrid(this.feedId, filename, this.dateFrom, this.dateTo, fileLanguage)
      .subscribe({
        next: (res) => {
          console.log(res);
          this.actionGridProgress = 100;
          this.manageExcelFile(res, filename);
          this.openSucessSnackBar(this.successfulDownloadMessage, "Ok");
        },
        error: (e) => { //async
          console.error("Monthlygrids API Error: ", e);
          if (e === 404)
            this.openErrorSnackBar(this.noDataFeedErrorMessage, "Ok");
          else
            this.openErrorSnackBar(this.monthlyErrorMessage, "Ok");
          this.actionGridProgress = 0;
        },
        complete: () => {
          console.info('completed request');
        }
      });

  }

  publicarGrillaMensual() {
    this.actionGridProgress = 0;
    this.feedId = this.monthly.feedtimezoneId.id;
    this.networkCode = this.monthly.feedtimezoneId.network.code;
    //let filename = this.feedId.split('_')[0] + "_" + this.year + "_" + this.month + "_grids_SPA_ARG.xls";
    let filename = this.networkCode + "_" + this.year + "_" + this.month + "_grids_" + this.monthly.language_id.code + "_" + this.monthly.country_id.code;
    const date = new Date('01-' + this.month + "-" + this.year)
    let fileLanguage = this.monthly.language_id.code;

    //this.openSnackBar(this.processingPublishMessage, "Ok");
    this.actionGridProgress = 50;

    this.monthlyService.publishMonthlyGrid(this.feedId, filename, this.dateFrom, this.dateTo, fileLanguage)
      .subscribe({
        next: (res) => {
          console.log(res);
          this.actionGridProgress = 100;
          this.openSucessSnackBar(this.successfulPublishMessage, "Ok"); //"La publicacion se realizara en los proximos minutos"
        },
        error: (e) => {
          console.error("Monthlygrids API Error: ", e);
          if (e === 404)
            this.openErrorSnackBar(this.noDataFeedErrorMessage, "Ok");
          else
            this.openErrorSnackBar(this.monthlyErrorMessage, "Ok");
          this.actionGridProgress = 0;
        },
        complete: () => {
          console.info('completed request');
        }
      });

  }

  manageExcelFile(response: any, filename: string): void {
    const dataType = response.type;
    const binaryData = [];

    binaryData.push(response);

    const filePath = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }))
    const downloadLink = document.createElement('a')
    downloadLink.href = filePath
    downloadLink.setAttribute('download', filename)
    document.body.appendChild(downloadLink)
    downloadLink.click()

  }

  addEventFrom(event: MatDatepickerInputEvent<Date>) {
    this.dateFromEmit.emit(event.value);
  }

  addEventTo(event: MatDatepickerInputEvent<Date>) {
    this.dateToEmit.emit(event.value);
  }

  getExtensions() {
    //traigo las extensiones para validar
    this.fileExtensionsConfigurationsService.getAllFilesExtConfByConfId(this.monthlyFileConfiguration.id).subscribe(result => {
      this.monthlyFileExtensionsConfigurations = result;
      this.extensions = this.monthlyFileExtensionsConfigurations.map(data => data.fileExtensionId.fileExtension);
    })
  }

  onFileSelected(event: any) {
    this.files = [];
    this.files = event.target.files;
    this.errorMessage = '';
    const invalidFiles: string[] = [];

    //valido extencion, tamaño y cantidad.
    Array.from(this.files).forEach(file => {
      if (!this.extensions.some(extension => file.name.toLowerCase().endsWith(extension))) {
        invalidFiles.push(`Archivo: ${file.name}, no tiene una extensión válida.`);
        // }
        // if (!file.name.toLowerCase().endsWith('.xls')) {
        //   invalidFiles.push(`Archivo: ${file.name}, no es .xls`);
      } else if (file.size === 0) {
        invalidFiles.push(`Archivo: ${file.name}, se encuentra vacío`);
      }
    });
    if (this.files.length + this.newfiles.length > 10) {
      invalidFiles.push(`Se han seleccionado mas de 10 archivos`);
    }
    //si hay algun archivo erroneo, muestro mensaje y no lo agrego
    if (invalidFiles.length > 0) {
      this.filesSelected = false;
      this.dialog.open(MonthlyUtilsDialogsComponentComponentComponent, {
        data: invalidFiles
      });
    }
    else {
      //agrego los archivos a la lista
      for (const file of this.files) {
        this.newfiles.push(file);
      }
      this.updateFilesSelected();
    }
    if (this.archivosSeleccionados.length > 0) {
      this.filesSelected = true;
    }
    this.archivosSeleccionados = Array.from(this.newfiles).map(file => file.name);

    event.target.value = '';

  }

  removeFile(archivo: string) {
    const index = this.newfiles.findIndex(file => file.name === archivo);
    if (index !== -1) {
      this.newfiles.splice(index, 1);
      this.archivosSeleccionados = this.newfiles.map(file => file.name);
      this.updateFilesSelected();
    }

  }

  updateFilesSelected() {
    this.filesSelected = this.newfiles.length > 0;
  }

  subirGrillas() {
    this.subiendoArchivos = true;
    this.monthlyService.uploadFilesToBucket(this.newfiles).subscribe(
      (response: MonthlyUploadResponse) => {
        this.subiendoArchivos = false;
        this.archivosSeleccionados = [];
        this.newfiles = [];
        //si viene vacio, se subieron bien
        if (response.uploadErrors && response.uploadErrors.length === 0) {
          this.filesSelected = false;
          this.openSucessSnackBar(this.monthlySuccessUploadMessage, "Ok");
        } else {//si viene con datos, algun archivo era invalido
          const messages = response.uploadErrors.map((error) => `${error.fileName}: ${error.errorMessage}`);
          this.dialog.open(MonthlyUtilsDialogsComponentComponentComponent, {
            data: messages
          });
        }
      },
      (error) => {
        this.subiendoArchivos = false;
        this.archivosSeleccionados = [];
        this.newfiles = [];
        this.openErrorSnackBar(this.monthlyErrorUploadMessage, "Ok");
      }
    );
  }

  //Funcion para filtrar los feed de Monthlygrids
  filterItems() {
    const filterValue = this.searchTerm.toLowerCase().trim();
  
    const cleanString = (str: string) => str?.toLowerCase().trim() || '';
  
    if (this.searchTerm) {
      // Filtrar elementos que empiezan con el término de búsqueda
      const startsWithFilter = this.itemLista.filter(item =>
        cleanString(item.feedtimezoneId.name).startsWith(filterValue) ||
        cleanString(item.language_id.code).startsWith(filterValue) ||
        cleanString(item.country_id.code).startsWith(filterValue)
      );
  
      // Filtrar elementos que contienen el término de búsqueda pero no empiezan con él
      const includesFilter = this.itemLista.filter(item =>
        !startsWithFilter.includes(item) && (
          cleanString(item.feedtimezoneId.name).includes(filterValue) ||
          cleanString(item.language_id.code).includes(filterValue) ||
          cleanString(item.country_id.code).includes(filterValue)
        )
      );
  
      // Ordenar ambas listas
      this.filteredItems = startsWithFilter
        .sort((a, b) => cleanString(a.feedtimezoneId.name).localeCompare(cleanString(b.feedtimezoneId.name)))
        .concat(
          includesFilter.sort((a, b) => cleanString(a.feedtimezoneId.name).localeCompare(cleanString(b.feedtimezoneId.name)))
        );
    } else {
      // Si no hay un término de búsqueda, ordenar la lista completa
      this.filteredItems = this.itemLista.slice()
        .sort((a, b) => cleanString(a.feedtimezoneId.name).localeCompare(cleanString(b.feedtimezoneId.name)));
    }
  }
}  
