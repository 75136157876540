import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UtilsDialogsComponent } from 'src/app/epg/visual-logs/utils-dialogs/visual-logs-dialogs.component';
import { Feeds } from 'src/app/shared/model/visual-logs-model/feeds';
import { FileProcess } from 'src/app/shared/model/visual-logs-model/files';
import { Process } from 'src/app/shared/model/visual-logs-model/process';
import { VisualLogsService } from 'src/app/shared/service/visual-logs.service';

@Component({
  selector: 'app-visual-logs',
  templateUrl: './visual-logs.component.html',
  styleUrls: ['./visual-logs.component.scss']
})
export class VisualLogsComponent implements OnInit {
  @ViewChild(MatPaginator) tablePaginator: MatPaginator;
  //@ViewChild(String "tablePaginator") tablePaginator: MatPaginator;
  @Output()
  public dateFromEmit = new EventEmitter<Date>();

  @Output()
  public dateToEmit = new EventEmitter<Date>();

  itemLista: Process[] = [];
  itemListaFeeds: Feeds[] = [];
  itemArchivo: FileProcess[] = [];
  statusArchivo: String[] = [];
  displayedColumns: String[] = ["file_name", "feed", "status", "date", "time", " "]
  dataSourceFile = new MatTableDataSource([]);
  disableSelect = new FormControl(false);
  idProcessSelected: number;
  mostrarBarra: boolean = false;
  startDateFilter: string[] = [];
  processSelected: boolean = false;

  private readonly MIN_DATE_IN_DAYS ;
  private readonly MAX_DATE_IN_DAYS = new Date().getDate() + 1;
  minDate = new Date();
  maxDate = new Date();
  range = new FormGroup({
    start: new FormControl('', Validators.required),
    end: new FormControl('', Validators.required)
  });

  filterForm:FormGroup;

  @ViewChild(MatSort, { static: true })
    sort: MatSort;


  constructor(private _service: VisualLogsService, private dialog: MatDialog) { 
    this.filterForm = new FormGroup({
      feed: new FormControl(''),
      status: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl('')
    });

  }

  ngOnInit(): void {
    this.minDate.setDate(this.MIN_DATE_IN_DAYS);
    this.maxDate.setDate(this.MAX_DATE_IN_DAYS);

    this._service.traerProcesos().subscribe(result => {
      this.itemLista = result;
    });

    this._service.traerFeeds().subscribe(result => {
      this.itemListaFeeds = result;
    });

    this._service.traerArchivosStatus().subscribe(result => {
      this.statusArchivo = result;
    });
  }

  openDialog(element) {

    this.dialog.open(UtilsDialogsComponent, {
      data: {
        id: element.id,
        file_name: element.file_name,
      },
    });
  }

  onselecprocess(deviceValue) {
    this.processSelected = true;
    this.mostrarBarra = true;
    this.filterForm.reset();
    this.startDateFilter[0] = "";
    this.startDateFilter[1] = "";
    this.actualizarTabla(deviceValue);
  }

  refresh(){
    this.onselecfilter(this.startDateFilter);
  }

  onselecfilter(value) {
    let feed = this.filterForm.get('feed')?.value;
    let status = this.filterForm.get('status')?.value;
    let startDate = this.startDateFilter[0];//this.filterForm.get('startDate')?.value;
    let endDate = this.startDateFilter[1];//this.filterForm.get('endDate')?.value;

    this._service.traerArchivosFiltered(feed, status, startDate, endDate, this.idProcessSelected).subscribe(result => {
      this.itemArchivo = result;
      this.dataSourceFile = new MatTableDataSource(result);
      this.dataSourceFile.paginator = this.tablePaginator;
      this.dataSourceFile.sort = this.sort;
      this.mostrarBarra = false;
    }
    );
  }

  actualizarTabla(id: number) {
    //this.filterForm.reset();
    this._service.traerArchivosId(id).subscribe(result => {
      this.itemArchivo = result;
      this.idProcessSelected = id;
      this.dataSourceFile = new MatTableDataSource(result);
      this.dataSourceFile.paginator = this.tablePaginator;
      this.dataSourceFile.sort = this.sort;
      this.mostrarBarra = false;
    }
    );
  }

  addEventFrom(event: MatDatepickerInputEvent<Date>) {
    this.startDateFilter[0] = this.format(event.value).toString();
  }

  addEventTo(event: MatDatepickerInputEvent<Date>) {
    this.startDateFilter[1] = this.format(event.value).toString();
    this.onselecfilter(this.startDateFilter);
  }

  format(date: Date): string {
    console.log("date",date)

    const day = new Date(date).getDate().toString().padStart(2, '0');
    const month = (new Date(date).getMonth() + 1).toString().padStart(2, '0');
    const year = new Date(date).getFullYear();

    return `${year}-${month}-${day}`;
  }
}
