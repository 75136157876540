
export class NewsletterRequest{
    fromId: number;
    subject: string;
    body: string;
    template: string;
    language: string;
    networksIds: number[];
    countries: string[];
    userIds: number[];


    constructor(
        fromId: number,
        subject: string,
        body: string,
        template: string,
        language: string,
        networksIds: number[],
        countries: string[],
        usersIds: number[]) {
            this.fromId = fromId;
            this.subject = subject;
            this.body = body;
            this.template = template;
            this.language = language;
            this.networksIds = networksIds;
            this.countries = countries;
            this.userIds = usersIds;
    }
}
