import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dialog-timeout',
    templateUrl: './dialog-timeout.component.html',
    styleUrls: ['./dialog-timeout.component.css']
})
export class DialogTimeoutComponent implements OnDestroy {

    public timeout: any;
    private warningSubscription: Subscription;

    constructor(
            public dialogRef: MatDialogRef<DialogTimeoutComponent>,
            private idle: Idle,
            iconRegistry: MatIconRegistry,
            sanitizer: DomSanitizer) {

        this.warningSubscription = this.idle
            .onTimeoutWarning
            .subscribe(countdown => this.timeout = { timeout: String(countdown)});

        iconRegistry.addSvgIcon(
            'close',
            sanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/twotone-close-24px.svg')
        );
    }

    ngOnDestroy(): void {
        this.warningSubscription.unsubscribe();
    }

    logout(): void {
        this.dialogRef.close(true);
    }

    stay(): void {
        this.dialogRef.close();
    }

}
