import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { NgIdleModule } from '@ng-idle/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AlertComponent } from './shared/alert/alert.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { EpgModule } from './epg/epg.module';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { LoginModule } from './login/login.module';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { CustomPaginator } from './shared/utils/custom-paginator';
import { ErrorInterceptor } from './shared/utils/error.interceptor';
import { JwtInterceptor } from './shared/utils/jwt.interceptor';
import { VisualLogsService } from './shared/service/visual-logs.service';
import { UtilsDialogsComponent } from './epg/visual-logs/utils-dialogs/visual-logs-dialogs.component';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBar, MatProgressBarModule } from '@angular/material/progress-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmDialogComponent } from './shared/dialogs/confirm-dialog/confirm-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { RegisterModule } from './register/register.module';
import { MatDialogModule } from '@angular/material/dialog';
import { QuillModule } from 'ngx-quill'

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        NotFoundComponent,
        AlertComponent,
        UtilsDialogsComponent,
        ConfirmDialogComponent,
    ],
    // El orden de los modulos es importante
    imports: [
        BrowserModule,
        MatProgressBarModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter
            }
        }),
        NgIdleModule.forRoot(),
        HttpClientModule,
        BrowserAnimationsModule,
        LoginModule,
        QuillModule.forRoot(),
        EpgModule,
        RegisterModule,
        MatDialogModule,
        // Tener en cuenta que el AppRoutingModule, debe ser el ultimo
        AppRoutingModule,
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: CustomPaginator },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        VisualLogsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

// Requerido para compilacion con AOT
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

// Inicializa el config de JwtModule
export function tokenGetter() {
    return sessionStorage.getItem('');
}
