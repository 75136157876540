import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from 'src/app/shared/model/user';
import { GeneralService } from 'src/app/shared/service/general.service';
import { environment } from 'src/environments/environment';
import { Feed } from 'src/app/shared/model/feed';
import { FeedSubscriptionResponse } from 'src/app/shared/response/feed-subscription.response';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient,
        private gralService: GeneralService
    ) { }

    getUser$(): Observable<User> {
        return this.http
                   .get<User>(environment.apiDomainUrlGetUser)
                   .pipe(catchError(this.gralService.handleError));
    }

    subscribeToEPG$(feeds: Feed[]): Observable<FeedSubscriptionResponse> {
        return this.http
            .put<FeedSubscriptionResponse>(environment.apiDomainUrlPutUserSubscription, feeds)
            .pipe(catchError(this.gralService.handleError));
    }

    registerDownloadedFile(formData: FormData): Observable<any> {
        return this.http.post(environment.apiDomainUrlPostUserFileDownloaded, formData);
    }

}
