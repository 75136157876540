import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralService } from './general.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Feed } from '../model/feed';
import { FeedResponse } from '../response/feed.response';
import { LanguageResponse } from '../response/language.response';
import { Language } from '../model/language';
import { LanguageSelect } from '../model/languageSelect';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    private apiUrl = environment.apiSecurityUrlLanguage;

    constructor(
        private http: HttpClient,
        private gralService: GeneralService) {
    }

    getLanguages$(): Observable<Language[]> {
        this.http.get<LanguageResponse>(environment.apiDomainGetLanguages).pipe(map(response => response.languages), catchError(this.gralService.handleError))
        //console.log("****" + environment.apiDomainGetLanguages);
        return this.http.get<LanguageResponse>(environment.apiDomainGetLanguages).pipe(map(response => response.languages), catchError(this.gralService.handleError));
    }

    getLanguagesSelect(): Observable<LanguageSelect[]> {
        return this.http.get<LanguageSelect[]>(this.apiUrl);
    }

    addLanguage(language: LanguageSelect): Observable<LanguageSelect> {
        return this.http.post<LanguageSelect>(`${this.apiUrl}/add`, language).pipe(
            catchError(this.gralService.handleError)
        );
    }

    updateLanguage(id: number, language: LanguageSelect): Observable<LanguageSelect> {
        return this.http.put<LanguageSelect>(`${this.apiUrl}/update/${id}`, language).pipe(
            catchError(this.gralService.handleError)
        );
    }

    deleteLanguage(id: number): Observable<void> {
        return this.http.delete<void>(`${this.apiUrl}/delete/${id}`).pipe(
            catchError(this.gralService.handleError)
        );
    }

}
