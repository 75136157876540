import { Component, OnInit, Output } from '@angular/core';
import { NewsletterService } from 'src/app/shared/service/newsletter.service';

@Component({
  selector: 'app-newsletter-editor',
  templateUrl: './newsletter-editor.component.html',
  styleUrls: ['./newsletter-editor.component.css']
})
export class NewsletterEditorComponent implements OnInit {

    @Output()
    sendNewsletter: boolean = false;



    constructor( private newsletterService: NewsletterService) { }

    ngOnInit(): void {
    }


    onContentChanged(event: any) {
        const content = event.html; // Obtiene el contenido HTML del editor
        this.newsletterService.setNewsletterMessage(content) ; // Envia el contenido al servicio
      }

}
