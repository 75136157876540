import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { NetworkEpg } from 'src/app/shared/model/network-epg-model/network-epg';
import { User } from 'src/app/shared/model/user';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { NetworkEpgService } from 'src/app/shared/service/network-epg.service';
import { NetworkService } from 'src/app/shared/service/network.service';

@Component({
    selector: 'app-network-subscription',
    templateUrl: './network-subscription.component.html',
    styleUrls: ['./network-subscription.component.scss']
})
export class NetworkSubscriptionComponent implements OnInit {

    networkList: NetworkEpg[] = [];
    selectedNetworks: NetworkEpg[] = [];
    selectedPeriod: string;
    periods: string[] = ['Daily', 'Weekly', 'Monthly']
    networkCrtl = new FormControl('', Validators.required);
    periodCrtl = new FormControl('', Validators.required);
    filteredNetworkList = this.networkList;
    allNetworksSelected = false;

    currentUser: User;

    constructor(private networkEpgService: NetworkEpgService,
        private authenticationService: AuthenticationService,
        private networkService: NetworkService) { }

    ngOnInit(): void {

        this.networkEpgService.getActiveNetworks().pipe(
            map(result => result.sort((a, b) => a.nameEn.toLowerCase().localeCompare(b.nameEn.toLowerCase())))
        ).subscribe(sortedResult => {
            this.networkList = sortedResult;
            this.filteredNetworkList = this.networkList
        });

        this.networkCrtl.valueChanges.subscribe(selectedValues => {
            this.selectedNetworks = this.getSelectedNetworks(selectedValues);
        });

        this.periodCrtl.valueChanges.subscribe(selectedValues => {
            this.selectedPeriod = selectedValues;
        });

        this.currentUser = this.authenticationService.currentUserValue;
    }

    getNetworkById(id: number): string {
        let network = this.networkList.find(network => network.id === id);
        if (network)
            return network.nameEn
        else
            return '';
    }

    filterNetworks(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

        if (!filterValue) {
            this.filteredNetworkList = this.networkList;
            return;
        }

        this.filteredNetworkList = this.networkList.filter(network => network.nameEn.toLowerCase().includes(filterValue));
    }

    toggleSelectAllNetworks() {
        this.allNetworksSelected = !this.allNetworksSelected;

        if (!this.allNetworksSelected) {
            this.networkCrtl.setValue([]);
        } else {
            let netwoksIds = this.networkList.map(network => network.id);
            netwoksIds.push(0);
            this.networkCrtl.setValue(netwoksIds);
        }

    }

    private getSelectedNetworks(selectedIds: number[]): NetworkEpg[] {
        return this.filteredNetworkList.filter(network => selectedIds.includes(network.id));
    }

    subscribe() {

        const requestBody = {
            networks: this.selectedNetworks,
            period: this.selectedPeriod,
            username: this.currentUser.username
        };

        this.networkService.subscribeNetworks(requestBody).subscribe(result => {
        });

    }

}
