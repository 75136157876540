import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryEnum } from 'src/app/shared/constants/country.enum';
import { UserProfile } from 'src/app/shared/model/user-profile';
import { UserProfileService } from 'src/app/shared/service/user-profile.service';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit {

    @Input() user: UserProfile;

    PersonalDataForm: FormGroup;

    loading = false;
    successUpdatePersonalData = false;
    errorUpdatePersonalData = false;

    countryList = Object.keys(CountryEnum).map(key => ({ value: key, name: CountryEnum[key] }));



    constructor(
        private formBuilder: FormBuilder,
        private userProfileService: UserProfileService
    ) { }

    ngOnInit(): void {
        this.PersonalDataForm =this.formBuilder.group({
            nameCtrl: new FormControl(this.user.firstName , Validators.required),
            lastNameCtrl: new FormControl(this.user.lastName , Validators.required),
            companyCtrl: new FormControl(this.user.company.name , Validators.required),
            countryCtrl: new FormControl({value: this.user.address.country, disabled: false } , Validators.required),
            cityCtrl: new FormControl(this.user.address.city , Validators.required),
            addressCtrl: new FormControl(this.user.address.street , Validators.required),
            zipCodeCtrl: new FormControl(this.user.address.zipCode , Validators.required)
        });
    }

    updatePersonalData(): void {
        this.loading = true;
        this.errorUpdatePersonalData = false;
        this.successUpdatePersonalData = false;

        this.user.firstName = this.PersonalDataForm.controls.nameCtrl.value;
        this.user.lastName = this.PersonalDataForm.controls.lastNameCtrl.value;
        this.user.company.name = this.PersonalDataForm.controls.companyCtrl.value;
        this.user.address.country = this.PersonalDataForm.controls.countryCtrl.value;
        this.user.address.city = this.PersonalDataForm.controls.cityCtrl.value;
        this.user.address.street = this.PersonalDataForm.controls.addressCtrl.value;
        this.user.address.zipCode = this.PersonalDataForm.controls.zipCodeCtrl.value;

        this.userProfileService.updateProfile$(this.user).subscribe(
            () => {
                this.successUpdatePersonalData = true;
                this.loading = false;
            },
            (error) => {
                this.errorUpdatePersonalData = true;
                this.loading = false;
            }
        )


    }
}
