import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CountrySelect } from 'src/app/shared/model/countrySelect';
import { UtilService } from 'src/app/shared/service/util.service';

@Component({
  selector: 'app-country-dialog',
  templateUrl: './country-dialog.component.html',
  styleUrls: ['./country-dialog.component.css']
})
export class CountryDialogComponent implements OnInit {
  countryForm: FormGroup;
  isEditing: boolean;

  constructor(
    public dialogRef: MatDialogRef<CountryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { country: CountrySelect, isEditing: boolean },
    private fb: FormBuilder,
    private translateService: TranslateService,
    private utilService: UtilService
  ) {
    this.countryForm = this.fb.group({
      name: ['', Validators.required],
    });
    this.isEditing = data.isEditing;
  }

  ngOnInit(): void {
    if (this.isEditing && this.data.country) {
      this.countryForm.setValue({
        name: this.data.country.country,
      });
    }
  }

  async onSave() {
    if (this.countryForm.valid) {
      let textConfirm = this.isEditing 
        ? await this.translateService.get('epg.users.dialog.messageEditConfirm').toPromise()
        : await this.translateService.get('epg.users.dialog.messageAddConfirm').toPromise();
      
      let buttonConfirm = this.isEditing
        ? await this.translateService.get('epg.users.dialog.buttons.edit').toPromise()
        : await this.translateService.get('epg.users.dialog.buttons.add').toPromise();

      const dialogRef = this.utilService.openConfirmDialog({
        title: buttonConfirm,
        text: textConfirm,
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          var newCountry: CountrySelect = {
            country: this.countryForm.value.name
          }
          this.dialogRef.close(newCountry);
        }
      });
    }
  }
 
  onCancel(): void {
    this.dialogRef.close();
  }

}
