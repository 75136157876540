import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EpgErrorResponse } from '../model/EpgErrorResponse';
import { MonthlyUploadResponse } from '../model/monthly-upload-model/MonthlyUploadResponse';
import { EpgNews } from '../model/news-model/news';
import { EpgNewsUpload } from '../model/news-model/news-upload';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(
    private http: HttpClient,
    private gralService: GeneralService) { }

  getAllNetworkNews() {
    return this.http.get<EpgNews[]>(environment.apiEpgGetAllNews);
  }

  getAllNetworkNewsByNetworkId(networkId: number) {
    var params = new HttpParams();
    if (networkId)
      params = params.set('networkId', networkId + "");

    return this.http.get<EpgNews[]>(environment.apiEpgGetAllNewsByNetworkId, { params, });
  }

  uploadNewsFilesToS3(epgNewsUpload: EpgNewsUpload) {
    let formData = new FormData();

    formData.append('imageName', epgNewsUpload.imageName);
    formData.append('imageFile', epgNewsUpload.imageFile);

    return this.http.post<MonthlyUploadResponse>(environment.apiEpgUploadNewsFiles, formData).pipe(
      catchError(this.gralService.handleError));
  }

  uploadNewsDB(epgNewsUploadDB: EpgNews): Observable<EpgErrorResponse>{
    return this.http.post<EpgErrorResponse>(environment.apiEpgUploadNewsDB, epgNewsUploadDB ).pipe(
      catchError(this.gralService.handleError));
  }

  deleteImageFromS3(imageName: string):Observable<MonthlyUploadResponse>{
    let formData = new FormData();
    formData.append('imageName', imageName);
    return this.http.post<MonthlyUploadResponse>(environment.apiEpgDeleteNewsImageFromS3, formData);
  }

  deleteNews(newsId: number): Observable<EpgErrorResponse>{
    var params = new HttpParams();
    params = params.set('newsId', newsId + "");
    return this.http.delete<EpgErrorResponse>(environment.apiEpgDeleteNewsDB, { params, });
  }

  findByNetworkDateAndImage(): Observable<EpgNews[]>{
    return this.http.get<EpgNews[]>(environment.apiDomainGetNewsForCarousel);
  }

}
