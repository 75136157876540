import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor( private matDialog: MatDialog) { }

  openConfirmDialog(datos:{
    title?: string,
    text?: string
    count?: number,
    } )
    {
        return this.matDialog.open(ConfirmDialogComponent, {
           minWidth: '35vw',
           disableClose: true,
           data:datos,
           autoFocus: false,
           panelClass: 'confirm-dialog-container'
        });
    }
}
