import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataNetwork } from '../model/data-network';
import { Feed } from '../model/feed';
import { ConfigGridAlias } from '../model/config-grid-alias';

@Injectable({
    providedIn: 'root'
})
export class EpgDataService {

    public codeNetworkSubject$: BehaviorSubject<DataNetwork>;
    public codeNetwork$: Observable<DataNetwork>;

    constructor() {
        this.codeNetworkSubject$ = new BehaviorSubject<DataNetwork>(new DataNetwork());
        this.codeNetwork$ = this.codeNetworkSubject$.asObservable();
    }

}
