import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NetworkContact } from '../model/network-contact';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkContactService {

  constructor(
    private http: HttpClient
  ) { }

  getAllContacts(networkId: number): Observable<NetworkContact>  {
    let response = this.http.get<NetworkContact>(environment.apiNetworksContactsGetAllNetworkContacts + "/" + networkId);
    return response;
  }
}
