import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RoleType } from 'src/app/shared/model/role-type';
import { UserProfile } from 'src/app/shared/model/user-profile';
import { UserProfileResponse } from 'src/app/shared/response/user-profile-response';
import { RoleService } from 'src/app/shared/service/role.service';
import { UserProfileService } from 'src/app/shared/service/user-profile.service';
import { UtilService } from 'src/app/shared/service/util.service';
import { Observable } from 'rxjs';
import { map, startWith, switchMap, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';

import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarRef,
  } from '@angular/material/snack-bar'

@Component({
  selector: 'app-users-dialog',
  templateUrl: './users-dialog.component.html',
  styleUrls: ['./users-dialog.component.scss']
})
export class UsersDialogComponent implements OnInit {

    filter = new FormControl();
    filteredUsers: Observable<UserProfile[]>;
    roles: RoleType[] = [];
    selectedRoles: RoleType[] = [];

    loadingAprove: boolean = false;
    loadingReject: boolean = false;
    loadingActivate: boolean = false;
    loadingDeactivate: boolean = false;

    loadingCopyRoles : boolean = false;
    loadingDelete : boolean = false;
    showAlert: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: UserProfile,
        public dialogRef: MatDialogRef<UsersDialogComponent>,
        private userProfileService: UserProfileService,
        private utilService: UtilService,
        private translateService: TranslateService,
        private matSnackBar: MatSnackBar
    ) {
        this.roles = JSON.parse(localStorage.getItem('roles'));
   }

  ngOnInit(): void {

    this.filteredUsers = this.filter.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter(value =>  (typeof value === 'string' || value instanceof String) && value.length >= 3),
        switchMap(value => this.search(value || '')),
        map(users => users.filter(user => user.username !== this.data.username)
        )
    );
  }

 search(value: string): Observable<UserProfile[]> {
    return this.userProfileService.searchUser$(value).pipe(
        map(response => response.userProfiles)
    );
  }


  openSnackBar(message: string) {
    this.matSnackBar.openFromComponent(UsersDialogComponent , {
        duration: 1000,
        data: message,
        })
  }

  openErrorSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.panelClass = ['error-snackbar'];
    this.matSnackBar.open(message, action, config);
  }

  openSuccessSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = "top";
    config.panelClass = ['success-snackbar'];
    this.matSnackBar.open(message, action, config);
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent): void {
    const selectedOption = event.option.value;

    this.selectedRoles = [];
    this.selectedRoles = selectedOption.role;
  }

  displayFn(user: UserProfile): string {
    return user && user.username ? user.username : '';
  }


  copyRoles(){
    const dialogRef = this.utilService.openConfirmDialog({
        title: 'Confirmar',
        text: '¿Está seguro de realizar esta acción?'
    }).afterClosed().subscribe((result) => {
        if(result){
            this.loadingCopyRoles = true;
            this.data.role = this.selectedRoles;
            this.userProfileService.updateRole$(this.data)
                .subscribe(async resp=>{
                    this.loadingCopyRoles = false;
                    let message = await this.translateService.get('epg.users.dialog.success-role').toPromise();
                    this.openSuccessSnackBar(message, 'Close');
                }, async error => {
                    this.loadingCopyRoles = false;
                    let message = await this.translateService.get('epg.users.dialog.error-role').toPromise();
                    this.openErrorSnackBar(message, 'Close');
                })
        }
    });

  }


  getLanguageName(id: string): string {
    if(id === 'SPA'){
        return 'ESPAÑOL';
    }
    if(id === 'ENG'){
        return 'ENGLISH';
    }
    if(id === 'POR'){
        return 'PORTUGUES';
    }
    if(id === '' || id === null){
        return 'N/A';
    }
  }

  getState(state:number){
    if(state === 0){
      return 'PENDIENTE DE APROBACIÓN';
    }

    if(state === 1){
      return 'NO CONFIRMADO';
    }

    if(state === 2){
      return 'RECHAZADO';
    }

    if(state === 3){
      return 'ACTIVO';
    }

    if(state === 4){
      return 'INACTIVO';
    }
  }


  matchRole(id: number): boolean {
    for (let i = 0; i < this.data.role.length; i++) {
      if (this.data.role[i].id === id) {
        return true;
      }
    }
    return false;
  }



  close(): void {
    this.dialogRef.close(this.data);
  }

  toggleRole(rol:RoleType, event  ): void {

    const dialogRef = this.utilService.openConfirmDialog({
        title: 'Confirmar',
        text: '¿Está seguro de realizar esta acción?'
    }).afterClosed().subscribe((result) => {
        if(result){
            if(event.checked){
                this.data.role.push(rol);
                this.userProfileService.updateRole$(this.data)
                    .subscribe(resp=>{
                    })
            }
            if(!event.checked){
                let index = this.data.role.findIndex((role:RoleType) => role.id === rol.id);
                this.data.role.splice(index, 1);
                this.userProfileService.updateRole$(this.data)
                    .subscribe(resp=>{
                    })
            }
        }
        if(!result)
            event.source.checked = !event.checked;
    });

  }

  updateState(state: number, action: string ): void {

    const dialogRef = this.utilService.openConfirmDialog({
      title: 'Confirmar',
      text: '¿Está seguro de realizar esta acción?',
    }).afterClosed().subscribe((result) => {
        if(result){
            this.selectLoadingState(action, true);
            this.userProfileService.updateUserProfileStatus$(this.data.id, state).subscribe(
                (data: UserProfileResponse) => {
                this.selectLoadingState(action, false);
                this.openSuccessSnackBar('Estado actualizado correctamente', 'Cerrar');
                this.data = data.userProfiles[0];
            },
            (error) => {
                this.selectLoadingState(action, false);
                this.openErrorSnackBar('Error al actualizar estado', 'Cerrar');
            }
          );
        }
    });
  }

  async deleteUser(){

    let textConfirm = await this.translateService.get('epg.users.dialog.messageconfirm').toPromise();
    let buttonConfirm = await this.translateService.get('epg.users.dialog.buttons.confirm').toPromise();


    const dialogRef = this.utilService.openConfirmDialog({
      title: buttonConfirm,
      text: textConfirm,
    }).afterClosed().subscribe((result) => {
        if(result){
            this.loadingDelete = true;
            this.userProfileService.removeUserProfile(this.data.id).subscribe(
                (data: UserProfileResponse) => {
                this.loadingDelete = false;
                localStorage.setItem('operation', "delete");
                this.dialogRef.close(data);
                this.translateService.get('epg.users.dialog.message-success-delete').subscribe((msg: string) => {
                    this.openSuccessSnackBar(msg, 'Close');
                });
            },
            (error) => {
                this.loadingDelete = false;
                this.translateService.get('epg.users.dialog.message-error-delete').subscribe((msg: string) => {
                    this.openErrorSnackBar(msg, 'Close');
                });
            }
          );
        }
    });
  }


  selectLoadingState(state:string,value: boolean ){
    switch (state) {
        case "aprove": {
            this.loadingAprove = value;
            break;
        }
        case "reject": {
            this.loadingReject = value;
            break;
        }
        case "activate": {
            this.loadingActivate = value;
            break;
        }
        case "deactivate": {
            this.loadingDeactivate = value;
            break;
        }

    }
  }

  ngOnDestroy(): void {
    this.dialogRef.close(this.data);
  }

}
