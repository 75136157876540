import { Injectable } from '@angular/core';
import { NetworskDownloadData } from '../model/network-epg-model/networks-donwload-data';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkDataSharingService {
  private myObjectSubject = new Subject<NetworskDownloadData>();

  constructor() { }

  getMyObjectSubject(): Observable<NetworskDownloadData>{
    return this.myObjectSubject.asObservable();
  }

  updateMyObject(newState: NetworskDownloadData){
    this.myObjectSubject.next(newState);
  }
}
