import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CountrySelect } from '../model/countrySelect';
import { catchError } from 'rxjs/operators';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {


  private apiUrl = environment.apiSecurityUrlCountry;

  constructor(
    private http: HttpClient,
    private gralService: GeneralService) { 
    }

  getCountries(): Observable<CountrySelect[]> {
    return this.http.get<CountrySelect[]>(this.apiUrl);
  }


  addCountry(country: CountrySelect): Observable<CountrySelect> {
    return this.http.post<CountrySelect>(`${this.apiUrl}/add`, country).pipe(
      catchError(this.gralService.handleError)
    );
  }

  updateCountry(id: number, country: CountrySelect): Observable<CountrySelect> {
    return this.http.put<CountrySelect>(`${this.apiUrl}/update/${id}`, country).pipe(
      catchError(this.gralService.handleError)
    );
  }

  deleteCountry(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/delete/${id}`).pipe(
      catchError(this.gralService.handleError)
    );
  }
}
