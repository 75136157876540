import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Module } from 'src/app/shared/model/module';

@Component({
    selector: 'app-module-select',
    templateUrl: './module-select.component.html',
    styleUrls: ['./module-select.component.css']
})
export class ModuleSelectComponent {

    @Input()
    modules: Module[];

    @Output()
    public moduleEmit = new EventEmitter<Module>();

    moduleCtrl = new FormControl('', Validators.required);

    onModuleChange(event: MatSelectChange) {
        this.moduleEmit.emit(event.value);
    }

}
