import { Feed } from './feed';

export class User {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    accessToken: string;
    refreshToken: string;
    feeds: Feed[];
}
