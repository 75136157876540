import { Injectable } from '@angular/core';
import { Router, NavigationExtras, Params } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UrlService {

  private redirectUrl : string | null;
  private queryParams : Params | null;
 
  constructor(private router: Router) {}
 
   // Método para establecer la URL de destino
  setRedirectUrl(url: string, queryParams?: Params): void {
     this.redirectUrl = url
     this.queryParams = queryParams
    }
 
   // Método para obtener la URL de destino
  getRedirectUrl():  string | null{
     return this.redirectUrl;
 }

 getParams(): Params{
  return this.queryParams;
 }

  //Borrar URL
  clearRedirectUrl(): void {
    this.redirectUrl = null;
  }

  // Método para redirigir a la URL de destino almacenada
  redirectToStoredUrl(): void{
    const url = this.redirectUrl || '/';
    const navigationExtras: NavigationExtras = {
      queryParams: { redirect: url }
    };
    this.router.navigate(['/login'], navigationExtras);
  }
  
}

