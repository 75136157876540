import { Component, OnInit, ViewChild, Input, ViewEncapsulation } from '@angular/core';
import { AncineGridsList } from 'src/app/shared/model/ancine-grids-model/ancine-grids-list';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { AncineGirdsService } from 'src/app/shared/service/ancine-grids.service';
import { EpgComponent } from '../../epg.component';
import { takeUntil } from 'rxjs/operators';
import { AncineGridsFile } from 'src/app/shared/model/ancine-grids-model/ancine-grids-file';
import { MatDialog } from '@angular/material/dialog';
import { AncineUtilsDialogsComponentComponent } from './utils-dialogs/ancine-utils-dialogs-component/ancine-utils-dialogs-component.component';
import { Network } from 'src/app/shared/model/network';
import { AncineNetworksData } from 'src/app/shared/model/ancine-grids-model/ancine-grids-networks-data';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-ancine-feed-grid',
  templateUrl: './ancine-feed-grid.component.html',
  styleUrls: ['./ancine-feed-grid.component.scss']
})
export class AncineGridComponent implements OnInit {

  panelOpenState = false;
  filterForm: FormGroup;
  ancineNetworksData: AncineNetworksData = {};
  selectedFiles: AncineGridsFile[] = [];
  network: string;
 //mostrarTab1: boolean = false;
 //mostrarTab2: boolean = false;
  mostrarTab3: boolean = false;

  private unsubscription$: Subject<void>;

  //valores hardcodeados
  tab1: string = "PUBLIC";
  tab2: string = "PUBLIC_DAILY";
  tab3: string = "RESTRICTED";
  countSelected = 0;
  ancineFile: any;

  constructor(private _service: AncineGirdsService, private epgComponent: EpgComponent, private dialog: MatDialog) {
    this.unsubscription$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.epgComponent.selectedNetworkCode$.pipe(takeUntil(this.unsubscription$)).subscribe((codeNetwork) => {
      this.tabs(codeNetwork);
    })
  }

  ngOnDestroy(): void {
    this.unsubscription$.next();
    this.unsubscription$.complete();
  }

  tabs(codeNetwork: string): void {
    //this.mostrarTab1 = true;
    //this.mostrarTab2 = true;
    
    this.network = codeNetwork;
    if (this.ancineNetworksData[codeNetwork] == undefined) {
      this.mostrarTab3 = true;
      
      this.ancineNetworksData[codeNetwork] = {
        filesPublic: null,
        filesDaily: null,
        filesRestricted: null
      };
      this._service.getFileByNetwork(codeNetwork, this.tab1).subscribe(result => {
        this.ancineNetworksData[codeNetwork].filesPublic = result
        //this.mostrarTab1 = false;
      });
      this._service.getFileByNetwork(codeNetwork, this.tab2).subscribe(result => {
        this.ancineNetworksData[codeNetwork].filesDaily = result
       // this.mostrarTab2 = false;
      });
      this._service.getFileByNetwork(codeNetwork, this.tab3).subscribe(result => {
        this.ancineNetworksData[codeNetwork].filesRestricted = result
        this.mostrarTab3 = false;
      });
      
    }

  }

  updateSelectedCount(ancineFile: AncineGridsFile) {
    if (ancineFile.selected) {
      this.selectedFiles.push(ancineFile);
    } else {
      const index = this.selectedFiles.findIndex(file => file.id === ancineFile.id);
      if (index !== -1) {
        this.selectedFiles.splice(index, 1);
      }
    }
  }

  descargarfilesPublicSeleccionados() {
    this.dialog.open(AncineUtilsDialogsComponentComponent, {
      data: this.selectedFiles.sort((a, b) => a.channelId.code.localeCompare(b.channelId.code))
    });
  }

  deselectFiles(){
    for (const file of this.selectedFiles){
      file.selected=false;
    }
    this.selectedFiles = [];
    //this.selectedFiles.length=0;
  }
}