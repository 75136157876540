import { SelectionModel } from '@angular/cdk/collections';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Feed } from 'src/app/shared/model/feed';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserService } from 'src/app/shared/service/user.service';
import { ShowService } from '../../../shared/service/show.service';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';

@Component({
    selector: 'app-feed-confirm',
    templateUrl: './feed-confirm.component.html',
    styleUrls: ['./feed-confirm.component.css']
})
export class FeedConfirmComponent implements OnDestroy {

    @Input()
    selection: SelectionModel<Feed>;

    loadingConfirm = false;
    loadingDownload = false;
    alertMessageDownload: string;

    private unsubscription$: Subject<void>;

    constructor(
            public dialog: MatDialog,
            private userService: UserService,
            private showService: ShowService,
            private alertService: AlertService,
            private translateService: TranslateService,
            iconRegistry: MatIconRegistry,
            sanitizer: DomSanitizer) {

        iconRegistry.addSvgIcon('download',
            sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/cloud-download-24px.svg')
        );
        iconRegistry.addSvgIcon('confirm',
            sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/done-24px.svg')
        );
        this.unsubscription$ = new Subject<void>();
        this.translateService
            .stream(['epg.feed.alertMessageDownload'])
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(translations => this.alertMessageDownload = translations['epg.feed.alertMessageDownload']);
    }

    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
     }

    download(): void {
        this.alertService.clear();
        this.loadingDownload = true;
        this.userService
            .getUser$()
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(
                data => this.downloadByFeed(data.feeds),
                () => this.loadingDownload = false);
    }

    private downloadByFeed(feeds: Feed[]): void {
        const feedsTotal = feeds.length;
        let feedsCounter = 0;
        feeds.forEach(feed => {
            this.showService
                .download$(feed.alias)
                .pipe(takeUntil(this.unsubscription$))
                .subscribe((response: HttpResponse<Blob>) => this.downloadFile(response))
                .add(() => this.loadingDownload = (++feedsCounter !== feedsTotal));
        });
        if (feedsTotal === 0) {
            this.alertService.error(this.alertMessageDownload);
            this.loadingDownload = false;
        }
    }

    private downloadFile(response: HttpResponse<Blob>): void {
        if (response.body === null) {
            this.alertService.error(
                this.getContentDispositionValue(response.headers)
            );
        } else {
            const url = window.URL.createObjectURL(response.body);
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.href = url;
            // Nombre del archivo
            a.download = this.getContentDispositionValue(response.headers);
            a.click();
        }
    }

    /**
     * Devuelve el valor de la cabecera Content-Disposition
     *
     * @param headers cabeceras http
     */
    private getContentDispositionValue(headers: HttpHeaders): string {
        return headers.get('Content-Disposition')
            .split('=')[1]
            .replace('"', '')
            .replace('"', '');
    }

    openDialog(): void {
        const settings = {
            height: '210px',
            width: '356px',
            disableClose: true,
            autoFocus: true,
            data: this.selection.selected
        };

        this.dialog
            .open(DialogConfirmComponent, settings)
            .afterClosed()
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(result => this.acceptDialog(result));
    }

    private acceptDialog(result: any): void {
        this.alertService.clear();
        this.loadingConfirm = true;
        if (result) {
            this.userService
                .subscribeToEPG$(result)
                .pipe(takeUntil(this.unsubscription$))
                .subscribe(
                    data => this.alertService.success(data.message, false, 10),
                    error => this.alertService.error(error))
                .add(() => this.loadingConfirm = false);
        } else {
            this.loadingConfirm = false;
        }
    }

}
