import { AuthenticationService } from './../../../../../shared/service/authentication.service';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, of } from 'rxjs';
import { saveAs } from 'file-saver';
import { catchError, takeUntil } from 'rxjs/operators';
import { NetworkDownloadData } from 'src/app/shared/model/network-epg-model/network-download-data';
import { NetworskDownloadData } from 'src/app/shared/model/network-epg-model/networks-donwload-data';
import { NetworkDataSharingService } from 'src/app/shared/service/network-data-sharing.service';
import { NetworkGridsService } from 'src/app/shared/service/network-grids.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/shared/model/user';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
    selector: 'app-network-utils-dialog',
    templateUrl: './network-utils-dialog.component.html',
    styleUrls: ['./network-utils-dialog.component.scss']
})
export class NetworkUtilsDialogComponent implements OnInit {

    fileName: string = "TurnerTapkit-EPG-Networks.zip";
    descargando: boolean = false;
    showMesage: boolean = false;
    showErrorMessage: boolean = false;
    networskDownloadData: NetworskDownloadData[] = [];
    filesToDownload: string[] = [];
    private unsubscription$: Subject<void>;
    tabsNames: string[] = [];
    netDownloadData: NetworkDownloadData[] = [];
    currentUser: User;

    constructor(
        private _netService: NetworkDataSharingService,
        private authenticationService: AuthenticationService,
        private _service: NetworkGridsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<NetworkUtilsDialogComponent>,
        private translateService: TranslateService,
        private userService: UserService
    ) {
        this.networskDownloadData = data;
        this.unsubscription$ = new Subject<void>();
        this.translations();

    }

    ngOnInit(): void {
        this.networskDownloadData.sort((a, b) => {
            if (a.id_network !== b.id_network) {
                return a.id_network - b.id_network;
            } else {
                return a.tab_id - b.tab_id;
            }
        })

        this.currentUser = this.authenticationService.currentUserValue;
    }

    private translations(): void {
        const keys = [
            'epg.networks.tabs.grids',
            'epg.networks.tabs.highlights',
            'epg.networks.tabs.images',
            'epg.networks.tabs.videos',
            'epg.networks.tabs.localbreaks',
            'epg.networks.tabs.dailygrids',
            'epg.networks.tabs.epak',
            'epg.networks.tabs.updates'
        ];
        this.translateService
            .stream(keys)
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(translations => this.mapperToMessages(translations));
    }

    private mapperToMessages(translations: any): void {
        this.tabsNames[1] = translations['epg.networks.tabs.grids'];
        this.tabsNames[2] = translations['epg.networks.tabs.dailygrids'];
        this.tabsNames[3] = translations['epg.networks.tabs.highlights'];
        this.tabsNames[4] = translations['epg.networks.tabs.images'];
        this.tabsNames[5] = translations['epg.networks.tabs.videos'];
        this.tabsNames[6] = translations['epg.networks.tabs.localbreaks'];
        this.tabsNames[7] = translations['epg.networks.tabs.epak'];
        this.tabsNames[8] = translations['epg.networks.tabs.updates'];
    }

    download(): void {
        this.descargando = true;
        this.showMesage = true;
        //this.filesToDownload = this.networskDownloadData.map(item => item.name);
        this.networskDownloadData.forEach((element) => {
            const net: NetworkDownloadData = {
                name: element.name,
                path: element.path,
            };
            this.netDownloadData.push(net);
        });
        //console.log("lista", this.netDownloadData)
        this._service.getDownloadedFiles(this.netDownloadData).pipe(
            catchError(error => {
                console.error('Error al descargar los archivos:', error);
                this.descargando = false;
                this.showMesage = false;
                this.showErrorMessage = true;
                setTimeout(() => {
                    this.showErrorMessage = false;
                }, 3000);
                return of(null);
            })
        ).subscribe((arrayBuffer) => {
            if (arrayBuffer) {
                this.arrayBufferToFile(arrayBuffer);
            }

            for (const downloadedFile of this.networskDownloadData) {

                const formData = new FormData();

                formData.append('user-token', this.currentUser.accessToken);
                formData.append('file-id', downloadedFile.file_id.toString());
                formData.append('downloaded-date', this.dateFormat(new Date()));

                this.userService.registerDownloadedFile(formData).subscribe();

            }

            this.descargando = false;
            this.showMesage = false;
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    arrayBufferToFile(arrayBuffer: ArrayBuffer) {
        const blob = new Blob([arrayBuffer]);
        saveAs(blob, this.fileName);
    }

    removeFileFromList(network: NetworskDownloadData) {
        const index = this.networskDownloadData.findIndex(item => item === network);
        if (index !== -1) {
            this._netService.updateMyObject(network);
            this.networskDownloadData.splice(index, 1);
        }
        if (this.networskDownloadData.length == 0) {
            this.descargando = true;
        }
    }

    deselectFiles() {
        for (const file of this.networskDownloadData) {
            file.selected = false;
            this._netService.updateMyObject(file);
        }
        this.networskDownloadData.splice(0, this.networskDownloadData.length);
        this.networskDownloadData = [];
        this.descargando = true;
    }

    private dateFormat(lastDownloadedDate: Date): string {

        const year = lastDownloadedDate.getFullYear();
        const month = ('0' + (lastDownloadedDate.getMonth() + 1)).slice(-2);
        const day = ('0' + lastDownloadedDate.getDate()).slice(-2);
        const hours = ('0' + lastDownloadedDate.getHours()).slice(-2);
        const minutes = ('0' + lastDownloadedDate.getMinutes()).slice(-2);
        const seconds = ('0' + lastDownloadedDate.getSeconds()).slice(-2);
        const milliseconds = ('00' + lastDownloadedDate.getMilliseconds()).slice(-3);

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    }

}
