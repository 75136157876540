import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AlertService {

    private subject$ = new Subject<any>();
    private keepAfterRouteChange = false;

    constructor(private router: Router) {
        // Borra los mensajes de alerta ante el cambio de ruta
        // a menos que el indicador 'keepAfterRouteChange' sea true
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    this.keepAfterRouteChange = false;
                } else {
                    // borra el mensaje de alerta
                    this.clear();
                }
            }
        });
    }

    getAlert$(): Observable<any> {
        return this.subject$.asObservable();
    }

    info(message: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject$.next({ type: 'info', text: message });
    }

    success(message: string, keepAfterRouteChange = false, timeout = 0) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject$.next({ type: 'success', text: message });
        if(timeout !== 0)
            setTimeout(() => this.clear(), timeout*1000);
    }

    warning(message: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject$.next({ type: 'warning', text: message });
    }

    error(message: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject$.next({ type: 'error', text: message });
    }

    clear() {
        // borra la data llamando subject.next() sin parametros
        this.subject$.next();
    }
}
