import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Feed } from 'src/app/shared/model/feed';

@Component({
    selector: 'app-legal-feed-grid',
    templateUrl: './legal-feed-grid.component.html',
    styleUrls: ['./legal-feed-grid.component.css']
})
export class LegalFeedGridComponent implements OnInit {

    @Input()
    dataSource = new MatTableDataSource<Feed>();

    displayedColumns: string[];

    @Input()
    selection: SelectionModel<Feed>;

    @Input()
    loadingDatasource: boolean;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    constructor() { }

    ngOnInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.displayedColumns = [
            'code',
            'name',
            'selected'
        ];
    }

    /** Selecciona/Deselecciona un Feed */
    checkboxLabel(row?: Feed): string {
        const isSelected: boolean = this.selection.isSelected(row);
        this.dataSource
            .data
            .find(feed => feed.id === row.id)
            .selected = isSelected;

        if (isSelected) {
            return `deselect row ${row.id}`;
        } else {
            return `select row ${row.id}`;
        }
    }

}
