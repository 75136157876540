import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { DialogTimeoutComponent } from './login/dialog-timeout/dialog-timeout.component';
import { AuthenticationService } from './shared/service/authentication.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    dialogRef: MatDialogRef<DialogTimeoutComponent>;

    constructor(
            public dialog: MatDialog,
            private authService: AuthenticationService,
            private idle: Idle) {

        this.defineSessionTimeout();
    }

    private defineSessionTimeout(): void {
        this.idle.setIdle(environment.timeout);
        this.idle.setTimeout(environment.timeoutPopup);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe(() => this.idle.watch());
        this.idle.onIdleStart.subscribe(() => this.openDialog());
        this.idle.onTimeout.subscribe(() => {
            this.dialogRef.close();
            this.authService.logout();
        });
    }

    private openDialog(): void {
        this.dialogRef = this.dialog.open(DialogTimeoutComponent, {
            height: '210px',
            width: '356px',
            disableClose: true,
            autoFocus: true
        });

        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.authService.logout();
            }
        });
    }

}
