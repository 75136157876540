import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {

    recoveryForm: FormGroup;

    loading = false;
    submitted = false;
    error = false;
    successRecovery = false;



    private unsubscription$: Subject<void>;


    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private translateService: TranslateService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private authService: AuthenticationService
    ) {

            this.unsubscription$ = new Subject<void>();
            iconRegistry.addSvgIcon('person', sanitizer
                .bypassSecurityTrustResourceUrl('../../../assets/icons/filled-person-24px.svg')
            );
     }

    ngOnInit(): void {
        this.recoveryForm = this.formBuilder.group({
            email: ['',[Validators.required, Validators.email]]
        });
    }

    onSubmit() {
        console.log('Recovery password');
        this.submitted = true;
        this.error = false;
        this.successRecovery = false;
        if (this.recoveryForm.invalid) {
            return;
        }
        this.loading = true;

        console.log(this.recoveryForm.value.email);

        this.authService.resetPassword$(this.recoveryForm.value.email).subscribe(
            data => {
                this.loading = false;
                console.log("ok");
                this.recoveryForm.reset();
                this.submitted = false;
                this.successRecovery = true;
            },
            error => {
                console.log("error", error);
                this.loading = false;
                this.error = true;
                this.submitted = false;
            },

        );
    }

    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }
}
