import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../service/authentication.service';

/**
 * Intercepta las respuestas http de la API para verificar si hubo algun error
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    private readonly UNAUTHORIZED: number = 401;
    private readonly SERVER_ERROR: number = 500;

    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
                   .pipe(catchError(err => {
                        var errorTranslated = "Error";
                        if (err.status === this.UNAUTHORIZED) {
                            this.authenticationService.logout();
                            errorTranslated = err.error.error_description;
                        } else if (err.status === this.SERVER_ERROR) {
                            errorTranslated = "Server failed to execute request";
                        } else {
                            errorTranslated = err.status; //err.message;
                        }
                        //console.error("interceptor error = ", err);
                        //const error = err.error.error_description || err.statusText;
                        return throwError(errorTranslated);
                    }));
    }

}
