import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ViewChildren, QueryList } from '@angular/core';
import { Language } from 'src/app/shared/model/language';
import { NetworksUploadFiles } from 'src/app/shared/model/network-epg-model/networks-upload-files';
import { NetworkService } from 'src/app/shared/service/network.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-networks-upload-utils-dialog',
    templateUrl: './networks-upload-utils-dialog.component.html',
    styleUrls: ['./networks-upload-utils-dialog.component.scss'],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})


export class NetworksUploadUtilsDialogComponent implements OnInit {

    // Declaracion de variables.
    networksUploadFiles: NetworksUploadFiles[] = [];
    netUpFile: NetworksUploadFiles = null;
    private unsubscription$: Subject<void>;
    private pendingStatus: string;
    private waitingToDownloadStatus: string;
    private uploadedStatus: string;
    private failedStatus: string;

    // Variables que traigo del networks-upload.component.
    languages: string[] = null;
    fileTypes: string[] = null;
    feeds: string[] = null;
    username: string = '';

    //Variables para el html.
    @ViewChildren(MatDatepicker) datepickers: QueryList<MatDatepicker<Moment>>;
    minDate = new Date();
    maxDate = new Date();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<NetworksUploadUtilsDialogComponent>,
        //private cdr: ChangeDetectorRef, // para renderizar el html en caso de ser necesario.
        private networkService: NetworkService,
        private translateService: TranslateService
    ) {
        // Recibo los datos de network-upload.component.
        this.languages = data.languages.map(language => language.code);
        this.fileTypes = data.fileTypes;
        this.feeds = data.feeds;
        this.username = data.username;

        this.unsubscription$ = new Subject<void>();
        this.translations();
    }

    ngOnInit(): void {
        this.minDate.setDate(this.minDate.getDate() - 90);
        this.maxDate.setDate(this.maxDate.getDate() + 90);
    }

    setMonthAndYear(normalizedMonthAndYear: Moment, index: number) {
        const datepicker = this.datepickers.toArray()[index];
        const ctrlValue = this.networksUploadFiles[index].date.value ?? moment();
        ctrlValue.month(normalizedMonthAndYear.month());
        ctrlValue.year(normalizedMonthAndYear.year());
        this.networksUploadFiles[index].date.setValue(ctrlValue);
        datepicker.close();
    }

    onFilesSelected(event: any) {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
            this.netUpFile = {
                idStatus: 0,
                fileName: files[i].name,
                fileType: null,
                feed: null,
                language: null,
                date: new FormControl(moment()),
                status: this.waitingToDownloadStatus,
                file: files[i]
            }

            this.networksUploadFiles.push(this.netUpFile);
        }

        event.target.value = '';
    }

    clearAll() {
        this.networksUploadFiles = [];
    }

    publishAll() {

        for (let element of this.networksUploadFiles) {

            if (element.idStatus == 2)
                continue;

            element.idStatus = 1;
            element.status = this.pendingStatus;

            const formData = new FormData();

            formData.append('network-file', element.file);
            formData.append('feed', element.feed);
            formData.append('file-type', element.fileType);
            formData.append('language', element.language);
            formData.append('username',this.username);

            const formattedDate: string = element.date.value.format('MM/YYYY');

            formData.append('date', formattedDate);

            this.networkService.uploadFile(formData).subscribe(
                response => {
                    if (response.message != null){
                        element.status = this.failedStatus + ". " + response.message;
                        element.idStatus = 3;
                    }else {
                        element.status = this.uploadedStatus;
                        element.idStatus = 2;
                    }
                    
                }

            );

        }
    }

    applyToAll() {
        //HABILITAR EL BOTON SI ALGUNO DE LOS ELEMENTOS TIENE TODOS LOS ATRIBUTOS SETEADOS
        const value = this.networksUploadFiles.find(element => element.fileType != null && element.language != null && element.date != null); //AGREGAR LA VALIDACION DE FEED
        if (value != undefined) {
            this.networksUploadFiles.forEach(element => {
                element.fileType = value.fileType;
                element.language = value.language;
                element.date = value.date;
            });
        }
    }

    close() {
        this.dialogRef.close();
    }

    removeFile(i: number) {
        this.networksUploadFiles.splice(i, 1);
    }

    checkAllSelectorsSelected(): boolean {

        if (this.networksUploadFiles.length == 0)
            return false;

        return this.networksUploadFiles.every(file =>
            file.fileType && file.feed && file.language
        );
    }

    private translations(): void {

        const keys = [
            'epg.networks_upload.pending_status',
            'epg.networks_upload.waiting_to_download_status',
            'epg.networks_upload.failed_status',
            'epg.networks_upload.uploaded_status'
        ];

        this.translateService
            .stream(keys)
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(translations => this.mapperToMessages(translations));
    }

    private mapperToMessages(translations: any): void {

        this.pendingStatus = translations['epg.networks_upload.pending_status'];
        this.waitingToDownloadStatus = translations['epg.networks_upload.waiting_to_download_status'];
        this.uploadedStatus = translations['epg.networks_upload.uploaded_status'];
        this.failedStatus = translations['epg.networks_upload.failed_status'];

    }

    onSelectorChange(file: NetworksUploadFiles) {
        file.idStatus = 0;
    }

}
