export enum CountryEnum {
    ANGUILLA = 'Anguilla',
    ARGENTINA = 'Argentina',
    ARUBA = 'Aruba',
    AUSTRALIA = 'Australia',
    BAHAMAS = 'Bahamas',
    BARBADOS = 'Barbados',
    BELICE = 'Belice',
    BERMUDA = 'Bermuda',
    BOLIVIA = 'Bolivia',
    BRAZIL = 'Brazil',
    CAYMANISLANDS = 'Cayman Islands',
    CHILE = 'Chile',
    COLOMBIA = 'Colombia',
    SOUTHKOREA = 'South Korea',
    COSTARICA = 'Costa Rica',
    SEACRUISE = 'Sea Cruise',
    CUBA = 'Cuba',
    ECUADOR = 'Ecuador',
    ELSALVADOR = 'El Salvador',
    UNITEDSTATES = 'United States',
    GREECE = 'Greece',
    GUATEMALA = 'Guatemala',
    GUYANA = 'Guyana',
    HONDURAS = 'Honduras',
    HONGKONG = 'Hong Kong',
    HAITI = 'Haiti',
    ENGLAND = 'England',
    ITALY = 'Italy',
    JAPAN = 'Japan',
    MALAYSIA = 'Malaysia',
    MEXICO = 'Mexico',
    NICARAGUA = 'Nicaragua',
    NORWAY = 'Norway',
    PANAMA = 'Panama',
    PARAGUAY = 'Paraguay',
    PERU = 'Peru',
    PUERTORICO = 'Puerto Rico',
    DOMINICANREPUBLIC = 'Dominican Republic',
    TRINIDADANDTOBAGO = 'Trinidad and Tobago',
    URUGUAY = 'Uruguay',
    VENEZUELA = 'Venezuela'
}


