import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {

    constructor(
        private jwtHelperService: JwtHelperService,
        private authenticationService: AuthenticationService
    ) { }

    /**
     * Verifica si el usuario logueado tiene permisos (roles)
     *
     * @param allowedRoles array con los roles permitidos para acceder a esa funcionalidad
     */
    public isAuthorized(allowedRoles: string[]): boolean {

        if (allowedRoles == null || allowedRoles.length === 0) {
            return false;
        }

        const currentUser = this.authenticationService.currentUserValue;
        if (!currentUser) {
            return false;
        }
        const accessToken = currentUser.accessToken;
        const decodeAccessToken = this.jwtHelperService.decodeToken(accessToken);

        if (!decodeAccessToken) {
            return false;
        }
        for (const rol of decodeAccessToken.authorities) {
            if (allowedRoles.includes(rol)) {
                return true;
            }
        }
        return false;
    }

}
