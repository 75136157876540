import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DataNetwork } from '../shared/model/data-network';
import { Network } from '../shared/model/network';
import { EpgDataService } from '../shared/service/epg-data.service';
import { NetworkService } from '../shared/service/network.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-epg',
    templateUrl: './epg.component.html'
})
export class EpgComponent implements OnInit, OnDestroy {

    private dataNetwork: DataNetwork;
    private unsubscription$: Subject<void>;

    networks: Network[];
    loadingNetworks = false;
    networks$: Observable<Network[]>;
    style: any = {};

    activateStyle: any = {
        opacity: '1',
        filter: 'grayscale(0%)',
        clicked: false
    };
    deactivateStyle: any = {
        opacity: '.5',
        filter: 'grayscale(100%)',
        clicked: false
    };
    //network code para pasar a ancine
    
    selectedNetwork$ = new Subject<Network>();
    selectedNetworkCode$ = this.selectedNetwork$.pipe(map(n => n.code));//new Subject<string>();
    private showingAncine: boolean;

    constructor(
            private networkService: NetworkService,
            private epgDataService: EpgDataService,
            private route: ActivatedRoute
    ) {

        this.dataNetwork = new DataNetwork();
        this.unsubscription$ = new Subject<void>();
    }

    ngOnInit() {
        this.loadingNetworks = true;
        
        this.route.url.subscribe(() =>
        {
            this.loadNetworks();
        })

        this.loadNetworks();
    }

    private loadNetworks(){
        let nets: Observable<Network[]>;

        let dataUrl = this.route.snapshot.firstChild.data 
        let isAncine = dataUrl?.isAncine ?? false;
        if(this.loadingNetworks === false && this.showingAncine!=undefined){
            console.log("Networks loaded, ShowingAncine is defined")
            if( this.showingAncine===isAncine){
                console.log(this.showingAncine,isAncine);
                return;
            }
            this.networks = null;
            this.loadingNetworks = true;
        }

        if( isAncine === true){
            nets = this.networkService
            .getNetworksAncine$()
            this.showingAncine=true;
        }
        else
        {
            nets = this.networkService
            .getNetworks$()
            this.showingAncine=false;
        }
        
        nets.pipe(takeUntil(this.unsubscription$))
            .subscribe(networks => this.mapperNetworks(networks))
            .add(() => this.loadingNetworks = false);
    }

    private mapperNetworks(networks: Network[]): void {
        this.networks = networks;
        this.networks.forEach(network => this.style[network.code] = { ...this.deactivateStyle });
        this.networks$ = of(this.networks);
    }

    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }

    /**
     * Filtra por Network
     *
     * @param codeNetwork Codigo de Network
     */
    clickNetwork(network: Network) {
        let codeNetwork: string = network.code;
        this.dataNetwork.codeNetwork = codeNetwork;
        this.dataNetwork.isClicked = this.style[codeNetwork].clicked;
        this.epgDataService.codeNetworkSubject$.next(this.dataNetwork);
        //para pasar el network code a ancine
        this.selectedNetwork$.next(network);

        if (this.style[codeNetwork].clicked) {                    // Si la NETWORK esta seleccionada
            this.style[codeNetwork] = {...this.deactivateStyle};
        } else {
            this.networks.forEach(network => this.style[network.code] = {...this.deactivateStyle});
            this.style[codeNetwork] = {...this.activateStyle};
            this.style[codeNetwork].clicked = true;
        }
    }

}
