import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ConfigGridAlias } from 'src/app/shared/model/config-grid-alias';
import { ConfigGridField } from 'src/app/shared/model/config-grid-field';
import { DataNetwork } from 'src/app/shared/model/data-network';
import { Feed } from 'src/app/shared/model/feed';
import { ConfigService } from 'src/app/shared/service/config.service';
import { EpgDataService } from 'src/app/shared/service/epg-data.service';
import { FeedService } from 'src/app/shared/service/feed.service';
import { ArrayHelper } from 'src/app/shared/utils/array-helper';
import { ConfigGridMaster } from 'src/app/shared/model/config-grid-master';
import { AlertService } from 'src/app/shared/service/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ModuleService } from 'src/app/shared/service/module.service';
import { Module } from 'src/app/shared/model/module';

@Component({
    selector: 'app-configuration-grid',
    templateUrl: './configuration-grid.component.html',
    styleUrls: ['./configuration-grid.component.css']
})
export class ConfigurationGridComponent implements OnInit, OnDestroy {

    feeds: Feed[];
    selection: SelectionModel<Feed>;
    modules: Module[];
    module: Module;

    loadingAlias = false;
    loadingSelected = false;
    loadingDatasource = false;

    feedsSelected = new Array<Feed>();
    dataSource = new MatTableDataSource<Feed>();
    configAlias$ = new Observable<ConfigGridAlias[]>();
    configSelected$ = new Observable<ConfigGridAlias[]>();

    private configAlias = new Array<ConfigGridAlias>();
    private configSelected = new Array<ConfigGridAlias>();
    private configField = new Array<ConfigGridField>();
    private unsubscription$ = new Subject<void>();
    private message: string;

    constructor(
            private configService: ConfigService,
            private epgDataService: EpgDataService,
            private alertService: AlertService,
            private translateService: TranslateService,
            private feedService: FeedService,
            private moduleService: ModuleService) {

        this.translateService
            .stream(['epg.config.conflicts'])
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(translations => this.message = translations['epg.config.conflicts']);
    }

    ngOnInit() {
        this.configSelected$ = of(this.configSelected);
        this.loadModules();
        this.loadFeedsGrid();
    }

    ngOnDestroy(): void {
        this.unsubscription$.next();
        this.unsubscription$.complete();
    }

    private loadModules(): void {
        this.moduleService
            .getModules$()
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(modules => this.modules = modules);
    }

    private loadFeedsGrid(): void {
        this.loadingDatasource = true;
        this.feedService
            .getFeeds$()
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(feeds => this.mapperFeeds(feeds))
            .add(() => this.loadingDatasource = false);
    }

    private mapperFeeds(feeds: Feed[]) {
        this.feeds = feeds;
        this.dataSource.data = this.feeds;
        this.filterNetworkSetup();
        this.selection = new SelectionModel<Feed>(true, this.dataSource.data.filter(row => row.selected));
        this.epgDataService
            .codeNetwork$
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(dataNetwork => this.filterNetwork(dataNetwork));
    }

    loadFieldToSelect(): void {
        this.loadingAlias = true;
        this.configService
            .getConfigAlias$()
            .pipe(takeUntil(this.unsubscription$))
            .subscribe(aliasList => this.mapperFieldToSelect(aliasList))
            .add(() => this.loadingAlias = false);
    }

    private mapperFieldToSelect(aliasList: ConfigGridAlias[]) {
        this.configAlias$ = of(aliasList);
        this.configAlias = aliasList;
        ArrayHelper.orderBy(aliasList, 'field');

        this.configField.length = 0;
        this.configSelected.length = 0;

        if (!this.module) {
            return;
        }
        this.loadingSelected = true;

        this.configService
            .getConfigurations$(this.module.id)
            .pipe(
                takeUntil(this.unsubscription$),
                map(configs => configs.filter(config => ArrayHelper.contains(this.selection.selected, config.feed))))
            .subscribe(configs => this.validateStructure(configs))
            .add(() => this.loadingSelected = false);
    }

    private validateStructure(configs: ConfigGridMaster[]): void {
        let list1: string;
        let list2: string;
        let isValid = true;

        for (const config of configs) {
            config.fields.forEach(field => delete field.id);
            ArrayHelper.orderBy(config.fields, 'ordered');
            list1 = JSON.stringify(config.fields);
            if (!list2) {
                isValid = true;
            } else if (list1 === list2) {
                isValid = true;
            } else {
                isValid = false;
                break;
            }
            list2 = list1;
        }

        this.alertService.clear();
        if (!isValid) {
            const lastIndex = this.selection.selected.length - 1;
            const lastFeedSelected = this.selection.selected[lastIndex];
            this.dataSource.data.forEach(feed => {
                if (feed.alias === lastFeedSelected.alias) {
                    feed.selected = false;
                }
            });
            configs = configs.filter(config => config.feed.alias !== lastFeedSelected.alias);
            this.selection = new SelectionModel<Feed>(true, this.dataSource.data.filter(row => row.selected));
            this.alertService.error(this.message);
        }
        configs.forEach(config => this.mapperFieldSelected(config.fields));
    }

    private mapperFieldSelected(fields: ConfigGridField[]) {
        this.configField = fields;
        this.configField.forEach(field => {
            if (!ArrayHelper.contains(this.configSelected, field.alias)) {
                this.configSelected.push(field.alias);
            }
        });
        this.configAlias = this.configAlias.filter(alias => !ArrayHelper.contains(this.configSelected, alias));
        this.configAlias$ = of(this.configAlias);
        this.configSelected$ = of(this.configSelected);
    }

    private filterNetwork(dataNetwork: DataNetwork): void {
        this.dataSource.data = this.feeds;
        const codeNetwork = dataNetwork.codeNetwork.trim();

        if (dataNetwork.isClicked) {          // Si la NETWORK esta seleccionada
            this.dataSource.filter = null;
            this.dataSource
                .data
                .forEach(feed => feed.selected = false);

        } else if (codeNetwork.length > 0) {
            this.dataSource.filter = codeNetwork;
            this.dataSource.data = this.dataSource.filteredData;
        }
        this.selection = new SelectionModel<Feed>(true, this.dataSource.data.filter(row => row.selected));
        this.loadFieldToSelect();
    }

    /**
     * Setea el filtro por defecto, para que solo filtre por el campo 'feed.network'
     */
    private filterNetworkSetup() {
        this.dataSource.filterPredicate = (feed: Feed, networkCode: string) => {
            return feed.network.code === networkCode.trim().toUpperCase();
        };
    }

    moduleEmit(module: Module): void {
        this.selection.clear();
        this.loadFieldToSelect();
        this.module = module;
    }

}
