import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileExtensionsConfigurations } from '../model/file-extensions-configurations';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileExtensionsConfigurationsService {

  constructor(private http: HttpClient) { }

  getAllFilesExtConf(){
    return this.http.get<FileExtensionsConfigurations[]>(environment.apiDomainFileExtensionConfigurationAll);
  }

  getAllFilesExtConfByConfId(fileConfigurationId: number){
    var params = new HttpParams();
    if (fileConfigurationId)
      params = params.set('fileConfigurationId', fileConfigurationId + "");

    return this.http.get<FileExtensionsConfigurations[]>(environment.apiDomainFileExtensionConfigurationByIdConfiguration, { params, });
  }

  getAllFilesExtConfByFileType(fileTypes: string[]){
    const params = new HttpParams().set('fileType', fileTypes.join(','));
    return this.http.get<FileExtensionsConfigurations[]>(environment.apiDomainFileExtensionConfigurationByFileType, { params, });
  }
}
